import { Container } from '@react-email/container';

import React, { Component } from 'react';
import ImageBlock from './imageBlock';
import { Column } from '@react-email/column';
import TestTextBlock from './textBlock';
import { Section } from '@react-email/section';
import { connect } from 'react-redux';
import { putElm, setArr } from '../../../actions/actions';
import Menu from '../../../components/Menu';

import { bindActionCreators } from 'redux';
import { Row } from '@react-email/row';
import generateBlock from './generateBlock';

class TextThreeBlock extends Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    }

    getText1 = () => {

        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === this.props.text1) {
                return generateBlock(arr[i]);
            }
        }
    }
    getText2 = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === this.props.text2) {
                return generateBlock(arr[i]);
            }
        }
    }
    getText3 = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === this.props.text3) {
                return generateBlock(arr[i]);
            }
        }
    }
    getText1Align = () =>{
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === this.props.text1) {
                return arr[i].params.align;
            }
        }
    }
    getText2Align = () =>{
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === this.props.text2) {
                return arr[i].params.align;
            }
        }
    }
    getText3Align = () =>{
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === this.props.text3) {
                return arr[i].params.align;
            }
        }
    }
    getMarginTop = () => {
        var arr = this.props.arr;
       
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.marginTop;
            }
        }
    }
    getMarginLeft = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.marginLeft;
            }
        }
    }
    getMarginRight = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.marginRight;
            }
        }
    }
    getMarginBottom = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.marginBottom;
            }
        }
    }
    getPaddingTop = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.paddingTop;
            }
        }
    }
    getPaddingLeft = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.paddingLeft;
            }
        }
    }
    getPaddingRight = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.paddingRight;
            }
        }
    }
    getPaddingBottom = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.paddingBottom;
            }
        }
    }
    getBackgroundColor = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.backgroundColor;
            }
        }
    }

    checkIfCanInteract = () => {
        return this.props.arr.filter(item => item.id === this.props.id)[0].params.for === -99;
    }

    editBlock = () => {
        this.props.putElm({ id: this.props.id, type: this.props.type })
    }

    deleteBlock = () => {
        const deletedBlock = this.props.arr.filter(item => item.id === this.props.id);
        const childrens = deletedBlock[0].childrens;
        if (deletedBlock[0].params.for !== -99) return;
        this.props.setArr(this.props.arr.filter(item => item.id !== this.props.id).filter(item => {
            if (!childrens.includes(item.id)) {
                return item
            }
        }));
    }

    uuidv4() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }

    copyBlock = () => {
        let id = this.uuidv4();
        const block = this.props.arr.filter(item => item.id === this.props.id);
        const blockChildrens = this.props.arr.filter(item => item.params.for === block[0].id);
        const modifiedblockChildrens = blockChildrens.map(item => {
            return { ...item, id: this.uuidv4(), params: { ...item.params, for: id } }
        })
        let childrens = [];
        modifiedblockChildrens.forEach(item => childrens.push(item.id))
        const modifiedBlock = structuredClone(block.map(item => {
            return { ...item, id: id, childrens: [...childrens] }
        }))
        const res = [...modifiedblockChildrens, ...modifiedBlock];
        this.props.arr.push(...res);
        var arr = Array.prototype.concat(this.props.arr);
        this.props.setArr(arr);
    }

    elemMove = (direction) => {
        let arr = this.props.arr.filter(elem => elem.params.for === -99);
        let arrWithout99 = this.props.arr.filter(elem => elem.params.for !== -99);
        let selectedElemIndex = 0;
        let selectedElem = arr.find((elem, index) => {
            selectedElemIndex = index;
            return elem.id === this.props.id
        });
        let res = [];
        if (direction === 'up') {
            if (selectedElemIndex === 0) return;
            const arrCopy = [...arr];
            let element = arrCopy[selectedElemIndex];
            arrCopy.splice(selectedElemIndex, 1);
            arrCopy.splice(selectedElemIndex - 1, 0, element);
            res = arrCopy;
        }
        if (direction === 'down') {
            if (selectedElemIndex === this.props.arr.length - 1) return;
            const arrCopy = [...arr];
            let element = arrCopy[selectedElemIndex];
            arrCopy.splice(selectedElemIndex, 1);
            arrCopy.splice(selectedElemIndex + 1, 0, element);
            res = arrCopy;
        }
        this.props.setArr(res.concat(arrWithout99));
    }

    componentDidMount = () => {
        document.querySelectorAll('#moved-block')[0].classList.add('menu-moveBottom')
    }

    componentWillUnmount = () => {
        document.querySelectorAll('#moved-block')[0].classList.remove('menu-moveBottom')
    }

    render() {
        return (
            <Row id='moved-block' className='higlighted__parentElem' data-content='Logo' style={{
                paddingTop: this.getPaddingTop(), paddingBottom: this.getPaddingBottom(),
                border: this.props.selectedElm == this.props.id ? '2px solid #34b9a0' : '',
                paddingLeft: this.getPaddingLeft(), paddingRight: this.getPaddingRight(),
                backgroundColor: this.getBackgroundColor(),
                marginTop: this.getMarginTop(), marginBottom: this.getMarginBottom(), marginLeft: this.getMarginLeft(),
                marginRight: this.getMarginRight(),
            }} onClick={(e) => e.target.tagName === "TABLE" ? this.props.putElm({ id: this.props.id, type: this.props.type }) : null}>
                <Column align={this.getText1Align()}>{this.getText1()}</Column>
                <Column align={this.getText2Align()}>{this.getText2()}</Column>
                <Column align={this.getText3Align()}>{this.getText3()}</Column>
                <Menu
                    parent
                    edit={this.editBlock}
                    canInteract={this.checkIfCanInteract()}
                    delete={this.deleteBlock}
                    copy={this.copyBlock}
                    moveUp={() => this.elemMove('up')}
                    moveDown={() => this.elemMove('down')}
                />
            </Row>
        );
    }

}


function matchDispatchToProps(dispatch) {
    return bindActionCreators({ putElm: putElm, setArr: setArr }, dispatch);
}
const mapStateToProps = state => {
    return {
        arr: state.arr,
        selectedElm: state.selectedElm
    }
}

export default connect(mapStateToProps, matchDispatchToProps)(TextThreeBlock);