import React, { Component } from 'react'

import Tooltip from '../Tooltip/Tooltip';

import { icons } from '../../../assets/icons'

import { info, infoImage, infoTitle } from './styles';

export default class Info extends Component {
    constructor() {
        super();
        this.state = {
            openedTooltip: false
        }
    }
    openTooltip = () => {
        this.setState({ openedTooltip: true })

    }
    closeTooltip = () => {
        this.setState({ openedTooltip: false })
    }
    render() {
        return (
            <div style={{ ...info }}>
                <h2 style={{ ...infoTitle }}>{this.props.title}</h2>
                <div style={{...infoImage}}>
                    <img src={icons.info} onMouseEnter={this.openTooltip} onMouseLeave={this.closeTooltip} />
                    {this.state.openedTooltip && <Tooltip info={this.props.info} />}
                </div>
            </div>
        )
    }
}
