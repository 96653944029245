import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setArr, setCampaignId, setEmailId, setEmailsData } from '../../actions/actions';
import {
  container,
  content,
  myCampaigns,
  table,
  tableBody,
  tableBodyRow,
  tableBodyRowCol,
  tableBodyRowColDate,
  tableBodyRowColDateText,
  tableBodyRowColName, tableBodyRowColNameText,
  tableBodyRowColStatus, tableBodyRowColStatusButton, tableBodyRowColStatusText,
  tableHead,
  tableHeadCol,
  tableHeadColDate, tableHeadColIcon,
  tableHeadColName,
  tableHeadColStatus, tableHeadColButton,
  top,
  topButton,
} from "./styles";
import { icons } from "../../assets/icons";
import Reveal from 'react-reveal/Reveal';
import CreateCampaignModal from '../../components/CreateCampaignModal/CreateCampaignModal';

const data = [
  { createdAt: '26.11.2019', name: 'My last campaign', status: 1 },
  { createdAt: '10.11.2019', name: 'Campaign Loyalty Winter19/20', status: 0 },
  { createdAt: '20.10.2019', name: 'My last campaign', status: 0 },
  { createdAt: '15.10.2019', name: 'My last campaign', status: 0 },
  { createdAt: '06.09.2019', name: 'My last campaign', status: 3 },
  { createdAt: '26.08.2019', name: 'My last campaign', status: 2 },
  { createdAt: '13.08.2019', name: 'My last campaign', status: 2 },
]

class Dashboard extends Component {
  constructor(props) {
    super(props);
    console.log(this.props.token);
    this.state = {
      campaigns: [],//data,
      isDescendingName: true,
      isDescendingStatus: true,
      openedCreateCampaignModal: false
    };
  }

  openCreateCampaignModal = () => {
    this.setState({openedCreateCampaignModal: true})
  }

  closeCreateCampaignModal = () => {
    this.setState({openedCreateCampaignModal: false})
  }

  sortByName = () => {
    let { campaigns, isDescendingName } = this.state;
    if (isDescendingName) {
      this.setState({ isDescendingName: !isDescendingName })
      this.setState({ campaigns: campaigns.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1) });
    } else {
      this.setState({ isDescendingName: !isDescendingName })
      this.setState({ campaigns: campaigns.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? -1 : 1) });
    }
  }
  sortByStatus = () => {
    let { campaigns, isDescendingStatus } = this.state;
    if (isDescendingStatus) {
      this.setState({ isDescendingStatus: !isDescendingStatus })
      this.setState({ campaigns: campaigns.sort((a, b) => a.status - b.status) });
    } else {
      this.setState({ isDescendingStatus: !isDescendingStatus })
      this.setState({ campaigns: campaigns.sort((a, b) => b.status - a.status) });
    }
  }
  checkStatus = (status) => {
    switch (status) {
      case 0:
        return {
          text: 'Active',
          color: '#34B9A0'
        };
      case 1:
        return {
          text: 'In progress',
          color: '#FFC730'
        };
      case 2:
        return {
          text: 'Finished',
          color: '#748E8C'
        };
      case 3:
        return {
          text: 'Stopped',
          color: '#FD7D66'
        };
      default:
        return {
          text: '',
          color: ''
        };
    }
  }
  componentDidMount = () => {
    this.getCampaigns();
  }
  getCampaigns = () => {
    var json = {
      token: this.props.token
    };
    fetch('GetCampaings', {
      method: "POST",
      body: JSON.stringify(json),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    }).then(x => x.json()).then(x => this.setState({ campaigns: x.campaings }));
  }
  goToCampaign = (elem) => {
    var json = {
      id: elem.id,
      token: this.props.token
    };
    fetch('GetEmails', {
      method: "POST",
      body: JSON.stringify(json),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    }).then(x => x.json()).then(x => {
      //this.props.setArr(JSON.parse(x.data));
      //  this.props.setEmailId(elem.id);
      this.props.setEmailsData(x);
      this.props.setCampaignId(elem.id);
      this.props.setCurrentPage('campaign_details');
      window.scrollTo(0,0);
    });
  }
  /*testPush = () =>{
    var json = {
      token: this.props.token,
      name: "TEST 1",
      types: [
        1
      ]
    };
    fetch('CreateCampaign',{
      method: "POST",
                body: JSON.stringify(json),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
    })
  }*/
  render() {
    return (
      <div style={{ ...myCampaigns }}>
        <div style={{ ...container }}>
          <div style={{ ...content }}>
            <div style={{ ...top }}>
              <button onClick={this.openCreateCampaignModal} style={{ ...topButton }}>New Campaign</button>
            </div>
            {this.state.openedCreateCampaignModal && <CreateCampaignModal close={this.closeCreateCampaignModal} setCurrentPage={this.props.setCurrentPage}/>}
            <div style={{ ...table }}>
              <p onClick={() => this.testPush()} style={{ color: 'red', display: 'none' }}>POST TEST</p>
              <ul style={{ ...tableHead }}>
                <li style={{ ...tableHeadCol, ...tableHeadColDate }}>
                  <button style={{ ...tableHeadColButton }}>
                    Date
                    <img style={{ ...tableHeadColIcon }} src={icons.arrow_bottom} alt='Arrow bottom' />
                  </button>
                </li>
                <li style={{ ...tableHeadCol, ...tableHeadColName }}>
                  <button onClick={this.sortByName} style={{ ...tableHeadColButton }}>Name</button>
                </li>
                <li style={{ ...tableHeadCol, ...tableHeadColStatus }}>
                  <button onClick={this.sortByStatus} style={{ ...tableHeadColButton }}>Status</button>
                </li>
              </ul>
              <Reveal delay={300} effect="fadeInUp">
                <ul style={{ ...tableBody }}>
                  {this.state.campaigns.map((elem, index) => (
                    <li onClick={() => this.goToCampaign(elem)} key={index} style={{ ...tableBodyRow }}>
                      <div style={{ ...tableBodyRowCol, ...tableBodyRowColDate }}>
                        <span style={{ ...tableBodyRowColDateText }}>{elem.createdAt}</span>
                      </div>
                      <div style={{ ...tableBodyRowCol, ...tableBodyRowColName }}>
                        <span style={{ ...tableBodyRowColNameText }}>{elem.name}</span>
                      </div>
                      <div style={{ ...tableBodyRowCol, ...tableBodyRowColStatus, color: `${this.checkStatus(elem.status).color}` }}>
                        <span style={{ ...tableBodyRowColStatusText }}>{this.checkStatus(elem.status).text}</span>
                        <button style={{ ...tableBodyRowColStatusButton }}>
                          <img src={icons.show_more} alt='Show more' />
                        </button>
                      </div>
                    </li>
                  ))}
                </ul>
              </Reveal>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({ setArr: setArr, setEmailId: setEmailId, setEmailsData: setEmailsData, setCampaignId: setCampaignId }, dispatch);
}
const mapStateToProps = state => {

  return {
    arr: state.arr,
    selectedElm: state.selectedElm,
    token: state.token,
    email_id: state.email_id
  }
}
export default connect(mapStateToProps, matchDispatchToProps)(Dashboard);