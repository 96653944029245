import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setCampaignName, setSelectedChannels } from '../../../actions/actions';

import CustomRadio from '../../shared/CustomRadio';
import CustomCheckbox from '../../shared/CustomCheckbox';
import Info from '../../shared/Info/Info';

class Objectives extends Component {
  constructor(props) {
    super(props);
    this.state = {
      campaignName: this.props.reduxState.campaignName,
      selectedTargetAction: this.props.reduxState.selectedTargetAction,
      selectedProducts: [],
      selectedOfferType: '',
      selectedChannels: [],
    }
  }

  setCampaignName = (e) => {
    this.setState({ campaignName: e.target.value })
    this.props.setCampaignName(e.target.value)
  }

  setSelectedIChannels = (e) => {
    this.setState({ selectedChannels: e.target.value })
    this.props.setSelectedChannels(e.target.value);
  }

  setSelectedProducts = (e) => {
    let updatedList = [...this.state.selectedProducts];
    if (e.target.checked) {
      updatedList = [...this.state.selectedProducts, e.target.value];
    } else {
      updatedList.splice(this.state.selectedProducts.indexOf(e.target.value), 1)
    }
    this.setState({ selectedProducts: updatedList });
  }

  setSelectedOfferType = (e) => {
    this.setState({ selectedOfferType: e.target.value })
  }

  /*setSelectedChannels = (e) => {
    console.log(e.target);
    let updatedList = [...this.state.selectedChannels];
    if (e.target.checked) {
      updatedList = [...this.state.selectedChannels, e.target.value];
    } else {
      updatedList.splice(this.state.selectedChannels.indexOf(e.target.value), 1)
    }
    this.setState({ selectedChannels: updatedList });
  }*/

  render() {
    return (
      <div style={{ margin: '0 auto 64px auto', width: '672px' }}>

        <div style={{ marginBottom: '64px' }}>
          <input
            type='text'
            placeholder='Campaign Name'
            value={this.state.campaignName}
            onChange={(e) => this.setCampaignName(e)}
            style={{
              padding: '20px 15px 5px 15px',
              width: '100%',
              fontSize: '14px',
              fontWeight: '500',
              lineHeight: '17px',
              background: '#23312F',
              color: '#748E8C',
              outline: 'none',
              border: 'none',
              borderTopRightRadius: '5px',
              borderTopLeftRadius: '5px',
              borderBottom: '2px solid #34B9A0',
              fontFamily: 'Montserrat'
            }}
          />
        </div>

        <div style={{ marginBottom: '64px', display: 'none' }}>
          <Info
            title='Target Action'
            info={
              <>
                <p style={{ marginBottom: '7px' }}>Якщо ви маєте збережений файл у фоматі xls із бажаною цільовою аудиторією, просто перетягніть його на поле нижче і аудиторія налаштується автоматично.</p>
                <p style={{ marginBottom: '7px' }}>Ви завжди можете відредагувати її додатково. </p>
                <p>Зберегти аудиторію в форматі xls ви можете внизу цієї сторінки.</p>
              </>
            }
          />
          <CustomRadio
            labelId='actionChoice1'
            labelText='Kopilka'
            name='action'
            value='1'
            setValue={this.setselectedTargetAction}
            checked={this.props.reduxState.selectedTargetAction === '1'}
          />
          <CustomRadio
            labelId='actionChoice2'
            labelText='Bonus Plus'
            name='action'
            value='2'
            setValue={this.setselectedTargetAction}
            checked={this.props.reduxState.selectedTargetAction === '2'}
          />
          <CustomRadio
            labelId='actionChoice3'
            labelText='Deposit'
            name='action'
            value='3'
            setValue={this.setselectedTargetAction}
            checked={this.props.reduxState.selectedTargetAction === '3'}
          />
          <CustomRadio
            labelId='actionChoice4'
            labelText='Apple Pay / Google Pay'
            name='action'
            value='4'
            setValue={this.setselectedTargetAction}
            checked={this.props.reduxState.selectedTargetAction === '4'}
          />
          <CustomRadio
            labelId='actionChoice5'
            labelText='POS-Transaction'
            name='action'
            value='5'
            setValue={this.setselectedTargetAction}
            checked={this.props.reduxState.selectedTargetAction === '5'}
          />
          <CustomRadio
            labelId='actionChoice6'
            labelText='Installment'
            name='action'
            value='6'
            setValue={this.setselectedTargetAction}
            checked={this.props.reduxState.selectedTargetAction === '6'}
          />
          <CustomRadio
            labelId='actionChoice7'
            labelText='Komunalka'
            name='action'
            value='7'
            setValue={this.setselectedTargetAction}
            checked={this.props.reduxState.selectedTargetAction === '7'}
          />
          <CustomRadio
            labelId='actionChoice8'
            labelText='Mobile Top-UP'
            name='action'
            value='8'
            setValue={this.setselectedTargetAction}
            checked={this.props.reduxState.selectedTargetAction === '8'}
          />
        </div>

        <div style={{ marginBottom: '64px', display: 'none' }}>
          <Info title='Product' info="Lorem ipsum dolor Lorem ipsum dolor Lorem ipsum dolor" />
          <CustomCheckbox
            labelId='universalna'
            labelText='Universalna'
            name='universalna'
            value='1'
            setValue={this.setSelectedProducts}
          />
          <CustomCheckbox
            labelId='universalna-gold'
            labelText='Universalna Gold'
            name='universalna-gold'
            value='2'
            setValue={this.setSelectedProducts}
          />
          <CustomCheckbox
            labelId='pensiyna'
            labelText='Pensiyna'
            name='pensiyna'
            value='3'
            setValue={this.setSelectedProducts}
          />
          <CustomCheckbox
            labelId='socialna'
            labelText='Socialna'
            name='socialna'
            value='4'
            setValue={this.setSelectedProducts}
          />
          <CustomCheckbox
            labelId='junior'
            labelText='Junior'
            name='junior'
            value='5'
            setValue={this.setSelectedProducts}
          />
        </div>

        <div style={{ marginBottom: '64px', display: 'none' }}>
          <Info title='Offer Type' />
          <CustomRadio
            labelId='offerChoice1'
            labelText='Activation'
            name='offer'
            value='1'
            setValue={this.setSelectedOfferType}
          />
          <CustomRadio
            labelId='offerChoice2'
            labelText='Retention'
            name='offer'
            value='2'
            setValue={this.setSelectedOfferType}
          />
          <CustomRadio
            labelId='offerChoice3'
            labelText='Onboarding'
            name='offer'
            value='3'
            setValue={this.setSelectedOfferType}
          />
          <CustomRadio
            labelId='offerChoice4'
            labelText='Stimulation'
            name='offer'
            value='4'
            setValue={this.setSelectedOfferType}
          />
          <CustomRadio
            labelId='offerChoice5'
            labelText='Acquisition'
            name='offer'
            value='5'
            setValue={this.setSelectedOfferType}
          />
        </div>

        <div style={{ marginBottom: '64px' }}>
          <Info title='Channel' />
          <CustomCheckbox
            labelId='email'
            labelText='Email'
            name='email'
            value='1'
            setValue={this.setSelectedIChannels}
          />
          
          {/*
          <CustomCheckbox
            labelId='facebook'
            labelText='Facebook'
            name='facebook'
            value='2'
            setValue={this.setSelectedChannels}
          />
          <CustomCheckbox
            labelId='message'
            labelText='Message'
            name='message'
            value='3'
            setValue={this.setSelectedChannels}
          />
           */}
        </div>

      </div>
    )
  }
}
function matchDispatchToProps(dispatch) {
  return bindActionCreators({ setCampaignName: setCampaignName, setSelectedChannels: setSelectedChannels }, dispatch);
}
const mapStateToProps = state => {
  return {
    reduxState: state,
  }
}
export default connect(mapStateToProps, matchDispatchToProps)(Objectives);