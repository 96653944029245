import { Text } from '@react-email/text';
import { Container } from '@react-email/container'
import { Link } from '@react-email/link';
import { Column } from '@react-email/column';
import { Row } from '@react-email/row';
import { Section } from '@react-email/section';
export const getTextLinkBlock = (params) => {
    var rtl = '';
    if (params.dir !== undefined && params.dir !== null){
        rtl = params.dir ? 'rtl' : '';
    }
    return <Section>
        <Row>
            <Column align={params.align} style={{
                color: params.color,

                textDecoration: 'none',
                paddingTop: params.paddingBTop,
                paddingBottom: params.paddingBBottom,
                paddingLeft: params.paddingBLeft,
                paddingRight: params.paddingBRigth,
                backgroundColor: params.background,
                textAlign: params.align,
                align: params.align,
               
                margin: '0',
            
                
            }}>
                <Container style={{
                   
                    maxWidth: 'auto',
                    width: 'auto',
                    paddingTop: params.paddingTop,
                    paddingLeft: params.paddingLeft,
                    paddingRight: params.paddingRight,
                    borderTopLeftRadius: params.borderTopLeftRadius,
                    borderBottomLeftRadius: params.borderBottomLeftRadius,
                    borderTopRightRadius: params.borderTopRightRadius,
                    borderBottomRightRadius: params.borderBottomRightRadius,
                    paddingBottom: params.paddingBottom, 
                    backgroundColor: params.backgroundColor, 
                }}>
                    <Link href={params.link} style={{ color: params.color, padding: 0, margin: 0, fontFamily: params.fontFamily,
                fontSize: params.fontSize,
                lineHeight: params.fontHeight,
                fontWeight: params.fontWeight}} >
                        <span dir={rtl} style={{margin: 0,
                    }} dangerouslySetInnerHTML={{ __html: params.text }}></span>
                    </Link>
                </Container>

            </Column>
        </Row>
    </Section>; //TODO: params.add children generation
}