export const footer = {
    position: 'relative',
    paddingBottom: '15px',
    fontFamily: `Montserrat, sans-serif`,
  }
  
  export const container = {
    maxWidth: '1024px',
    width: '100%',
    margin: '0 auto',
  }
  
  export const content = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    zIndex: '1',
  }
  
  export const text = {
    fontSize: '9px',
    lineHeight: '11px',
    fontWeight: '500',
    color: '#14201E'
  }
  
  export const logo = {
    margin: '0 13px'
  }
  
  export const background = {
    position: 'absolute',
    bottom: '0',
    left: '0',
    width: '100%',
    height: '55vh',
    zIndex: '0'
  }