import React, { Component } from 'react'

import { tooltip, tooltipText } from './styles'

export default class Tooltip extends Component {
  render() {
    return (
      this.props.info && <div style={{ ...tooltip }}>
        <div style={{ ...tooltipText }}>{this.props.info}</div>
      </div>
    )
  }
}
