import React, { Component } from 'react';
import { Link } from "react-router-dom";

import { icons } from "../../assets/icons";

import {
  button,
  buttonText,
  container,
  content,
  error,
  form,
  formGroup,
  formGroupInput,
  links,
  linksItem,
  login,
  logo
} from "./styles";

class Login extends Component {
  state = {
    username: '',
    pphash: '',
    error: null
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  onSubmit = (e) => {
    e.preventDefault();
    const { username, pphash } = this.state;
    this.props.logIn(username, pphash);
  }

  render() {
    return (
      <div style={{ ...login }}>
        <div style={{ ...container }}>
          <div style={{ ...content }}>
            <div style={{ ...logo }}>
              <img src={icons.logo_adbot_big} alt='Logo Adbot big' />
            </div>
            <div style={{ ...error }}>
              <p>{this.props.loginError && this.props.loginError}</p>
            </div>
            <form onSubmit={this.onSubmit} style={{ ...form }}>
              <div style={{ ...formGroup }}>
                <input
                  style={{ ...formGroupInput }}
                  type='text'
                  placeholder='Login'
                  value={this.state.username}
                  name='username'
                  onChange={this.onChange}
                />
              </div>
              <div style={{ ...formGroup }}>
                <input
                  style={{ ...formGroupInput }}
                  type='password'
                  placeholder='Password'
                  value={this.state.pphash}
                  name='pphash'
                  onChange={this.onChange}
                />
              </div>
              <button type='submit' style={{ ...button }}>
                <span style={{ ...buttonText }}>Log In</span>
                <img src={icons.arrow_right} alt='Arrow right' />
              </button>
            </form>
            {/* <div style={{ ...links }}>
              <Link style={{ ...linksItem }} to='/'>Forgot password</Link>
              <Link style={{ ...linksItem }} to='/'>Registration</Link>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default Login;