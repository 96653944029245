import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setArr } from '../../../actions/actions';
import { bindActionCreators } from 'redux';
import { icons } from '../../../assets/icons';
import AttributeTooltip from '../../../components/AttributeTooltip/AttributeTooltip';
import { determinatePercentage } from '../../../components/determinatePercentage';

class BorderBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            borderStyle: '',
            borderWidth: '',
            borderColor: '',
            borderTopLeftRadius: '',
            borderBottomLeftRadius: '',
            borderTopRightRadius: '',
            borderBottomRightRadius: '',
            activeLink: false,
        };
    }
    componentDidMount = () => {
        this.getBorderData();
    }

    toggleApplyLink = () => {
        this.setState({ activeLink: !this.state.activeLink })
    }

    getBorderData = () => {
        var id = this.props.for;
        var arr = Array.prototype.concat(this.props.arr);
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === id) {

                var item = arr[i];
                this.setState({
                    borderStyle: item.params.borderStyle,
                    borderWidth: item.params.borderWidth,
                    borderColor: item.params.borderColor,
                    borderTopLeftRadius: item.params.borderTopLeftRadius,
                    borderBottomLeftRadius: item.params.borderBottomLeftRadius,
                    borderTopRightRadius: item.params.borderTopRightRadius,
                    borderBottomRightRadius: item.params.borderBottomRightRadius
                });
                return;
            }
        }
    }
    setBorderStyle = (value) => {
        this.setState({ borderStyle: value });
        var id = this.props.for;
        var arr = Array.prototype.concat(this.props.arr);
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === id) {
                var item = arr[i];
                item.params.borderStyle = value;
                arr[i] = item;
                break;
            }
        }
        this.props.setArr(arr);
    }
    setBorderWidth = (value) => {
        this.setState({ borderWidth: value });
        var id = this.props.for;
        var arr = Array.prototype.concat(this.props.arr);
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === id) {
                var item = arr[i];
                item.params.borderWidth = value;
                arr[i] = item;
                break;
            }
        }
        this.props.setArr(arr);
    }
    setBorderColor = (value) => {
        this.setState({ borderColor: value });
        var id = this.props.for;
        var arr = Array.prototype.concat(this.props.arr);
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === id) {
                var item = arr[i];
                item.params.borderColor = value;
                arr[i] = item;
                break;
            }
        }
        this.props.setArr(arr);
    }

    setborderTopLeftRadius = (value) => {
        let isHave = determinatePercentage(value);
        if (isHave) {
            return;
        } else {
            if (!this.state.activeLink) {
                this.setState({ borderTopLeftRadius: value });
            } else {
                this.setState({
                    borderTopLeftRadius: value,
                    borderBottomLeftRadius: value,
                    borderTopRightRadius: value,
                    borderBottomRightRadius: value
                });
            }
            var id = this.props.for;
            var arr = Array.prototype.concat(this.props.arr);
            for (var i = 0; i < arr.length; i++) {
                if (arr[i].id === id) {
                    var item = arr[i];
                    if (!this.state.activeLink) {
                        item.params.borderTopLeftRadius = value;
                    } else {
                        item.params.borderTopLeftRadius = value;
                        item.params.borderBottomLeftRadius = value;
                        item.params.borderTopRightRadius = value;
                        item.params.borderBottomRightRadius = value;
                    }
                    arr[i] = item;
                    break;
                }
            }
            this.props.setArr(arr);
        }
    }
    setborderBottomLeftRadius = (value) => {
        let isHave = determinatePercentage(value);
        if (isHave) {
            return;
        } else {
            if (!this.state.activeLink) {
                this.setState({ borderBottomLeftRadius: value });
            } else {
                this.setState({
                    borderTopLeftRadius: value,
                    borderBottomLeftRadius: value,
                    borderTopRightRadius: value,
                    borderBottomRightRadius: value
                });
            }
            var id = this.props.for;
            var arr = Array.prototype.concat(this.props.arr);
            for (var i = 0; i < arr.length; i++) {
                if (arr[i].id === id) {
                    var item = arr[i];
                    if (!this.state.activeLink) {
                        item.params.borderBottomLeftRadius = value;
                    } else {
                        item.params.borderTopLeftRadius = value;
                        item.params.borderBottomLeftRadius = value;
                        item.params.borderTopRightRadius = value;
                        item.params.borderBottomRightRadius = value;
                    }
                    arr[i] = item;
                    break;
                }
            }
            this.props.setArr(arr);
        }
    }
    setborderTopRightRadius = (value) => {
        let isHave = determinatePercentage(value);
        if (isHave) {
            return;
        } else {
            if (!this.state.activeLink) {
                this.setState({ borderTopRightRadius: value });
            } else {
                this.setState({
                    borderTopLeftRadius: value,
                    borderBottomLeftRadius: value,
                    borderTopRightRadius: value,
                    borderBottomRightRadius: value
                });
            }
            var id = this.props.for;
            var arr = Array.prototype.concat(this.props.arr);
            for (var i = 0; i < arr.length; i++) {
                if (arr[i].id === id) {
                    var item = arr[i];
                    if (!this.state.activeLink) {
                        item.params.borderTopRightRadius = value;
                    } else {
                        item.params.borderTopLeftRadius = value;
                        item.params.borderBottomLeftRadius = value;
                        item.params.borderTopRightRadius = value;
                        item.params.borderBottomRightRadius = value;
                    }
                    arr[i] = item;
                    break;
                }
            }
            this.props.setArr(arr);
        }
    }
    setborderBottomRightRadius = (value) => {
        let isHave = determinatePercentage(value);
        if (isHave) {
            return;
        } else {
            if (!this.state.activeLink) {
                this.setState({ borderBottomRightRadius: value });
            } else {
                this.setState({
                    borderTopLeftRadius: value,
                    borderBottomLeftRadius: value,
                    borderTopRightRadius: value,
                    borderBottomRightRadius: value
                });
            }
            var id = this.props.for;
            var arr = Array.prototype.concat(this.props.arr);
            for (var i = 0; i < arr.length; i++) {
                if (arr[i].id === id) {
                    var item = arr[i];
                    if (!this.state.activeLink) {
                        item.params.borderBottomRightRadius = value;
                    } else {
                        item.params.borderTopLeftRadius = value;
                        item.params.borderBottomLeftRadius = value;
                        item.params.borderTopRightRadius = value;
                        item.params.borderBottomRightRadius = value;
                    }
                    arr[i] = item;
                    break;
                }
            }
            this.props.setArr(arr);
        }
    }
    /*
    
     borderTopLeftRadius: '',
            borderBottomLeftRadius: '',
            borderTopRightRadius: '',
            borderBottomRightRadius: '',
    */
    render() {
        return (
            <div>
                <div className='attr-block'>
                    <p className='attr-label'>
                        Border Radius
                        <AttributeTooltip option='2'/>
                    </p>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ marginRight: '24px' }}>
                            <div style={{ display: 'flex', marginBottom: '10px' }}>
                                <div style={{ display: 'flex', alignItems: 'center', marginRight: '24px' }}>
                                    <img src={icons.border_radius} style={{ marginRight: '8px' }} />
                                    <input className='attr-input' style={{ width: '64px' }} value={this.state.borderTopLeftRadius} onInput={(e) => this.setborderTopLeftRadius(e.target.value)} />
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img src={icons.border_radius} style={{ transform: 'rotate(90deg)', marginRight: '8px' }} />
                                    <input className='attr-input' style={{ width: '64px' }} value={this.state.borderTopRightRadius} onInput={(e) => this.setborderTopRightRadius(e.target.value)} />
                                </div>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <div style={{ display: 'flex', alignItems: 'center', marginRight: '24px' }}>
                                    <img src={icons.border_radius} style={{ transform: 'rotate(-90deg)', marginRight: '8px' }} />
                                    <input className='attr-input' style={{ width: '64px' }} value={this.state.borderBottomLeftRadius} onInput={(e) => this.setborderBottomLeftRadius(e.target.value)} />
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img src={icons.border_radius} style={{ transform: 'rotate(180deg)', marginRight: '8px' }} />
                                    <input className='attr-input' style={{ width: '64px' }} value={this.state.borderBottomRightRadius} onInput={(e) => this.setborderBottomRightRadius(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <button className={this.state.activeLink ? 'attr-link attr-link__active' : 'attr-link'} onClick={this.toggleApplyLink}>
                            {this.state.activeLink ? (
                                <img className='attr-link__img' src={icons.link_active} />
                            ) : (
                                <img className='attr-link__img' src={icons.link} />
                            )}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}
function matchDispatchToProps(dispatch) {
    return bindActionCreators({ setArr: setArr }, dispatch);
}
const mapStateToProps = state => {
    return {
        arr: state.arr
    }
}
export default connect(mapStateToProps, matchDispatchToProps)(BorderBox);