import React, { Component } from 'react';

import Header from "../layout/mainHeader/Header";
import Footer from "../layout/mainFooter/Footer";
import Dashboard from "../pages/Dashboard/Dashboard";
import Contact from "../pages/Contact/Contact";
import Help from "../pages/Help/Help";
import { Navigate } from "react-router-dom";
import Constructor from '../pages/Constructor/Constructor';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setArr } from '../actions/actions';
import NewCampaign from '../pages/NewCampaign/NewCampaign';
import CampaignDetails from '../pages/CampaignDetails/CampaignDetails';
import AiText from '../pages/AIText/aiText';
import AiImg from '../pages/AIImg/aiImg';
import FAQ from '../pages/FAQ/FAQ';

class MainTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {

      currentPage: <Dashboard setCurrentPage={this.setCurrentPage} />,
      currentStringPage: 'dashboard',
    }
  }
  getHeader = () => {
    var page = this.state.currentStringPage;
    switch (page) {
      case 'constructor':
        return <></>;
      case 'ai_text':
        return <></>;
      case 'ai_img':
        return <></>;
      default:
        return <Header logOut={this.props.logOut} setCurrentPage={this.setCurrentPage} />;
    }
  }
  getFooter = () => {
    var page = this.state.currentStringPage;
    switch (page) {
      case 'constructor':
        return <></>;
      case 'ai_text':
        return <></>;
      case 'ai_img':
        return <></>;
      case 'faq':
        return <></>;
      default:
        return <Footer />;
    }
  }
  setCurrentPage = (page, params) => {
    switch (page) {
      case 'dashboard':
        return this.setState({ currentPage: <Dashboard setCurrentPage={this.setCurrentPage} />, currentStringPage: 'dashboard' });
      case 'constructor':
        return this.setState({ currentPage: <Constructor logOut={this.props.logOut} setCurrentPage={this.setCurrentPage} />, currentStringPage: 'constructor' });
      case 'contact':
        return this.setState({ currentPage: <Contact />, currentStringPage: 'contact' });
      case 'help':
        return this.setState({ currentPage: <Help />, currentStringPage: 'help' });
      case 'ai_text':
        return this.setState({ currentPage: <AiText setCurrentPage={this.setCurrentPage} />, currentStringPage: 'ai_text' });
      case 'ai_img':
        return this.setState({ currentPage: <AiImg setCurrentPage={this.setCurrentPage} />, currentStringPage: 'ai_img' });
      case 'new_campaign':
        return this.setState({ currentPage: <NewCampaign setCurrentPage={this.setCurrentPage} />, currentStringPage: 'new_campaign' });
      case 'campaign_details':
        return this.setState({ currentPage: <CampaignDetails setCurrentPage={this.setCurrentPage} />, currentStringPage: 'campaign_details' })
      case 'faq':
        return this.setState({currentPage: <FAQ/>, currentStringPage: 'faq'})
    }
  }
  render() {
    return (
      <>
        {this.getHeader()}
        <main>
          {!this.props.authenticated ? <Navigate to='/login' /> : this.state.currentPage}
        </main>
        {this.getFooter()}

      </>
    );
  }
}
function matchDispatchToProps(dispatch) {
  return bindActionCreators({ setArr: setArr }, dispatch);
}
const mapStateToProps = state => {

  return {
    arr: state.arr,
    selectedElm: state.selectedElm
  }
}
export default connect(mapStateToProps, matchDispatchToProps)(MainTemplate);