import { Row } from "@react-email/row";
import { getImgBlock } from "./imageBlock";
import { getTextBlock } from "./textBlock";
import { Column } from '@react-email/column';
import { Section } from '@react-email/section';

export const getThreeIRow = (img1, img2, img3, params) => {
    var params_1 = img1.params;
    var params_2 = img2.params;
    var params_3 = img3.params;
    return <Row style={{
        paddingTop: params.paddingTop, paddingLeft: params.paddingLeft, paddingRight: params.paddingRight, paddingBottom: params.paddingBottom,
        marginTop: params.marginTop, marginLeft: params.marginLeft, marginRight: params.marginRight, marginBottom: params.marginBottom, backgroundColor: params.backgroundColor
    }}>
        <Column style={{}}>{getImgBlock(params_1)}</Column>
        <Column style={{}}>{getImgBlock(params_2)}</Column>
        <Column style={{}}>{getImgBlock(params_3)}</Column>

    </Row>;
}