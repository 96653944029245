import { Img } from '@react-email/img';
import { Container } from '@react-email/container'
import { Section } from '@react-email/section';
import { Row } from '@react-email/row';
import { Column } from '@react-email/column';

export const getImgBlock = (params) => {
    return <Section style={{
        paddingTop: params.paddingTop,
        paddingLeft: params.paddingLeft,
        paddingRight: params.paddingRight,
        paddingBottom: params.paddingBottom,
        marginTop: params.marginTop,
        backgroundColor: params.backgroundColor,
        marginLeft: params.marginLeft,
        marginRight: params.marginRight,
        marginBottom: params.marginBottom
    }}>
        <Row >
            <Column align={params.align}>
                <Img src={params.src} width={params.width.replace('px','')} alt={params.alt} style={{
                    height: params.height,
                    borderTopLeftRadius: params.borderTopLeftRadius,
                    borderBottomLeftRadius: params.borderBottomLeftRadius,
                    borderTopRightRadius: params.borderTopRightRadius,
                    borderBottomRightRadius: params.borderBottomRightRadius,
                    width: params.width
                }} /></Column>
        </Row>
    </Section>;
}