export const newCampaign = {
    padding: '50px 0',
}

export const container = {
    maxWidth: '1024px',
    width: '100%',
    margin: '0 auto',
}

export const content = {
  
}