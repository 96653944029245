import React, { Component } from 'react'

import { icons } from '../../assets/icons'

import { stepperControls, stepperControlsLeftButton, stepperControlsLeftButtonText, stepperControlsRight, stepperControlsRightButton, stepperControlsRightButtonText } from './styles';

export default class StepperControls extends Component {
  constructor(props) {
    super(props);

  }
  //TODO: повністью переробити
  render() {
    return (
      <div style={{ ...stepperControls }}>
        {this.props.currentStep !== 1 &&
          <div>
            <button onClick={this.props.showPrevStep} style={{...stepperControlsLeftButton}}>
              <img src={icons.back} />
              <span style={{...stepperControlsLeftButtonText}}>Back</span>
            </button>
          </div>
        }
        <div style={{...stepperControlsRight}}>
          <button onClick={this.props.showNextStep} style={{...stepperControlsRightButton}}>
            <span style={{...stepperControlsRightButtonText}}>Next</span>
            <img src={icons.next} />
          </button>
        </div>
      </div>
    )
  }
}
