export const info = {
    display: 'flex', 
    justifyContent: 'flex-start',
    alignItems: 'center', 
    marginBottom: '8px',
}

export const infoTitle = {
    marginRight: '13px', 
    fontSize: '22px', 
    lineHeight: '33px', 
    fontFamily: `'Roboto Slab', serif`, 
    fontWeight: '400',
    color: '#34B9A0'
}

export const infoImage = {
    position: 'relative'
}