import React, { Component } from 'react';
import { connect } from 'react-redux';
import { putElm, setArr } from '../../../actions/actions';
import { Img } from '@react-email/img';

import { bindActionCreators } from 'redux';
import { Link } from '@react-email/link';
import { Section } from '@react-email/section';
import { Row } from '@react-email/row';
import { Column } from '@react-email/column';
import Menu from '../../../components/Menu';

class ImageButton extends Component {

    constructor(props) {
        super();
    }

    getSrc = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.src;
            }
        }
    }
    getAlt = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.alt;
            }
        }
    }
    getMarginTop = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.marginTop;
            }
        }
    }
    getMarginLeft = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.marginLeft;
            }
        }
    }
    getMarginRight = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.marginRight;
            }
        }
    }
    getMarginBottom = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.marginBottom;
            }
        }
    }
    getPaddingTop = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.paddingTop;
            }
        }
    }
    getPaddingLeft = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.paddingLeft;
            }
        }
    }
    getPaddingRight = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.paddingRight;
            }
        }
    }
    getPaddingBottom = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.paddingBottom;
            }
        }
    }
    getWidth = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.width;
            }
        }
    }
    getHeight = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.height;
            }
        }
    }
    getHref = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.href;
            }
        }
    }
    getAlign = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.align;
            }
        }
    }
    getBackgroundColor = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.backgroundColor;
            }
        }
    }

    checkIfCanInteract = () => {
        return this.props.arr.filter(item => item.id === this.props.id)[0].params.for === -99;
    }

    editBlock = () => {
        this.props.putElm({ id: this.props.id, type: "image" })
    }

    deleteBlock = () => {
        const deletedBlock = this.props.arr.filter(item => item.id === this.props.id);
        if (deletedBlock[0].params.for !== -99) return;
        this.props.setArr(this.props.arr.filter(item => item.id !== this.props.id));
    }

    uuidv4() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }

    copyBlock = () => {
        let id = this.uuidv4();
        const block = this.props.arr.filter(item => item.id === this.props.id);
        const modifiedBlock = structuredClone(block.map(item => {
            return { ...item, id: id }
        }))
        this.props.arr.push(modifiedBlock[0]);
        var arr = Array.prototype.concat(this.props.arr);
        this.props.setArr(arr);
    }

    elemMove = (direction) => {
        let arr = this.props.arr.filter(elem => elem.params.for === -99);
        let arrWithout99 = this.props.arr.filter(elem => elem.params.for !== -99);
        let selectedElemIndex = 0;
        let selectedElem = arr.find((elem, index) => {
            selectedElemIndex = index;
            return elem.id === this.props.id
        });
        let res = [];
        if (direction === 'up') {
            if (selectedElemIndex === 0) return;
            const arrCopy = [...arr];
            let element = arrCopy[selectedElemIndex];
            arrCopy.splice(selectedElemIndex, 1);
            arrCopy.splice(selectedElemIndex - 1, 0, element);
            res = arrCopy;
        }
        if (direction === 'down') {
            if (selectedElemIndex === this.props.arr.length - 1) return;
            const arrCopy = [...arr];
            let element = arrCopy[selectedElemIndex];
            arrCopy.splice(selectedElemIndex, 1);
            arrCopy.splice(selectedElemIndex + 1, 0, element);
            res = arrCopy;
        }
        this.props.setArr(res.concat(arrWithout99));
    }

    componentDidMount = () => {
        document.querySelectorAll('#moved-block')[0].classList.add('menu-moveBottom')
    }

    componentWillUnmount = () => {
        document.querySelectorAll('#moved-block')[0].classList.remove('menu-moveBottom')
    }
    getborderTopLeftRadius = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.borderTopLeftRadius;
            }
        }
    }
    getborderBottomLeftRadius = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.borderBottomLeftRadius;
            }
        }
    }
    getborderTopRightRadius = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.borderTopRightRadius;
            }
        }
    }
    getborderBottomRightRadius = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.borderBottomRightRadius;
            }
        }
    }
    render() {
        return <Section
            id='moved-block'
            className='highlighted__childElem'
            data-content='Image'
            style={{
                paddingTop: this.getPaddingTop(),
                paddingBottom: this.getPaddingBottom(),
                backgroundColor: this.getBackgroundColor(),
                border: this.props.selectedElm == this.props.id ? '2px solid #34b9a0' : '',
                paddingLeft: this.getPaddingLeft(),
                paddingRight: this.getPaddingRight()
            }}
        >
            <Row>
                <Column align={this.getAlign()}>
                    <Link href={this.getHref()} style={{}}>
                        <Img
                            src={this.getSrc()}
                            onClick={() => this.props.putElm({ id: this.props.id, type: "image" })}
                            alt={this.getAlt()}
                            style={{
                                width: this.getWidth(),
                                height: this.getHeight(),
                                marginTop: this.getMarginTop(),
                                borderTopLeftRadius: this.getborderTopLeftRadius(),
                                borderBottomLeftRadius: this.getborderBottomLeftRadius(),
                                borderTopRightRadius: this.getborderTopRightRadius(),
                                borderBottomRightRadius: this.getborderBottomRightRadius(),
                                marginBottom: this.getMarginBottom(),
                                marginLeft: this.getMarginLeft(),
                                marginRight: this.getMarginRight()
                            }}
                        />
                    </Link>
                </Column>
            </Row>
            <Menu
                edit={this.editBlock}
                canInteract={this.checkIfCanInteract()}
                delete={this.deleteBlock}
                copy={this.copyBlock}
                moveUp={() => this.elemMove('up')}
                moveDown={() => this.elemMove('down')}
            />
        </Section>
    };
}
function matchDispatchToProps(dispatch) {
    return bindActionCreators({ putElm: putElm, setArr: setArr }, dispatch);
}
const mapStateToProps = state => {
    return {
        arr: state.arr,
        selectedElm: state.selectedElm
    }
}

export default connect(mapStateToProps, matchDispatchToProps)(ImageButton);
