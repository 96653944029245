import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setArr } from '../../../actions/actions';
import { bindActionCreators } from 'redux';

class DirBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dir: ''
        };
    }
    componentDidMount = () => {
        this.getDir();
    }

    getDir = () => {

        var id = this.props.for;
        var arr = Array.prototype.concat(this.props.arr);
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === id) {

                var item = arr[i];
                this.setState({ dir: item.params.dir });
                return;
            }
        }
    }
    setDir = (value) => {
        console.log(value);
        this.setState({ dir: value });
        var id = this.props.for;
        var arr = Array.prototype.concat(this.props.arr);
        
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === id) {
                var item = arr[i];
                item.params.dir = value;
                arr[i] = item;
                
                break;
            }
        }
        this.props.setArr(arr);
    }

    render() {
        return (
            <div className='attr-block'>
                <p className='attr-label'>
                    DIR

                </p>
                <label style={{ fontFamily: 'Montserrat', color: 'white', cursor: 'pointer' }}><input checked={this.state.dir} onInput={(e) => this.setDir(!e.target.checked)} style={{ marginRight: '8px' }} type='checkbox' /> RTL</label>

            </div>
        );
    }
}
function matchDispatchToProps(dispatch) {
    return bindActionCreators({ setArr: setArr }, dispatch);
}
const mapStateToProps = state => {
    return {
        arr: state.arr
    }
}
export default connect(mapStateToProps, matchDispatchToProps)(DirBox);