import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setArr } from '../../../actions/actions';
import { bindActionCreators } from 'redux';
import AttributeTooltip from '../../../components/AttributeTooltip/AttributeTooltip';
class LinkBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            link: ''
        };
    }
    componentDidMount = () => {
        this.getLink();
    }
    
    getLink = () => {

        var id = this.props.for;
        var arr = Array.prototype.concat(this.props.arr);
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === id) {
               
                var item = arr[i];
                this.setState({ link: item.params.link });
                return;
            }
        }
    }
    setLink = (value) => {
        this.setState({ link: value });
        var id = this.props.for;
        var arr = Array.prototype.concat(this.props.arr);
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === id) {
                var item = arr[i];
                item.params.link = value;
                arr[i] = item;
                break;
            }
        }
        this.props.setArr(arr);
    }

    render() {
        return (
            <div className='attr-block'>
                <p className='attr-label'>
                    link
                    <AttributeTooltip/>
                </p>
                <input className='attr-input' value={this.state.link} onInput={(e) => this.setLink(e.target.value)} />
            </div>
        );
    }
}
function matchDispatchToProps(dispatch) {
    return bindActionCreators({ setArr: setArr }, dispatch);
}
const mapStateToProps = state => {
    return {
        arr: state.arr
    }
}
export default connect(mapStateToProps, matchDispatchToProps)(LinkBox);