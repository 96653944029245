import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setArr } from '../../../actions/actions';
import { bindActionCreators } from 'redux';
import { icons } from '../../../assets/icons';
import AttributeTooltip from '../../../components/AttributeTooltip/AttributeTooltip';
import { determinatePercentage } from '../../../components/determinatePercentage';
class MarginBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            marginTop: '0',
            marginBottom: '0',
            marginLeft: '0',
            marginRight: '0',
            activeLink: false,
        };
    }
    componentDidMount = () => {
        this.getMargin();
    }

    toggleApplyLink = () => {
        this.setState({ activeLink: !this.state.activeLink })
    }

    getMargin = () => {
        var id = this.props.for;
        var arr = Array.prototype.concat(this.props.arr);
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === id) {
                var item = arr[i].params;
                this.setState({
                    marginTop: item.marginTop,
                    marginBottom: item.marginBottom,
                    marginLeft: item.marginLeft,
                    marginRight: item.marginRight
                });
                return;
            }
        }
    }

    setMarginTop = (value) => {
        let isHave = determinatePercentage(value);
        if (isHave) {
            return;
        } else {
            if (!this.state.activeLink) {
                this.setState({ marginTop: value });
            } else {
                this.setState({
                    marginTop: value,
                    marginBottom: value,
                    marginLeft: value,
                    marginRight: value
                });
            }
            var id = this.props.for;
            var arr = Array.prototype.concat(this.props.arr);
            for (var i = 0; i < arr.length; i++) {
                if (arr[i].id === id) {
                    var item = arr[i];
                    if (!this.state.activeLink) {
                        item.params.marginTop = value;
                    } else {
                        item.params.marginTop = value;
                        item.params.marginBottom = value;
                        item.params.marginLeft = value;
                        item.params.marginRight = value;
                    }
                    arr[i] = item;
                    break;
                }
            }
            this.props.setArr(arr);
        }
    }
    setMarginLeft = (value) => {
        let isHave = determinatePercentage(value);
        if (isHave) {
            return;
        } else {
            if (!this.state.activeLink) {
                this.setState({ marginLeft: value });
            } else {
                this.setState({
                    marginTop: value,
                    marginBottom: value,
                    marginLeft: value,
                    marginRight: value
                });
            }
            var id = this.props.for;
            var arr = Array.prototype.concat(this.props.arr);
            for (var i = 0; i < arr.length; i++) {
                if (arr[i].id === id) {
                    var item = arr[i];
                    if (!this.state.activeLink) {
                        item.params.marginLeft = value;
                    } else {
                        item.params.marginTop = value;
                        item.params.marginBottom = value;
                        item.params.marginLeft = value;
                        item.params.marginRight = value;
                    }
                    arr[i] = item;
                    break;
                }
            }
            this.props.setArr(arr);
        }
    }
    setMarginRight = (value) => {
        let isHave = determinatePercentage(value);
        if (isHave) {
            return;
        } else {
            if (!this.state.activeLink) {
                this.setState({ marginRight: value });
            } else {
                this.setState({
                    marginTop: value,
                    marginBottom: value,
                    marginLeft: value,
                    marginRight: value
                });
            }
            var id = this.props.for;
            var arr = Array.prototype.concat(this.props.arr);
            for (var i = 0; i < arr.length; i++) {
                if (arr[i].id === id) {
                    var item = arr[i];
                    if (!this.state.activeLink) {
                        item.params.marginRight = value;
                    } else {
                        item.params.marginTop = value;
                        item.params.marginBottom = value;
                        item.params.marginLeft = value;
                        item.params.marginRight = value;
                    }
                    arr[i] = item;
                    break;
                }
            }
            this.props.setArr(arr);
        }
    }
    setMarginBottom = (value) => {
        let isHave = determinatePercentage(value);
        if (isHave) {
            return;
        } else {
            if (!this.state.activeLink) {
                this.setState({ marginBottom: value });
            } else {
                this.setState({
                    marginTop: value,
                    marginBottom: value,
                    marginLeft: value,
                    marginRight: value
                });
            }
            var id = this.props.for;
            var arr = Array.prototype.concat(this.props.arr);
            for (var i = 0; i < arr.length; i++) {
                if (arr[i].id === id) {
                    var item = arr[i];
                    if (!this.state.activeLink) {
                        item.params.marginBottom = value;
                    } else {
                        item.params.marginTop = value;
                        item.params.marginBottom = value;
                        item.params.marginLeft = value;
                        item.params.marginRight = value;
                    }
                    arr[i] = item;
                    break;
                }
            }
            this.props.setArr(arr);
        }
    }

    render() {
        return (
            <div>
                <div className='attr-block'>
                    <p className='attr-label'>
                        Margin
                        <AttributeTooltip option='2'/>
                    </p>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ marginRight: '24px' }}>
                            <div style={{ display: 'flex', marginBottom: '10px' }}>
                                <div style={{ display: 'flex', alignItems: 'center', marginRight: '24px' }}>
                                    <img src={icons.padding_margin} style={{ marginRight: '8px' }} />
                                    <input className='attr-input' style={{ width: '64px' }} value={this.state.marginLeft} onInput={(e) => this.setMarginLeft(e.target.value)} />
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img src={icons.padding_margin} style={{ transform: 'rotate(90deg)', marginRight: '8px' }} />
                                    <input className='attr-input' style={{ width: '64px' }} value={this.state.marginTop} onInput={(e) => this.setMarginTop(e.target.value)} />
                                </div>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <div style={{ display: 'flex', alignItems: 'center', marginRight: '24px' }}>
                                    <img src={icons.padding_margin} style={{ transform: 'rotate(180deg)', marginRight: '8px' }} />
                                    <input className='attr-input' style={{ width: '64px' }} value={this.state.marginRight} onInput={(e) => this.setMarginRight(e.target.value)} />
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img src={icons.padding_margin} style={{ transform: 'rotate(270deg)', marginRight: '8px' }} />
                                    <input className='attr-input' style={{ width: '64px' }} value={this.state.marginBottom} onInput={(e) => this.setMarginBottom(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <button className={this.state.activeLink ? 'attr-link attr-link__active' : 'attr-link'} onClick={this.toggleApplyLink}>
                            {this.state.activeLink ? (
                                <img className='attr-link__img' src={icons.link_active} />
                            ) : (
                                <img className='attr-link__img' src={icons.link} />
                            )}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}
function matchDispatchToProps(dispatch) {
    return bindActionCreators({ setArr: setArr }, dispatch);
}
const mapStateToProps = state => {
    return {
        arr: state.arr
    }
}
export default connect(mapStateToProps, matchDispatchToProps)(MarginBox);