import React, { Component } from 'react'

export default class CustomRadio extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { labelId, name, value, labelText, checked } = this.props;
        return (
            <div className='custom-radio'>
                <input
                    className='custom-radio__input'
                    type="radio"
                    id={labelId}
                    name={name}
                    checked={checked}
                    value={value}
                    onChange={(e) => this.props.setValue(e)}
                />
                <label
                    className='custom-radio__label'
                    htmlFor={labelId}
                >
                    {labelText}
                </label>
            </div>
        )
    }
}
