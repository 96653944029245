import { Row } from "@react-email/row";
import { getImgBlock } from "./imageBlock";
import { getTextBlock } from "./textBlock";
import { Column } from '@react-email/column';
import { Section } from '@react-email/section';
import { getImgButton } from "./imageButton";

export const getSocialBlock = (block1, block2, block3, block4, params) => {
    var params1 = block1.params;
    var params2 = block2.params;
    var params3 = block3.params;
    var params4 = block4.params;

    return <Section style={{
        backgroundColor: params.backgroundColor,
        borderTopLeftRadius: params.borderTopLeftRadius,
        borderBottomLeftRadius: params.borderBottomLeftRadius,
        borderTopRightRadius: params.borderTopRightRadius,
        borderBottomRightRadius: params.borderBottomRightRadius,
        paddingTop: params.paddingTop, paddingBottom: params.paddingBottom,
        paddingLeft: params.paddingLeft, paddingRight: params.paddingRight
    }}>
        <Section align={params.align} width="600" style={{
            marginLeft: 'auto', marginRight: 'auto', width: 'auto',
        }}>
            <Column width={params1.width}>{getImgButton(params1)}
            </Column>
            <Column width={params2.width}>{getImgButton(params2)}
            </Column>
            <Column width={params3.width}>{getImgButton(params3)}
            </Column>
            <Column width={params4.width}>{getImgButton(params4)}
            </Column>
        </Section>
    </Section>;
}