export const stepperControls = {
    display: 'flex',
    margin: '0 auto',
    width: '672px',
    fontFamily: `Montserrat, sans-serif`,
}

export const stepperControlsLeft = {

}

export const stepperControlsLeftButton = {
    display: 'flex', 
    alignItems: 'center', 
    background: 'none', 
    border: 'none', 
    cursor: 'pointer'
}

export const stepperControlsLeftButtonText = {
    marginLeft: '14px', 
    color: '#34B9A0', 
    textDecoration: 'underline', 
    fontSize: '16px', 
    lineHeight: '19.5px'
}

export const stepperControlsRight = {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end'
}

export const stepperControlsRightButton = {
    display: 'flex', 
    alignItems: 'center', 
    background: 'none', 
    border: 'none', 
    cursor: 'pointer',
    fontFamily: 'inherit'
}

export const stepperControlsRightButtonText = {
    marginRight: '14px', 
    color: '#34B9A0', 
    textDecoration: 'underline', 
    fontSize: '16px', 
    lineHeight: '19.5px',
}