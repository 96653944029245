import React, {Component} from 'react';

import {icons} from "../../assets/icons";

import {images} from "../../assets/images";
import {background, container, content, footer, logo, text} from "./styles";

class Footer extends Component {
  render() {
    return (
      <footer style={{...footer}}>
        <div style={{...container}}>
          <div style={{...content}}>
            <span style={{...text}}>Powered by</span>
            <img style={{...logo}} src={icons.logo_digitum} alt='Digitum logo'/>
            <span style={{...text}}>&copy; Digitum {new Date().getFullYear()}</span>
          </div>
        </div>
        <img style={{...background}} src={images.welcome_background} alt='Background'/>
      </footer>
    );
  }
}

export default Footer;