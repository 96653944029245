import React, { Component } from 'react';
import { Tooltip } from 'react-tooltip';

import './styles.css'

export default class AttributeTooltip extends Component {
    render() {
        return (
            <>
                <div
                    className='attrTooltip'
                    data-tooltip-id="attrTooltip"
                    data-tooltip-html={
                        this.props.option === '1' ? 'Safe option – supported<br/>by all mail agents' : 
                        this.props.option === '2' ? 'Questionable option – supported<br/>by most mail agents' : 
                        'Safe option – supported<br/>by all mail agents'
                    }
                    data-tooltip-place="right"
                    style={{ backgroundColor: this.props.option === '1' ? '#34B9A0' : this.props.option === '2' ? '#FFC730' : '#34B9A0' }}
                ></div>
                <Tooltip
                    border='1px solid rgba(239, 241, 241, 0.2)'
                    id='attrTooltip'
                    className='attrTooltip-content'
                />
            </>
        )
    }
}
