import React, { Component } from 'react'
import { Fade } from 'react-reveal'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setCampaignId, setEmailsData } from '../../actions/actions';

import './styles.css'

class DeleteCampaignModal extends Component {
    componentDidMount = () => {
        document.addEventListener('click', this.handleClickOutside);
    }

    handleClickOutside = (e) => {
        if (e.target.classList.contains('deleteCampaign-modal-overlay')) {
            this.props.close();
        }
    }

    goToCampaign = () => {
        var json = {
            id: this.props.campaignId,
            token: this.props.token
        };
        fetch('GetEmails', {
            method: "POST",
            body: JSON.stringify(json),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then(x => x.json()).then(x => {
            //this.props.setArr(JSON.parse(x.data));
            //  this.props.setEmailId(elem.id);
            this.props.setEmailsData(x);
            this.props.setCampaignId(this.props.campaignId);
            this.props.setCurrentPage('campaign_details');
            window.scrollTo(0, 0);
        });
    }

    deleteEmail = () => {
        var json = {
            token: this.props.token,
            id: this.props.id
        };
        fetch('RemoveEmail', {
            method: "POST",
            body: JSON.stringify(json),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then(x => {
            this.props.close();
            this.goToCampaign();
        });
    }

    render() {
        console.log(this.props)
        return (
            <Fade>
                <div className='deleteCampaign-modal-overlay'>
                    <div className='deleteCampaign-modal'>
                        <h3 className='deleteCampaign-modal-title'>Do you really want to delete this email?</h3>
                        <div className='deleteCampaign-modal-buttons'>
                            <button onClick={this.deleteEmail}>Yes</button>
                            <button onClick={this.props.close}>No</button>
                        </div>
                    </div>
                </div>
            </Fade>
        )
    }
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({ setEmailsData: setEmailsData, setCampaignId: setCampaignId }, dispatch);
}
const mapStateToProps = state => {
    return {
        campaignId: state.campaignId
    }
}
export default connect(mapStateToProps, matchDispatchToProps)(DeleteCampaignModal);