import React, { Component } from 'react'
import { Fade } from 'react-reveal';
import { connect } from 'react-redux';
import { setArr } from '../../actions/actions';
import { bindActionCreators } from 'redux';
import { icons } from '../../assets/icons'

import './styles.css'

class GenerateLetterModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            prompt: '',
            words: 200,
            tone: '',
            elements: ['List', 'Logo', 'Image', 'Button Link']
        }
    }
    componentDidMount = () => {
        document.addEventListener('click', this.handleClickOutside);
    }

    componentWillUnmount = () => {
        document.removeEventListener('click', this.handleClickOutside);
    }

    handleClickOutside = (e) => {
        if (e.target.classList.contains('generateLetter-modal-overlay')) {
            this.props.close();
        }
    }

    plusWords = (plus) => {
        var words = this.state.words;
        words += plus;
        if (words >= 20) {
            this.setState({ words: words });
        }
    }

    handleChangeWords = (number) => {
        if (number < 0 || number > 5000) {
            return;
        }
        const re = /^[0-9]+$/;
        if (number === '' || re.test(number)) {
            this.setState({ words: Number(number) });
        };
    }

    onBlurWords = () => {
        if (this.state.words < 20) {
            this.setState({ words: 20 })
        }
    }

    addImage = (_for, _img, _width, backgroundColor, color, paddingTB) => {
        var id = this.uuidv4();
        var img = _img;
        var width = _width === undefined ? '350px' : _width;

        var _obj = {
            id: id,
            type: 'image',
            childrens: [],
            params: {
                height: 'auto',
                width: width,
                paddingTop: _for === undefined ? '12px' : (paddingTB === undefined ?  '0px' : paddingTB),
                align: 'center',
                paddingLeft: '0px',
                paddingRight: '0px',
                paddingBottom: _for === undefined ? '32px' :  (paddingTB === undefined ?  '0px' : paddingTB),
                backgroundColor: backgroundColor,
                marginTop: '0px',
                for: _for === undefined ? -99 : _for,
                marginRight: '0px',
                marginLeft: '0px',
                marginBottom: '0px',
                alt: '',
                src: img
            }
        }
        this.props.arr.push(_obj)
        var arr = Array.prototype.concat(this.props.arr);
        this.props.setArr(arr);
    }
    addListBlock = (text, backgroundColor, color) => {
        var id = this.uuidv4();
        this.addText(id, text, backgroundColor, color);
        this.addImage(id, "https://"+window.location.hostname+"/static/media/default_dot.png", "35px", backgroundColor, color);

        var _obj = {
            id: id,
            childrens: [],
            type: "list-block",
            params: {
                paddingTop: '12px',
                paddingLeft: '32px',
                paddingRight: '32px',
                paddingBottom: '12px',
                backgroundColor: backgroundColor,
                marginTop: '0px',
                for: -99,
                marginRight: '0px',
                marginLeft: '0px',
                marginBottom: '0px',
            }
        }
        this.props.arr.push(_obj)
        var arr = Array.prototype.concat(this.props.arr);
        this.props.setArr(arr);
    }
    addText = (_for, _text, backgroundColor, color) => {
        var text = 'Paragraph: Laborum laboris mollit commodo do consectetur consectetur sunt ea velit reprehenderit ad irure aliqua amet. Excepteur esse Lorem do labore laborum reprehenderit laborum mollit dolor mollit mollit quis in. Anim nulla Lorem nostrud duis voluptate ipsum sint pariatur. Esse ex fugiat pariatur officia consequat est aliqua labore sit cillum Lorem deserunt qui mollit. Quis eu Lorem cillum dolor do do. Ea sint aliquip irure exercitation ut ut et irure aute ad.';
        if (_text != undefined && _text != null) {
            text = _text;
        }
        console.log(_text);
        var id = this.uuidv4();
        var _obj = {
            id: id,
            type: 'text',
            childrens: [],
            params: {
                backgroundColor: backgroundColor,
                paddingTop: '12px',
                paddingLeft: '32px',
                paddingRight: '32px',
                text: _for !== undefined ? text : 'Lorem ipsum',
                paddingBottom: '12px',
                marginTop: 0,
                marginRight: 0,
                align: 'left',
                color: color,
                for: _for === undefined ? -99 : _for,
                marginLeft: 0,
                marginBottom: 0,
                fontFamily: 'Arial',
                fontSize: '14px',
                fontWeight: 400,
                fontHeight: '133%'
            }
        };
        this.props.arr.push(_obj)
        var arr = Array.prototype.concat(this.props.arr);
        this.props.setArr(arr);

    }
    applyAllLetter = (data) => {
        this.props.setArr([]);
        var obj = JSON.parse(data.text).email;
        for (var i = 0; i < obj.length; i++) {

            if (obj[i].type === "plain-text") {

                this.addText(-99, obj[i].text, obj[i].backgroundColor, obj[i].colorText);
            }
            else if (obj[i].type === "bullet-text") {
                this.addListBlock(obj[i].text, obj[i].backgroundColor, obj[i].colorText);
            }
            else if (obj[i].type === "logo-image") {
                this.addImage(-99, "https://"+window.location.hostname+"/static/media/g_logo_test.png", '600px', obj[i].backgroundColor, obj[i].colorText, '24px');
            }
            else if (obj[i].type === "image") {
                this.addImage(-99, "https://"+window.location.hostname+"/static/media/banner_test.png",'600px', obj[i].backgroundColor, obj[i].colorText, '24px');
            }
            else if (obj[i].type === "link-button") {
                this.addTextLink(-99, obj[i].text, obj[i].backgroundColor, obj[i].colorText);
            }
        }
        this.addSocialBlock();

        this.props.close();
        //this.props.setCurrentPage('constructor');
    }
    addSocialBlock = () => {
        var id = this.uuidv4();
        var img1 = this.addImageLink(id, "http://adbotest.digitum.nl/static/media/fb_default.png", '30px', '20px');
        var img2 = this.addImageLink(id, "http://adbotest.digitum.nl/static/media/tw_default.png", '30px', '20px');
        var img3 = this.addImageLink(id, "https://"+window.location.hostname+"/static/media/insta_default.png", '30px', '20px');
        var img4 = this.addImageLink(id, "http://adbotest.digitum.nl/static/media/yt_default.png", '30px', '20px');

        var _obj = {
            id: id,
            type: 'social-block',
            childrens: [img1, img2, img3, img4],
            params: {
                paddingTop: '12px',
                paddingLeft: '0px',
                paddingRight: '0px',
                align: 'center',
                backgroundColor: '#fff',
                paddingBottom: '12px',
                marginTop: '0px',
                for: -99,
                marginRight: '0px',
                marginLeft: '0px',
                marginBottom: '0px',
            }
        }
        this.props.arr.push(_obj)
        var arr = Array.prototype.concat(this.props.arr);
        this.props.setArr(arr);
    }
    addTextLink = (_for, text, backgroundColor, color) => {
        var id = this.uuidv4();
        var _obj = {
            id: id,
            type: 'text-link',
            childrens: [],
            params: {
                backgroundColor: backgroundColor,
                paddingTop: '12px',
                paddingLeft: '36px',
                paddingRight: '36px',
                text: text,
                color: color,
                link: 'https://digitum.nl',
                paddingBottom: '12px',
                marginTop: 0,
                marginRight: 0,
                align: 'center',
                for: _for === undefined ? -99 : _for,
                marginLeft: 0,
                marginBottom: 0,
                fontFamily: 'Arial',
                fontSize: '14px',
                fontWeight: 400,
                fontHeight: '133%',
                borderStyle: '',
                borderWidth: '',
                borderColor: '',
                borderTopLeftRadius: '4px',
                borderBottomLeftRadius: '4px',
                borderTopRightRadius: '4px',
                borderBottomRightRadius: '4px',
            }
        };
        this.props.arr.push(_obj)
        var arr = Array.prototype.concat(this.props.arr);
        this.props.setArr(arr);
    }
    addImageLink = (_for, _img_link, _size, _pRight) => {
        var id = this.uuidv4();
        var img_link = _img_link === undefined ? "https://"+window.location.hostname+"/static/media/default_button.png" : _img_link;
        var size = _size === undefined ? '300px' : _size;
        var _obj = {
            id: id,
            type: 'link-img',
            childrens: [],
            params: {
                height: 'auto',
                width: size,
                paddingTop: _for === undefined ? '12px' : '0px',
                for: _for === undefined ? -99 : _for,
                align: 'center',
                paddingLeft: '0px',
                link: "https://digitum.nl",
                paddingRight: '0px',
                backgroundColor: '#fff',
                paddingBottom: _for === undefined ? '12px' : '0px',
                marginTop: '0px',
                marginRight: _pRight === undefined ? '0px' : _pRight,
                marginLeft: '0px',
                marginBottom: '0px',
                alt: '',
                src: img_link
            }
        }
        this.props.arr.push(_obj)
        var arr = Array.prototype.concat(this.props.arr);
        this.props.setArr(arr);
        return id;

    }
    setSelectedElements = (e) => {
        let updatedList = [...this.state.elements];
        if (e.target.checked) {
            updatedList = [...this.state.elements, e.target.value];
        } else {
            updatedList.splice(this.state.elements.indexOf(e.target.value), 1)
        }
        this.setState({ elements: updatedList });
    }
    generateText = () => {
        if (this.state.prompt.length === 0) {
            //  this.applyAllLetter(this.state.generateText);
            return;
        }
        var json = {
            token: this.props.token,
            words: this.state.words,
            prompt: this.state.prompt,
            tone: this.state.tone,
            elements: this.state.elements
        };

        // this.setState({ generateText: '' });
        this.setState({ loading: true });
        fetch('ChatGPTLetter', {
            method: "POST",
            body: JSON.stringify(json),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then(x => x.json()).then(x => { this.setState({ loading: false }); this.applyAllLetter(x) });
    }
    uuidv4() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }
    render() {
        return (
            <Fade>
                <div className='generateLetter-modal-overlay'>
                    <div className='generateLetter-modal'>
                        <div className='generateLetter-modal-close'>
                            <button onClick={this.props.close}>
                                <img src={icons.close} />
                            </button>
                        </div>
                        <div className='generateLetter-row'>
                            <div className='generateLetter-col'>
                                <h3 className='generateLetter-col-title'>Describe in details what the mailer should be about</h3>
                                <textarea className='generateLetter-describe' onInput={(e) => this.setState({ prompt: e.target.value })} rows={3} />
                            </div>
                            <div className='generateLetter-col'>
                                <h3 className='generateLetter-col-title'>Text volume (words)</h3>
                                <div className='generateLetter-words'>
                                    <div className='generateLetter-words-manual'>
                                        <i onClick={() => this.plusWords(-1)}>-</i>
                                        <input value={this.state.words} onChange={(e) => this.handleChangeWords(e.target.value)} onBlur={this.onBlurWords} />
                                        <i onClick={() => this.plusWords(1)}>+</i>
                                    </div>
                                    <ul className='generateLetter-words-select'>
                                        <li onClick={() => this.setState({ words: 20 })}>20</li>
                                        <li onClick={() => this.setState({ words: 50 })}>50</li>
                                        <li onClick={() => this.setState({ words: 100 })}>100</li>
                                        <li onClick={() => this.setState({ words: 200 })}>200</li>
                                        <li onClick={() => this.setState({ words: 300 })}>300</li>
                                        <li onClick={() => this.setState({ words: 500 })}>500</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='generateLetter-row'>
                            <div className='generateLetter-col'>
                                <h3 className='generateLetter-col-title'>Tone of voice</h3>
                                <div className='generateLetter-select'>
                                    <div>
                                        <input
                                            onChange={() => this.setState({ tone: 'Formal' })}
                                            className='generateLetter-select-input'
                                            id='formal'
                                            type='radio'
                                            name="tone"
                                        />
                                        <label className='generateLetter-select-label' for="formal">
                                            Formal
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                            onChange={() => this.setState({ tone: 'Friendly' })}
                                            className='generateLetter-select-input'
                                            id='friendly'
                                            type='radio'
                                            name="tone"
                                        />
                                        <label className='generateLetter-select-label' for="friendly">
                                            Friendly
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                            onChange={() => this.setState({ tone: 'Business' })}
                                            className='generateLetter-select-input'
                                            id='business'
                                            type='radio'
                                            name="tone"
                                        />
                                        <label className='generateLetter-select-label' for="business" >
                                            Business
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                            onChange={() => this.setState({ tone: 'Informal' })}
                                            className='generateLetter-select-input'
                                            id='informal'
                                            type='radio'
                                            name="tone"
                                        />
                                        <label className='generateLetter-select-label' for="informal" >
                                            Informal
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                            onChange={() => this.setState({ tone: 'For kids' })}
                                            className='generateLetter-select-input'
                                            id='for kids'
                                            type='radio'
                                            name="tone"
                                        />
                                        <label className='generateLetter-select-label' for="for kids" >
                                            For kids
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                            onChange={() => this.setState({ tone: 'Funny' })}
                                            className='generateLetter-select-input'
                                            id='funny'
                                            type='radio'
                                            name="tone"
                                        />
                                        <label className='generateLetter-select-label' for="funny" >
                                            Funny
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                            onChange={() => this.setState({ tone: 'Slang' })}
                                            className='generateLetter-select-input'
                                            id='slang'
                                            type='radio'
                                            name="tone"
                                        />
                                        <label className='generateLetter-select-label' for="slang" >
                                            Slang
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                            onChange={() => this.setState({ tone: 'Romantic' })}
                                            className='generateLetter-select-input'
                                            id='romantic'
                                            type='radio'
                                            name="tone"
                                        />
                                        <label className='generateLetter-select-label' for="romantic" >
                                            Romantic
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className='generateLetter-col'>
                                <h3 className='generateLetter-col-title'>Elements</h3>
                                <div className='generateLetter-select'>
                                    <div>
                                        <input
                                            className='generateLetter-select-input'
                                            id='list'
                                            type='checkbox'
                                            name="elements"
                                            value='List'
                                            onChange={this.setSelectedElements}
                                            checked={this.state.elements.includes('List')}
                                        />
                                        <label className='generateLetter-select-label' for="list" >
                                            List
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                            className='generateLetter-select-input'
                                            id='logo'
                                            type='checkbox'
                                            name="elements"
                                            value='Logo'
                                            onChange={this.setSelectedElements}
                                            checked={this.state.elements.includes('Logo')}
                                        />
                                        <label className='generateLetter-select-label' for="logo" >
                                            Logo
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                            className='generateLetter-select-input'
                                            id='image'
                                            type='checkbox'
                                            name="elements"
                                            value='Image'
                                            onChange={this.setSelectedElements}
                                            checked={this.state.elements.includes('Image')}
                                        />
                                        <label className='generateLetter-select-label' for="image" >
                                            Image
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                            className='generateLetter-select-input'
                                            id='button Link'
                                            type='checkbox'
                                            name="elements"
                                            value='Button Link'
                                            onChange={this.setSelectedElements}
                                            checked={this.state.elements.includes('Button Link')}
                                        />
                                        <label className='generateLetter-select-label' for="button Link" >
                                            Button Link
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='generateLetter-modal-generate'>
                            <button onClick={() => this.generateText()} disabled={!this.state.prompt || !this.state.words || !this.state.tone || this.state.elements.length === 0 || this.state.loading}>
                                {this.state.loading ? (
                                    <img src={icons.m_loading} style={{filter: 'brightness(0%)', transform: 'scale(0.8)'}}/>
                                ) : (
                                    <>
                                        <img src={icons.sparkles} />
                                        <span>Generate</span>
                                    </>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </Fade>

        )
    }
}
function matchDispatchToProps(dispatch) {
    return bindActionCreators({ setArr: setArr }, dispatch);
}
const mapStateToProps = state => {
    return {
        arr: state.arr,
        token: state.token
    }
}
export default connect(mapStateToProps, matchDispatchToProps)(GenerateLetterModal);