export const login = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  }
  export const container = {
    maxWidth: '1024px',
    width: '100%',
    margin: '0 auto',
  }
  export const content = {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  }
  export const logo = {
    marginBottom: '162px',
  }
  export const error = {
    marginBottom: '10px',
    fontSize: '12px',
    lineHeight: '14.5px',
    color: '#c9503a',
    fontFamily: 'Montserrat'
  }
  export const form = {
    width: '320px',
  }
  export const formGroup = {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '16px',
  }
  export const formGroupInput = {
    padding: '20px 15px 5px 15px',
    width: '100%',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '17px',
    background: 'white',
    outline: 'none',
    border: 'none',
    borderTopRightRadius: '5px',
    borderTopLeftRadius: '5px',
    borderBottom: '2px solid #748E8C'
  }
  export const button = {
    marginBottom: '8px',
    width: '100%',
    padding: '15px 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: 'none',
    cursor: 'pointer',
    borderRadius: '5px',
    background: '#14201E',
  }
  export const buttonText = {
    marginRight: '15px',
    color: '#FFFFFF',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '17px'
  }
  export const links = {
    width: '320px',
    display: 'flex',
    justifyContent: 'space-between',
    fontFamily: 'Montserrat'
  }
  export const linksItem = {
    fontSize: '12px',
    lineHeight: '15px',
    fontWeight: '500',
    textDecoration: 'underline',
    color: '#14201E'
  }