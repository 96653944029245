import { Row } from "@react-email/row";
import { getImgBlock } from "./imageBlock";
import { getTextBlock } from "./textBlock";
import { Column } from '@react-email/column';
import { Section } from '@react-email/section';

export const getTextThreeBlock = (text1, text2,text3, params) => {
    var params_1 = text1.params;
    var params_2 = text2.params;
    var params_3 = text3.params;
    return <Row style={{
        paddingTop: params.paddingTop, paddingLeft: params.paddingLeft, paddingRight: params.paddingRight, paddingBottom: params.paddingBottom,
        marginTop: params.marginTop, marginLeft: params.marginLeft, marginRight: params.marginRight, marginBottom: params.marginBottom, backgroundColor: params.backgroundColor
    }}>
        <Column style={{}}>{getTextBlock(params_1)}</Column>
        <Column style={{}}>{getTextBlock(params_2)}</Column>
        <Column style={{}}>{getTextBlock(params_3)}</Column>
    </Row>;
}