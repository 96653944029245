import React, { Component } from 'react'
import { Fade, Zoom } from 'react-reveal';

import { icons } from '../../assets/icons'

import './styles.css'

export default class AboutModal extends Component {
    render() {
        const { close } = this.props;
        return (
            <Fade>
                <div className='about-modal-overlay'>
                    <div className='about-modal'>
                        <div className='about-modal-top'>
                            <div className='about-modal-img'>
                                <img src={icons.logo_adbot_small} />
                            </div>
                            <div className='about-modal-content'>
                                <h3 className='about-modal-title'>Adbot Desktop 1.0</h3>
                                <span className='about-modal-subtitle'>Powered by Digitum</span>
                                <span className='about-modal-text'>
                                    <a href={'https://'+window.location.hostname+'/static/media/Privacy_policy_of_Digitum_LLC.pdf'} target='_blank'>Privacy Policy</a>
                                    <span></span>
                                    Copyright © 2023 Digitum, Inc.
                                </span>
                            </div>
                        </div>
                        <div className='about-modal-bottom'>
                            <button className='about-modal-button' onClick={close}>OK</button>
                        </div>
                    </div>
                </div>
            </Fade>

        )
    }
}
