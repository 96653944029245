import React, { Component } from 'react'

import { icons } from '../../assets/icons';

import { stepper, stepperItem, stepperItemCircle, stepperItemCircleNumber, stepperItemCircleIcon, stepperItemTitle } from './styles';

export default class Stepper extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div style={{ ...stepper }}>
                {this.props.steps.map((step, index) => (
                    <div key={index} style={{ ...stepperItem }}>
                        <div style={{ ...stepperItemCircle, backgroundColor: this.props.currentStep === index + 1 ? '#34B9A0' : index + 1 < this.props.currentStep ? '#34B9A0' : '#5F7675' }}>
                            <div style={{ ...stepperItemCircleNumber }}>{index + 1}</div>
                            {this.props.currentStep === index + 1 && <img src={icons.rozhki} style={{ ...stepperItemCircleIcon }} />}
                        </div>
                        <span
                            style={{ ...stepperItemTitle, color: this.props.currentStep === index + 1 ? '#34B9A0' : index + 1 < this.props.currentStep ? '#34B9A0' : '#5F7675' }}>
                            {step}
                        </span>
                    </div>
                ))}
                <div className='progress'>
                    <div className='progress-line' style={{ width: this.props.width }}></div>
                </div>
            </div>
        )
    }
}
