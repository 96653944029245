import React, { Component } from 'react'
import { Fade } from 'react-reveal'
import EditorToolbar, { formats, modules } from '../EditorToolbar/EditorToolbar'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css';

import { icons } from '../../assets/icons'

import './styles.css'

export default class EditTextModal extends Component {

    componentDidMount = () => {
        document.addEventListener('mousedown', this.handleClickOutside);
        var elm = Array.prototype.slice.call(document.getElementsByTagName('SPAN'));
        for(var i = 0; i < elm.length; i++){
            var data_value = elm[i].getAttribute('data-value');
            if (data_value != null && data_value != undefined){
                if (data_value == 'custom-color'){
                   console.log(elm[i]);
                    elm[i].style.backgroundImage = "linear-gradient( 89.7deg, rgba(223,0,0,1) 2.7%, rgba(214,91,0,1) 15.1%, rgba(233,245,0,1) 29.5%, rgba(23,255,17,1) 45.8%, rgba(29,255,255,1) 61.5%, rgba(5,17,255,1) 76.4%, rgba(202,0,253,1) 92.4% )";
                }
            }
        }
    }

    componentWillUnmount = () => {
        document.removeEventListener('mousedown', this.handleClickOutside)
    }

    handleClickOutside = (e) => {
        if (e.target.classList.contains('editText-modal-overlay')) {
            this.props.close();
        }
    }

    render() {
        let { close, value, setValue, setText } = this.props;
        return (
            <Fade duration={500}>
                <div className='editText-modal-overlay'>
                    <div className='editText-modal'>
                            <button className='editText-modal__top' onClick={close}>
                                <img src={icons.close} />
                            </button>
                        
                        <EditorToolbar />
                        <ReactQuill
                            theme='snow'
                            value={value}
                            modules={modules}
                            formats={formats}
                            className='editText-modal__editor'
                            onChange={(value) => setValue(value)}
                            onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                    console.log(e);
                                    e.preventDefault();
                                    e.stopPropagation();
                                }
                            }}
                        />
                        <div className='editText-modal__bottom'>
                            <button onClick={setText}>Apply</button>
                        </div>
                    </div>
                </div>
            </Fade>
        )
    }
}
