import React, { Component } from 'react'

import { icons } from '../../assets/icons'

import faqData from '../../faq.json';

import './styles.css'

export default class FAQ extends Component {
    constructor() {
        super()
        this.state = {
            activeBtn: 0,
            activeBtnCategory: 'General',
            btnCategoryData: [
                {
                    id: 0,
                    title: 'General'
                },
                {
                    id: 1,
                    title: 'Text block'
                },
                {
                    id: 2,
                    title: 'Image'
                },
                {
                    id: 3,
                    title: 'Button block'
                },
                {
                    id: 4,
                    title: 'List block'
                },
                {
                    id: 5,
                    title: 'Logo block'
                },
                {
                    id: 6,
                    title: 'Social media block'
                },
            ],
            clicked: null
        }
    }

    setActiveBtn = (num, category) => {
        this.setState({ activeBtn: num, activeBtnCategory: category, clicked: null })
    }

    toggleAccordion = (index) => {
        if (this.state.clicked === index) {
            this.setState({ clicked: null })
        } else {
            this.setState({ clicked: index })
        }
    }

    getRotate = (i) => {
        if (this.state.clicked === i) {
            return 'rotate(-90deg)';
        } else {
            return 'rotate(90deg)';
        }
    }

    renderContent = () => {
        let res = faqData.faq_data[0]._obj.filter(i => i.name === this.state.activeBtnCategory)[0].data;
        return (
            <ul className='accordion' style={{ listStyleType: 'none' }}>
                {res.map((item, index) => (
                    <li key={index} className={this.state.clicked === index ? 'accordion-item accordion-item_active' : 'accordion-item'}>
                        <div className='accordion-item-question' onClick={() => this.toggleAccordion(index)}>
                            <button>{item.q}</button>
                            <div className='accordion-item-question-img'>
                                <img src={icons.next} style={{ transform: this.getRotate(index), opacity: this.state.clicked === index ? '1' : '0.4' }} />
                            </div>
                        </div>
                        <div className={this.state.clicked === index ? 'accordion-item-answer accordion-item-answer_open' : 'accordion-item-answer'}>
                            <div className='accordion-item-answer_inner'>
                                <div className='accordion-item-answer_content'>{item.a}</div>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        )
    }

    render() {
        return (
            <div className='faq'>
                <div className='faq-left'>
                    <ul className='faq-left-list'>
                        {this.state.btnCategoryData.map(item => (
                            <li
                                key={item.id}
                                className='faq-left-list-btn'
                                onClick={() => this.setActiveBtn(item.id, item.title)}
                                style={{ borderLeft: this.state.activeBtn === item.id ? '3px solid #34B9A0' : '3px solid transparent' }}
                            >
                                {item.title}
                            </li>
                        ))}
                    </ul>
                </div>
                <div className='faq-right'>
                    {this.renderContent()}
                </div>
            </div>
        )
    }
}
