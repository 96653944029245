var arr = [];
var selectedElm = -99;
var generatedImages = [];
var loadingAIImages = false;
const initState = {
    arr: [],
    selectedElm: -99,
    token: null,
    campaignName: '',
    campaignId: -99,
    selectedChannels: '',
    email_id: -99,
    loadingAIImages: false,
    emails_data: [],
    generatedImages: []
}
const reducer = (state = initState, action) => {
    const newState = { ...state };
    switch (action.type) {
        case "PUT":
            return { ...state, arr: action.payload }
        case "PUT_ELM":
            return { ...state, selectedElm: action.payload.id }
        case "PUT_AI_IMAGES":
            return { ...state, generatedImages: action.payload }
        case "PUT_AI_IMG_LOADING":
            return { ...state, loadingAIImages: action.payload }
        case "SET_TOKEN":
            return { ...state, token: action.payload }
        case "SET_CAMPAIGN_NAME":
            return { ...state, campaignName: action.payload }
        case "SET_SELECTED_CHANNELS":
            return { ...state, selectedChannels: action.payload }
        case "SET_EMAIL_ID":
            return { ...state, email_id: action.payload }
        case "SET_EMAILS_DATA":
            return { ...state, emails_data: action.payload }
        case "SET_CAMPAIGN_ID":
            return { ...state, campaignId: action.payload }
    }

    return newState;
}
export default reducer;
