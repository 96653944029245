import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Stepper from '../../components/Stepper/Stepper'
import StepperControls from '../../components/StepperControls/StepperControls'
import Objectives from '../../components/steps/Objectives/Objectives'
import {setArr, setCampaignId, setEmailId} from '../../actions/actions';
import { container, content, newCampaign } from './styles'
import Layout from '../../components/steps/Layout/Layout';

class NewCampaign extends Component {
    constructor(props) {
        super(props);
        this.state = {
            steps: ['Objectives', 'Layout'],
            totalSteps: 2,
            currentStep: 1,
        }
    }

    displayStep = () => {
        switch (this.state.currentStep) {
            case 1:
                return <Objectives />;
            case 2:
                return <Layout />;
        }
    }

    showPrevStep = () => {
        return this.setState({ currentStep: this.state.currentStep - 1 })
    }

    showNextStep = () => {
        // if (this.state.steps.length === this.state.currentStep) return;
        // return this.setState({ currentStep: this.state.currentStep + 1 })
        var name = (this.props.campaignName);
        var type = (this.props.selectedChannels);
        if (name.length > 0 && type.length > 0) {
            var json = {
                token: this.props.token,
                name: name,
                types: [parseInt(type)]
            };
            fetch('CreateCampaign', {
                method: "POST",
                body: JSON.stringify(json),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            }).then(x=> x.json()).then(x=> {
                // Put new redux variblse
                this.props.setArr([]);
                this.props.setEmailId(x.emailId); 
                this.props.setCampaignId(x.campaignId);
                this.props.setCurrentPage('constructor');
                
            });
        }else{

        }
        //  this.props.setCurrentPage('constructor');
    }

    render() {
        const progressWidth = `${(100 / (this.state.totalSteps - 1)) * (this.state.currentStep - 1)}%`;
        return (
            <div style={{ ...newCampaign }}>
                <div style={{ ...container }}>
                    <div style={{ ...content }}>
                        <Stepper steps={this.state.steps} currentStep={this.state.currentStep} width={progressWidth} />
                        {this.displayStep()}
                        <StepperControls currentStep={this.state.currentStep} showPrevStep={this.showPrevStep} showNextStep={this.showNextStep} />
                    </div>
                </div>
            </div>
        )
    }
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({setEmailId: setEmailId, setArr: setArr, setCampaignId: setCampaignId}, dispatch);
}
const mapStateToProps = state => {
    return {
        campaignName: state.campaignName,
        selectedChannels: state.selectedChannels,
        token: state.token,
        arr: state.arr
    }
}
export default connect(mapStateToProps, matchDispatchToProps)(NewCampaign);