import { Component } from "react";
import { Route, Routes } from "react-router-dom";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import withCookies from "react-cookie/cjs/withCookies";
import { setToken } from "./actions/actions";

import MainTemplate from "./templates/MainTemplate";
import LoginTemplate from "./templates/LoginTemplate";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authenticated: false,
      loginError: null,
      cookies: this.props.cookies.get('ppt_a_login'),
    };
  }

  setLoginError = (error) => {
    this.setState({loginError: error})
  }

  logIn = async (username, pphash) => {
    if (username === '' && pphash === '') {
      this.setLoginError('Enter login and password');
      return;
    }
    await fetch('Auth', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({username: username, pphash: pphash})
    })
      .then(res => res.json())
      .then(data => {
        switch (data.code) {
          case 200: 
            this.props.setToken(data.recCode);
            this.setState({ authenticated: true, loginError: null });
            break;
          case 403:
            this.setLoginError('Enter the correct username and password')
            break;
          case 500:
            this.setLoginError('Server error, please try again later.')
            break;
        }
      })
  }

  logOut = () => {
    this.setState({ authenticated: false });
    this.props.setToken(null);
    this.props.cookies.remove('ppt_a_login');
  }

  componentDidMount = () => {
    if (this.state.cookies != null) {
      this.setState({authenticated: true})
    }
  }

  render() {
    return (
      <>
          <Routes>
            <Route path='/' element={<MainTemplate logOut={this.logOut} authenticated={this.state.authenticated} />} />
            <Route 
              path='/login' element={<LoginTemplate loginError={this.state.loginError} authenticated={this.state.authenticated} logIn={this.logIn} />} />
          </Routes>
      </>
    )
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({ setToken: setToken }, dispatch);
}
const mapStateToProps = state => {

  return {
      token: state.token,
  }
}
export default withCookies(connect(mapStateToProps, matchDispatchToProps)(App));