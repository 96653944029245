import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setArr } from '../../actions/actions';
import { bindActionCreators } from 'redux';
import { Fade, Reveal } from 'react-reveal';
import { icons } from '../../assets/icons/index';
import './styles.css';
import EditorToolbar, { formats, modules } from '../../components/EditorToolbar/EditorToolbar';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
class AiText extends Component {
    constructor(props) {
        super(props);
        this.state = {
            prompt: '',
            openWords: true,
            openTone: true,
            loading: false,
            words: 200,
            generateText: ""
        };
    }
    plusWords = (plus) => {
        var words = this.state.words;
        words += plus;
        if (words >= 20) {
            this.setState({ words: words });
        }
    }

    handleChangeWords = (number) => {
        if (number < 0 || number > 5000) {
            return;
        }
        const re = /^[0-9]+$/;
        if (number === '' || re.test(number)) {
            this.setState({ words: Number(number) });
        };
    }

    onBlurWords = () => {
        if (this.state.words < 20) {
            this.setState({ words: 20 })
        }
    }

    componentDidMount = () => {
    }

    getCheckedTone = () => {
        var selected = 'i3';
        for (var i = 1; i < 9; i++) {
            var elem = document.getElementById('i' + i);
            if (elem.checked) {
                selected = elem.id;
                break;
            }
        }
        switch (selected) {
            case 'i1':
                return 'Formal';
            case 'i2':
                return 'Friendly';
            case 'i3':
                return 'Business';
            case 'i4':
                return 'Informal';
            case 'i5':
                return 'For kids';
            case 'i6':
                return 'Funny';
            case 'i7':
                return 'Slang';
            case 'i8':
                return 'Romantic';
            default:
                return "Business";
        }
    }
    addText = (_for, _text) => {
        var text = 'Paragraph: Laborum laboris mollit commodo do consectetur consectetur sunt ea velit reprehenderit ad irure aliqua amet. Excepteur esse Lorem do labore laborum reprehenderit laborum mollit dolor mollit mollit quis in. Anim nulla Lorem nostrud duis voluptate ipsum sint pariatur. Esse ex fugiat pariatur officia consequat est aliqua labore sit cillum Lorem deserunt qui mollit. Quis eu Lorem cillum dolor do do. Ea sint aliquip irure exercitation ut ut et irure aute ad.';
        if (_text != undefined && _text != null) {
            text = _text;
        }
        console.log(_text);
        var id = this.uuidv4();
        var _obj = {
            id: id,
            type: 'text',
            childrens: [],
            params: {
                backgroundColor: '#fff',
                paddingTop: '12px',
                paddingLeft: '32px',
                paddingRight: '32px',
                text: _for !== undefined ? text : 'Lorem ipsum',
                paddingBottom: '12px',
                marginTop: 0,
                marginRight: 0,
                align: 'left',
                color: '#000',
                for: _for === undefined ? -99 : _for,
                marginLeft: 0,
                marginBottom: 0,
                fontFamily: 'Arial',
                fontSize: '14px',
                fontWeight: 400,
                fontHeight: '133%'
            }
        };
        this.props.arr.push(_obj)
        var arr = Array.prototype.concat(this.props.arr);
        this.props.setArr(arr);

    }
    setTestText = () =>{
        var text = {"text":"Introducing our latest banking innovation - the all-new Good Cashback service! We are thrilled to present this groundbreaking feature that aims to revolutionize the way you manage your finances and maximize your savings. With Good Cashback, we are committed to providing our valued customers with an unparalleled banking experience that goes beyond traditional offerings.\n\nWhat sets our Good Cashback service apart is its unique ability to reward you for your everyday banking activities. We understand that every penny counts, and that's why we have designed this service to help you earn cashback on your transactions, making your money work harder for you. Whether you're making purchases online, paying bills, or simply withdrawing cash from an ATM, our Good Cashback service ensures that you receive a percentage of your spending back into your account.\n\nThe process is simple and hassle-free. Once you enroll in our Good Cashback service, every eligible transaction you make will automatically qualify for cashback rewards. The more you use your account, the more cashback you earn. It's that straightforward! We believe in transparency, which is why we provide detailed statements outlining your cashback earnings, allowing you to track your progress and witness the growth of your savings firsthand.\n\nMoreover, our Good Cashback service is not limited to a specific category of purchases. Whether you're shopping for groceries, dining out, or"};
        var _text = text.text;
        _text = _text.replace(/\n\n/g, '<br/><br/>');
        this.setState({generateText: _text});
    }
    applyText = () =>{
        var raw_text = this.state.generateText;
        var a_r = raw_text.split('<p><br></p>');
        for(var i = 0; i < a_r.length; i++){
            this.addText(-99, a_r[i]);
        }
        this.props.setCurrentPage('constructor');
        // console.log(a_r);
    }
    /*applyText = () => {
        var text = document.getElementsByClassName('ql-editor')[0].innerText;
        //  text = text.replace(/<p>/g,'').replace(/<\/p>/g, '\n').replace(/<br>/g, '<br/>');
        text = text.replace(/\n\n/g, '\n');
        console.log(text);
        
        var _arr = text.split('\n\n');
        console.log(_arr);
       // return;
        var arr = [];
        for (var i = 0; i < _arr.length; i++) {
            if (_arr[i].length === 0 && i != 0) {
                arr[i - 1] += '\n';
            } else if (_arr[i] !== undefined) {
                arr.push(_arr[i]);
            }
        }
        for (var i = 0; i < arr.length; i++) {
            if (arr[i] !== undefined && arr[i] !== 'undefined' && arr[i].toString() !== "undefined\n") {
                this.addText(-99, arr[i].replace(/\n/g, '<br/>'));
            }

        }
        this.props.setCurrentPage('constructor');
        //  Array.prototype.slice.call(arr).forEach((item) => { this.addText(-99, item); });

        //   console.log(arr);
    }*/
    generateText = () => {
        if (this.state.prompt.length === 0) {
            return;
        }
        var json = {
            token: this.props.token,
            words: this.state.words,
            prompt: this.state.prompt,
            tone: this.getCheckedTone()
        };
        this.setState({ generateText: '' });
        this.setState({ loading: true });
        fetch('ChatGPTText', {
            method: "POST",
            body: JSON.stringify(json),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then(x => x.json()).then(x => this.setState({ generateText: x.text.replace(/\n/g, '<br/>'), loading: false }));
    }
    uuidv4() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }

    render() {
        return (
            <div className="ai-text-screen">
                <div className='ai-header'>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexGrow: '1', paddingLeft: '54px' }}>
                        <img src={icons.adbot_mini} />
                        <p style={{ marginLeft: '6px', fontWeight: 700, fontSize: '14px', fontFamily: 'Montserrat' }}>Adbot Text Generator</p>
                    </div>
                    <div onClick={() => this.props.setCurrentPage('constructor')} style={{ cursor: 'pointer', fontFamily: 'Montserrat' }}>Cancel</div>
                </div>
                <div className='ai-text-content'>
                    <div className='ai-text-params'>
                        <div>
                            <p>Describe in details what the mailer should be about</p>
                            <textarea onInput={(e) => this.setState({ prompt: e.target.value })}>

                            </textarea>
                            <br />
                            <br />
                            <br />
                            <p onClick={() => this.setState({ openWords: !this.state.openWords })} style={{ cursor: 'pointer' }}><img style={{ filter: 'brightness(0%)', marginRight: '4px', marginLeft: '4px', marginTop: '2px', rotate: this.state.openWords ? '90deg' : '0deg', }} src={icons.next} />Text volume (words)</p>
                            <Fade top when={this.state.openWords} duration={300}>
                                <div style={{ display: this.state.openWords ? '' : 'none' }} className='words-block'>
                                    <div className='manual-words'>
                                        <i onClick={() => this.plusWords(-1)}>-</i>
                                        <input value={this.state.words} onChange={(e) => this.handleChangeWords(e.target.value)} onBlur={this.onBlurWords} />
                                        <i onClick={() => this.plusWords(1)}>+</i>
                                    </div>
                                    <ul className='select-words'>
                                        <li onClick={() => this.setState({ words: 20 })}>20</li>
                                        <li onClick={() => this.setState({ words: 50 })}>50</li>
                                        <li onClick={() => this.setState({ words: 100 })}>100</li>
                                        <li onClick={() => this.setState({ words: 200 })}>200</li>
                                        <li onClick={() => this.setState({ words: 300 })}>300</li>
                                        <li onClick={() => this.setState({ words: 500 })}>500</li>
                                    </ul>
                                </div>
                            </Fade>
                            <br />
                            <br />
                            <br />
                            <p onClick={() => this.setState({ openTone: !this.state.openTone })} style={{ cursor: 'pointer' }}><img style={{ filter: 'brightness(0%)', marginRight: '4px', marginLeft: '4px', marginTop: '2px', rotate: this.state.openTone ? '90deg' : '0deg', }} src={icons.next} /> Tone of voice</p>
                            <Fade top when={this.state.openTone} duration={300}>
                                <div style={{ display: this.state.openTone ? '' : 'none' }} className='words-block'>
                                    <div className='select-tone'>
                                        <div>
                                            <input id='i1' type='radio' className='tone-input' name="tone" />
                                            <label for="i1" className='tone-label'>
                                                Formal
                                            </label>
                                        </div>
                                        <div>
                                            <input id='i2' type='radio' className='tone-input' name="tone" />
                                            <label for="i2" className='tone-label'>
                                                Friendly
                                            </label>
                                        </div>
                                        <div>
                                            <input id='i3' type='radio' className='tone-input' name="tone" />
                                            <label for="i3" className='tone-label'>
                                                Business
                                            </label>
                                        </div>
                                        <div>
                                            <input id='i4' type='radio' className='tone-input' name="tone" />
                                            <label for="i4" className='tone-label'>
                                                Informal
                                            </label>
                                        </div>
                                        <div>
                                            <input id='i5' type='radio' className='tone-input' name="tone" />
                                            <label for="i5" className='tone-label'>
                                                For kids
                                            </label>
                                        </div>
                                        <div>
                                            <input id='i6' type='radio' className='tone-input' name="tone" />
                                            <label for="i6" className='tone-label'>
                                                Funny
                                            </label>
                                        </div>
                                        <div>
                                            <input id='i7' type='radio' className='tone-input' name="tone" />
                                            <label for="i7" className='tone-label'>
                                                Slang
                                            </label>
                                        </div>
                                        <div>
                                            <input id='i8' type='radio' className='tone-input' name="tone" />
                                            <label for="i8" className='tone-label'>
                                                Romantic
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                        </div>


                        <div style={{ alignSelf: 'flex-end' }}>
                            <p onClick={() => this.generateText()} style={{ marginBottom: '0px', fontWeight: '500' }} className='generate-button'><img style={{ filter: 'brightness(0%)', position: 'relative', top: '2px' }} src={icons.sparkles} /> Generate</p>
                        </div>
                    </div>
                    <div className='ai-text-box'>
                        {/* <pre className='generated-text'>
                            {this.state.generateText}
                            <div id="loading" style={{ display: this.state.loading ? '' : 'none', width: '100%', height: '100%', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} >
                                <Reveal when={this.state.loading} effect="fadeInUp">
                                    <div style={{ position: 'absolute', top: '50%', right: '50%', transform: "translate(50%,-50%)" }}>  <img src={icons.loading} style={{width: '100px', height: '100px'}} /></div>
                                </Reveal>
                            </div>
                        </pre> */}
                        <div style={{ marginTop: '8px' }}>
                            <EditorToolbar />
                            <ReactQuill
                                theme='snow'
                                value={this.state.generateText}
                                modules={modules}
                                formats={formats}
                                className='editorText'
                                onChange={(value) => this.setState({ generateText: value })}
                                readOnly={this.state.generateText.length > 0 ? false : true}
                            />
                        </div>
                        <div id="loading" style={{ display: this.state.loading ? '' : 'none', width: '100%', height: '100%', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} >
                            <Reveal when={this.state.loading} effect="fadeInUp">
                                <div style={{ position: 'absolute', top: '50%', right: '50%', transform: "translate(50%,-50%)" }}>  <img src={icons.loading} style={{ width: '100px', height: '100px' }} /></div>
                            </Reveal>
                        </div>
                        <div onClick={() => this.applyText()} className='apply-ai-text-btn'><p>Apply to layout</p></div>
                    </div>
                </div>
            </div>
        );
    }
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({ setArr: setArr }, dispatch);
}
const mapStateToProps = state => {
    return {
        arr: state.arr,
        token: state.token
    }
}
export default connect(mapStateToProps, matchDispatchToProps)(AiText);
