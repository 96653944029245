import { Text } from '@react-email/text';
import { Container } from '@react-email/container'
function createText(text) {
    //    console.log(text);

    var p = document.createElement('P');
    if (text.indexOf('<p') != -1) {
        p.innerHTML = text;
        try {
            var elms = Array.prototype.slice.call(p.getElementsByTagName('P'));
            elms[0].style.margin = '0';
            var _out = p.innerHTML.toString();
            return _out;
        } catch (e) {
            return p;
        }
    } else {
        p.innerText = text;
        return p.outerHTML;
    }

}


export const getTextBlock = (params, main) => {
    var text = params.text;
    var text2 = createText(text);
    var rtl = '';
    if (params.dir !== undefined && params.dir !== null){
        rtl = params.dir ? 'rtl' : '';
    }
    if (text.indexOf('<p') != -1) {
        text = text2;
    }
    var width = '601px';
    console.log(main);
    if (main !== undefined && main !== null){
        var _params = main;
        var pR = parseInt(_params.paddingRight);
        var pL = parseInt(_params.paddingLeft);
        console.log(_params);
        var _width = 601 - pR - pL;
        width = _width.toString() + 'px';
    }
    return <table width={params.for == -99 ? width : 'auto'} cellspacing="0" align={params.align} cellpadding="0" style={{

        borderTopLeftRadius: params.borderTopLeftRadius,
        borderBottomLeftRadius: params.borderBottomLeftRadius,
        borderTopRightRadius: params.borderTopRightRadius,
        borderBottomRightRadius: params.borderBottomRightRadius,
        backgroundColor: params.backgroundColor,
        width: params.for == -99 ? width : '',

        marginTop: params.marginTop, marginLeft: params.marginLeft, marginRight: params.marginRight, marginBottom: params.marginBottom,
    }}>
        <tbody>
            <tr >
                <td dir={rtl} style={{
                    marginTop: params.marginTop,
                    marginLeft: params.marginLeft,
                    align: params.align,
                    paddingTop: params.paddingTop,
                    paddingLeft: params.paddingLeft,
                    paddingRight: params.paddingRight,
                    maxWidth: '',
                    backgroundColor: params.backgroundColor,
                    textAlign: params.align,
                    paddingBottom: params.paddingBottom,
                    borderTopLeftRadius: params.borderTopLeftRadius,
                    borderBottomLeftRadius: params.borderBottomLeftRadius,
                    borderTopRightRadius: params.borderTopRightRadius,
                    borderBottomRightRadius: params.borderBottomRightRadius,
                    fontFamily: params.fontFamily,
                    fontSize: params.fontSize,
                    color: params.color,

                    lineHeight: params.fontHeight,
                    fontWeight: params.fontWeight,
                    marginRight: params.marginRight,
                    marginBottom: params.marginBottom,
                }} dangerouslySetInnerHTML={{ __html: text }}>

                </td>

            </tr>
        </tbody>
    </table>; //TODO: params.add children generation
}
/*


*/
/* 
  return <Container style={{
        marginTop: params.marginTop,
        marginLeft: params.marginLeft,
        align: params.align,
        paddingTop: params.paddingTop,
        paddingLeft: params.paddingLeft,
        paddingRight: params.paddingRight,
        maxWidth: '',
        backgroundColor: params.backgroundColor,
        textAlign: params.align,
        paddingBottom: params.paddingBottom,
        borderTopLeftRadius: params.borderTopLeftRadius,
        borderBottomLeftRadius: params.borderBottomLeftRadius,
        borderTopRightRadius: params.borderTopRightRadius,
        borderBottomRightRadius: params.borderBottomRightRadius,
        fontFamily: params.fontFamily,
        fontSize: params.fontSize,
        color: params.color,
        display: 'block',
        lineHeight: params.fontHeight,
        fontWeight: params.fontWeight,
        marginRight: params.marginRight,
        marginBottom: params.marginBottom,}}><p dangerouslySetInnerHTML={{ __html: createText(params.text) }} style={{
        color: params.color,
      
        textAlign: params.align,
        align: params.align,
        fontFamily: params.fontFamily,
        fontSize: params.fontSize,
        margin: '0',
        lineHeight: params.fontHeight,
        fontWeight: params.fontWeight
    }} >
    
    </p></Container>
*/