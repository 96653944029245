export const tooltip = {
    position: 'absolute', 
    top: '21px', 
    right: '-332px', 
    width: '333px', 
    background: '#34B9A0', 
    color: '#14201E', 
    borderRadius: '4px', 
    padding: '8px', 
    zIndex: '100',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)'
}

export const tooltipText = {
    fontSize: '12px',
    lineHeight: '18px',
    color: '#14201E',
}