import { Img } from '@react-email/img';
import { Container } from '@react-email/container';
import { Link } from '@react-email/link';
import { Section } from '@react-email/section';
import { Row } from '@react-email/row';
import { Column } from '@react-email/column';

export const getImgButton = (params) => {
    return <Section style={{
        paddingTop: params.paddingTop,
        paddingLeft: params.paddingLeft,
        paddingRight: params.paddingRight,
        paddingBottom: params.paddingBottom,
        backgroundColor: params.backgroundColor,
        marginTop: params.marginTop,
        marginLeft: params.marginLeft,
        marginRight: params.marginRight,
        marginBottom: params.marginBottom
    }}>
        <Row><Column align={params.align}> <Link href={params.link}><Img width={params.width.replace('px','')} src={params.src} alt={params.alt} style={{
            height: params.height,
            borderTopLeftRadius: params.borderTopLeftRadius,
            borderBottomLeftRadius: params.borderBottomLeftRadius,
            borderTopRightRadius: params.borderTopRightRadius,
            borderBottomRightRadius: params.borderBottomRightRadius,
            width: params.width
        }} /></Link></Column></Row>
    </Section>;
}