import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Objectives from '../../components/campaignDetailsContent/Objectives/Objectives';
import Audience from '../../components/campaignDetailsContent/Audience/Audience';
import Segments from '../../components/campaignDetailsContent/Segments/Segments';
import Creative from '../../components/campaignDetailsContent/Creative/Creative';
import EditCampaignNameModal from '../../components/EditCampaignNameModal/EditCampaignNameModal';

import { setArr, setEmailId } from '../../actions/actions';
import { newEmail } from '../../components/newEmail';
import { icons } from '../../assets/icons';

import './styles.css'

class CampaignDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 1,
            openedEditCampaignNameModal: false,
            campaign: {}
        }
    }

    // toggleActiveTab = (index) => {
    //     this.setState({ activeTab: index })
    // }

    // displayContent = () => {
    //     switch (this.state.activeTab) {
    //         case 1:
    //             return <Objectives elem={this.props.elem} />
    //         case 2:
    //             return <Audience elem={this.props.elem} />
    //         case 3:
    //             return <Segments elem={this.props.elem} />
    //         case 4:
    //             return <Creative setCurrentPage={this.props.setCurrentPage} elem={this.props.elem} />
    //     }
    // }

    checkStatus = (status) => {
        switch (status) {
            case 0:
                return {
                    text: 'Active',
                    color: '#34B9A0'
                };
            case 1:
                return {
                    text: 'In progress',
                    color: '#FFC730'
                };
            case 2:
                return {
                    text: 'Finished',
                    color: '#748E8C'
                };
            case 3:
                return {
                    text: 'Stopped',
                    color: '#FD7D66'
                };
            default:
                return {
                    text: '',
                    color: ''
                };
        }
    }

    openEditCampaignNameModal = () => {
        this.setState({ openedEditCampaignNameModal: true })
    }

    closeEditCampaignNameModal = () => {
        this.setState({ openedEditCampaignNameModal: false })
    }

    getCampaign = () => {
        var json = {
          token: this.props.token
        };
        fetch('GetCampaings', {
          method: "POST",
          body: JSON.stringify(json),
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        }).then(x => x.json()).then(x => this.setState({ campaign: x.campaings.filter(x => x.id === this.props.campaignId)[0] }));
    }

    componentDidMount = () => {
        this.getCampaign();
    }

    render() {
        return (
            <div className='campaignDetails'>
                <div className='campaignDetails-container'>
                    <div>
                        <div className='campaignDetails-top'>
                            <div className='campaignDetails-top__back'>
                                <button className='campaignDetails-top__back-button' onClick={() => this.props.setCurrentPage('dashboard')}>
                                    <img className='campaignDetails-top__back-button__img' src={icons.arrow_left} />
                                    <span className='campaignDetails-top__back-button__text'>
                                        Back to All campaigns
                                    </span>
                                </button>
                            </div>
                            <div className='campaignDetails-top__info'>
                                <h2 className='campaignDetails-top__info-name'>{this.state.campaign.name}</h2>
                                <img className='campaignDetails-top__info-editName' src={icons.edit} onClick={this.openEditCampaignNameModal} />
                                <span className='campaignDetails-top__info-separator'></span>
                                <div className='campaignDetails-top__info-status'>
                                    <span>Status: </span>
                                    <span style={{ color: this.checkStatus(this.state.campaign.status).color }}>{this.checkStatus(this.state.campaign.status).text}</span>
                                </div>
                                <div className='campaignDetails-top__info-btn'>
                                    <button onClick={() => { newEmail(this.props.campaignId, this.props.token, this.props.setArr, this.props.setEmailId, '[]'); this.props.setCurrentPage('constructor'); }}>New mail</button>
                                </div>
                            </div>
                            {/* <div className='campaignDetails-controls__bottom'>
                                <button
                                    className={`${this.state.activeTab !== 1 ? 'campaignDetails-controls__bottom-button' : 'campaignDetails-controls__bottom-button bottom-button__active'}`}
                                    onClick={() => this.toggleActiveTab(1)}
                                >
                                    Objectives
                                </button>
                                <button
                                    className={`${this.state.activeTab !== 2 ? 'campaignDetails-controls__bottom-button' : 'campaignDetails-controls__bottom-button bottom-button__active'}`}
                                    onClick={() => this.toggleActiveTab(2)}
                                >
                                    Target audience
                                </button>
                                <button
                                    className={`${this.state.activeTab !== 3 ? 'campaignDetails-controls__bottom-button' : 'campaignDetails-controls__bottom-button bottom-button__active'}`}
                                    onClick={() => this.toggleActiveTab(3)}
                                >
                                    Segments
                                </button>
                                <button
                                    className={`${this.state.activeTab !== 4 ? 'campaignDetails-controls__bottom-button' : 'campaignDetails-controls__bottom-button bottom-button__active'}`}
                                    onClick={() => this.toggleActiveTab(4)}
                                >
                                    Creative
                                </button>
                            </div> */}
                        </div>
                        {/* <div>{this.displayContent()}</div> */}
                        <Creative setCurrentPage={this.props.setCurrentPage} elem={this.state.campaign} />
                        {this.state.openedEditCampaignNameModal &&
                            <EditCampaignNameModal
                                elem={this.state.campaign}
                                close={this.closeEditCampaignNameModal}
                                setCurrentPage={this.props.setCurrentPage}
                            />
                        }
                    </div>
                </div>
            </div>
        )
    }
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({ setArr: setArr, setEmailId: setEmailId }, dispatch);
}
const mapStateToProps = state => {
    return {
        arr: state.arr,
        campaignId: state.campaignId,
        token: state.token,
        emails_data: state.emails_data
    }
}
export default connect(mapStateToProps, matchDispatchToProps)(CampaignDetails);