import React from 'react';
import { icons } from '../assets/icons/index'

const Menu = ({ parent, canInteract, edit, delete: remove, copy, moveUp, moveDown }) => {
  return (
    <div className={parent ? 'menu parentMenu' : 'menu childMenu'}>
      <button onClick={edit} className='menuButton'>
        <img src={icons.edit} />
      </button>
      {canInteract &&
        <>
          <button onClick={copy} className='menuButton'>
            <img src={icons.copy} />
          </button>
          <button onClick={remove} className='menuButton'>
            <img src={icons.delete} />
          </button>
          <button onClick={moveUp} className='menuButton'>
            <img src={icons.arrow_up} />
          </button>
          <button onClick={moveDown} className='menuButton'>
            <img src={icons.arrow_down} />
          </button>
        </>
      }
    </div>
  );
};

export default Menu;
