import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setArr } from '../../../actions/actions';
import { bindActionCreators } from 'redux';
import { icons } from '../../../assets/icons';
class AI_Text extends Component {
    constructor(props) {
        super(props);
        this.state = {
            query_text: '',
            text: '',
            loading: false
        };
    }
    componentDidMount = () => {

    }

    setText = () => {
        var value = this.state.text;
        var id = this.props.for;
        var arr = Array.prototype.concat(this.props.arr);
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === id) {
                var item = arr[i];
                item.params.text = value;
                arr[i] = item;
                break;
            }
        }
        this.props.setArr(arr);
    }
    fetchGPT = () => {
        if (this.state.query_text.length > 0) {
            this.setState({loading: true});
            var json = {
                token: this.props.token,
                query: this.state.query_text
            }
            fetch('GPTText', {
                method: "POST",
                body: JSON.stringify(json),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }).then(x=> x.json()).then(x=> this.setState({text: x.text, loading: false}));
        }
    }

    render() {
        return (
            <div style={{ marginBottom: '16px' }}>
                <div>

                    <p className='attr-label'>Request to AI</p>
                    <input className='attr-input' value={this.state.query_text} onInput={(e) => this.setState({ query_text: e.target.value })} />
                    <br />
                    <button onClick={() => this.fetchGPT()}>Generate</button>
                </div>
                <div style={{display: this.state.loading ? '' : 'none'}}>
                    <img style={{width: '80px'}} src={icons.loading}/>
                </div>
                <div style={{ display: this.state.text.length > 0 ? '' : 'none' }}>
                    <p>Generated Text</p>
                    <p>{this.state.text}</p>
                    <button onClick={() => this.setText()}>Apply</button>
                </div>
            </div>
        );
    }
}
function matchDispatchToProps(dispatch) {
    return bindActionCreators({ setArr: setArr }, dispatch);
}
const mapStateToProps = state => {
    return {
        arr: state.arr,
        token: state.token
    }
}
export default connect(mapStateToProps, matchDispatchToProps)(AI_Text);