import { getImgBlock } from "./imageBlock";
import { getTextBlock } from "./textBlock";
import { Column } from '@react-email/column';
import { Section } from '@react-email/section';

export const getListBlock = (img, text, params, main) => {
    var params_i = img.params;
    var params_t = text.params;
    var rtl = false;
    if (params.dir !== undefined && params.dir !== null) {
        rtl = params.dir;
    }
    var width = '601px';
    if (main !== undefined && main !== null){
        var _params = main;
        var pR = parseInt(_params.paddingRight);
        var pL = parseInt(_params.paddingLeft);
    
        var _width = 601 - pR - pL;
        width = _width.toString() + 'px';

    }
    return <table width="601px" cellspacing="0" align={params.align} cellpadding="0" style={{
        maxWidth: "601px", width: width,
        borderTopLeftRadius: params.borderTopLeftRadius,
        borderBottomLeftRadius: params.borderBottomLeftRadius,
        borderTopRightRadius: params.borderTopRightRadius,
        borderBottomRightRadius: params.borderBottomRightRadius,


        marginTop: params.marginTop, marginLeft: params.marginLeft, marginRight: params.marginRight, marginBottom: params.marginBottom, backgroundColor: params.backgroundColor
    }}>
        <tr >
            {rtl ? <>
                <td align={params_t.align} style={{
                    width: '100%',
                    borderTopRightRadius: params.borderTopRightRadius,
                    borderBottomRightRadius: params.borderBottomRightRadius,
                    backgroundColor: params_t.backgroundColor, paddingTop: params.paddingTop, paddingRight: params.paddingRight,
                    paddingBottom: params.paddingBottom,
                }}>{getTextBlock(params_t)}</td>
                <td align={params_i.align} style={{
                    borderTopLeftRadius: params.borderTopLeftRadius,
                    borderBottomLeftRadius: params.borderBottomLeftRadius,
                    verticalAlign: 'top', paddingTop: params.paddingTop, paddingLeft: params.paddingLeft,
                    paddingBottom: params.paddingBottom,
                    width: params_i.width,
                    backgroundColor: params_i.backgroundColor
                }}>{getImgBlock(params_i)}</td>
            </> : <> <td align={params_i.align} style={{
                borderTopLeftRadius: params.borderTopLeftRadius,
                borderBottomLeftRadius: params.borderBottomLeftRadius,
                verticalAlign: 'top', paddingTop: params.paddingTop, paddingLeft: params.paddingLeft,
                paddingBottom: params.paddingBottom,
                width: params_i.width,
                backgroundColor: params_i.backgroundColor
            }}>{getImgBlock(params_i)}</td>
                <td align={params_t.align} style={{
                    width: '100%',
                    borderTopRightRadius: params.borderTopRightRadius,
                    borderBottomRightRadius: params.borderBottomRightRadius,
                    backgroundColor: params_t.backgroundColor, paddingTop: params.paddingTop, paddingRight: params.paddingRight,
                    paddingBottom: params.paddingBottom,
                }}>{getTextBlock(params_t)}</td></>}

        </tr>

    </table>;
}