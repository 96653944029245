import React, {Component} from 'react';

class Contact extends Component {
  render() {
    return (
      <div>
        Contact page
      </div>
    );
  }
}

export default Contact;