import React, { Component } from 'react';
import { connect } from 'react-redux';
import { putElm, setArr } from '../../../actions/actions';
import { bindActionCreators } from 'redux';
class DeleteBox extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    deleteBlock = () => {
        var id = this.props.for;
        var arr = Array.prototype.concat(this.props.arr);
        var _arr = []
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id !== id) {
                var item = arr[i];
                _arr.push(item);

            }

        }
        this.props.putElm(-99);
        this.props.setArr(_arr);
    }
    close = () => {
        this.props.putElm(-99);
    }
    render() {
        return (
            <div style={{ display: '', marginBottom: '16px' }}>
                <div onClick={() => this.close()} style={{ display: 'flex', justifyContent: 'right', marginBottom: '16px' }}>

                    <img style={{ cursor: 'pointer', width: '22px', height: '22px' }} src=" data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACmSURBVHgB7VSJCYAwDEydpCu4gY7iZI5SN+gK3aQm0kLBNA1CEbQHEshzF84SgIHPw3BJ5701Ju4xmm2d5yARtHonVhUHMCwYHRGATO5S7871sAK0DYaAn62JFORUC2nmviy0t8sEa7ZAqqkFakTXkJK8KcCJpLSKXCXAiICWnDBBZ7xrUdef3PWZagi0ItKpEAcplyyjmn1yKo6Wv4XIUTsVAz/ACRI/tRSYO0myAAAAAElFTkSuQmCC" />
                   
                </div>
                <div style={{ display: 'flex', width: '100%' }}>
                    <p onClick={() => this.deleteBlock()} Style="width: 100%;background: #34B9A0;border-radius: 4px;padding: 8px 16px;cursor: pointer;">Видалити блок</p>
                </div>
            </div>
        );
    }
}
function matchDispatchToProps(dispatch) {
    return bindActionCreators({ setArr: setArr, putElm: putElm }, dispatch);
}
const mapStateToProps = state => {
    return {
        arr: state.arr
    }
}
export default connect(mapStateToProps, matchDispatchToProps)(DeleteBox);