import React, { Component } from 'react'
import Slide from 'react-reveal/Slide';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getHtmlRaw } from '../getHtmlRaw';

import { icons } from '../../assets/icons'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './styles.css';

class PreviewModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            preview: [],
            loadingSubject: false,
            subject: 'No Subject',
            receiver: ''
        }
    }

    componentDidMount = () => {
        this.setState({ preview: this.props.arr })
    }

    closeModal = (e) => {
        if (e.target.classList.contains('previewModal-overlay')) {
            this.props.close();
        }
    }
    _toast = (text, status) => {
        toast(text, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            className: status === 200 ? 'toast-success' : 'toast-error'
        });
    }
    sendMail = () => {
        if (this.state.receiver.length === 0 || this.state.subject.length === 0) {
            return;
        }
        var json = {
            token: this.props.token,
            body: getHtmlRaw(this.state.preview),
            subject: this.state.subject,
            to: this.state.receiver,
            preHeader: this.state.subject
        };
        fetch('https://adbotest.digitum.nl/SendTestMail', {
            method: "POST",
            body: JSON.stringify(json),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then(x => this._toast(x.status === 200 ? "Sended" : "Server Error", x.status));
    }
    aiSubject = () => {
        this.setState({ loadingSubject: true });
        var json = {
            token: this.props.token,
            words: 20,
            prompt: document.getElementsByTagName('HTML')[0].innerText,
            tone: 'buisness'
        };
        fetch('CreateGPTSubject', {
            method: "POST",
            body: JSON.stringify(json),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then(x => x.json()).then(x => this.setState({ subject: x.text, loadingSubject: false }));
    }
    render() {
        return (
            <div onClick={this.closeModal} className='previewModal-overlay'>
                <Slide top>
                    <div className='previewModal'>
                        <div className='previewModal-top'>
                            <span className='previewModal-top__title'>Preview</span>
                            <button className='previewModal-top__btn' onClick={this.props.close}>
                                <img src={icons.close_small} />
                            </button>
                        </div>
                        <div className='previewModal-actions'>
                            <div className='previewModal-actions__top'>
                                <input onInput={(e) => this.setState({ receiver: e.target.value })} className='previewModal-actions__top-input' type='text' placeholder='Recipient' />
                                <button 
                                    onClick={() => this.sendMail()} 
                                    className='previewModal-actions__top-btn'
                                    disabled={!this.state.receiver || !this.state.subject}
                                >
                                    Send
                                </button>
                            </div>
                            <div className='previewModal-actions__bottom'>
                                <button style={{
                                    width: '105px',
                                    justifyContent: this.state.loadingSubject ? 'center' : '',
                                    height: '31px'
                                }} onClick={() => this.aiSubject()} className='previewModal-actions__bottom-btnGenerate'>
                                    <img src={this.state.loadingSubject ? icons.m_loading : icons.sparkles} />
                                    <span style={{ display: this.state.loadingSubject ? 'none' : '' }}>Generate</span>
                                </button>
                                <div className='previewModal-actions__bottom-arrows'>
                                    <button className='previewModal-actions__bottom-arrows-left'>
                                        <img src={icons.arrow_down} />
                                    </button>
                                    <button className='previewModal-actions__bottom-arrows-right'>
                                        <img src={icons.arrow_down} />
                                    </button>
                                </div>
                                <input value={this.state.subject} onInput={(e) => this.setState({ subject: e.target.value })} className='previewModal-actions__bottom-input' type='text' placeholder='Introducing Our New Product – The Super Rewards Credit Card' />
                                <button style={{ display: 'none' }} className='previewModal-actions__bottom-btnPreText'>Pre-Text</button>
                            </div>
                        </div>
                        <iframe
                            className='previewModal-iframe'
                            width={617}
                            srcDoc={getHtmlRaw(this.state.preview)}
                        />
                    </div>
                </Slide>
            </div>
        )
    }
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}
const mapStateToProps = state => {
    return {
        arr: state.arr,
        token: state.token
    }
}

export default connect(mapStateToProps, matchDispatchToProps)(PreviewModal);