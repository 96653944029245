export const header = {
    marginTop: '15px',
    height: '40px',
    padding: '12px 0',
    display: 'flex',
    alignItems: 'center',
    fontFamily: `Montserrat, sans-serif`,
  }
  
  export const container = {
    maxWidth: '1024px',
    width: '100%',
    margin: '0 auto',
  }
  
  export const content = {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  }
  
  export const list = {
    display: 'flex',
    listStyleType: 'none'
  }
  
  export const listItem = {
    marginLeft: '31px',
  }
  
  export const listItemLink = {
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '15px',
    color: '#34B9A0'
  }