import React, { Component } from 'react';
import ReactQuill, { Quill } from 'react-quill';

import './styles.css'
import { icons } from '../../assets/icons';

let Size = Quill.import("formats/size");
Size.whitelist = [
  "9px",
  "10px",
  "11px",
  "12px",
  "14px",
  "16px",
  "18px",
  "20px",
  "22px",
  "24px",
  "26px",
  "28px",
];
Quill.register(Size, true);

let Font = Quill.import("formats/font");
Font.whitelist = [
  "arial",
  "courier-new",
  "georgia",
  "helvetica",
  "lucida-sans",
  "tahoma",
  "times-new-roman",
  "trebuchet-ms"
];
Quill.register(Font, true);

let Color = Quill.import("formats/color")
Color.register = [
  "#FFFFFF",
  "#C0C0C0",
  "#808080",
  "#000000",
  "#FF0000",
  "#800000",
  "#FFFF00",
  "#808000",
  "#00FF00",
  "#008000",
  "#00FFFF",
  "#008080",
  "#0000FF",
  "#000080",
  "#FF00FF",
  "#800080",
  'custom-color'
];
Quill.register(Color, true);

let size = '9px'



function hexToRgbA(hex) {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',1)';
  }
}

export const modules = {
  toolbar: {
    container: "#toolbar",
    handlers: {
      'size': function (value) {
        this.quill.format('size', value);
        return;
      },
      'font': function (value) {
        this.quill.format('font', value);
        return;
      },
      'textStyle': function (value) {
        let selection = this.quill.getFormat();
        if (value === 'normal') {
          this.quill.format('bold', false);
          this.quill.format('italic', false);
          this.quill.format('underline', false);
          return;
        }
        if (value === 'bold') {
          if (selection.bold === true) {
            this.quill.format('bold', false);
            return;
          }
          this.quill.format('bold', true);
          return;
        }
        if (value === 'italic') {
          if (selection.italic === true) {
            this.quill.format('italic', false);
            return;
          }
          this.quill.format('italic', true);
          return;
        }
        if (value === 'underline') {
          if (selection.underline === true) {
            this.quill.format('underline', false);
            return;
          }
          this.quill.format('underline', true);
          return;
        }
      },
      'color': function (value) {
        if (value == 'custom-color') value = window.prompt('Enter Hex Color Code');
        if (value[0] === '#') {
          let rgba = hexToRgbA(value);
          this.quill.format('color', rgba);
          return;
        } else {
          this.quill.format('color', value);
          return;
        }
      },
      'decreaseSize': function () {
        if (this.quill !== undefined) {
          var arr = Array.prototype.slice.call(document.getElementsByClassName("ql-picker-label"));
          for (var i = 0; i < arr.length; i++) {
            var attr = parseInt(arr[i].getAttribute('data-label'));
            if (!isNaN(attr) && attr > 0) {
              for (var j = 0; j < Size.whitelist.length; j++) {
                var item = Size.whitelist[j];
                if (item == (attr + 'px') && j > 0) {
                  size = item;
                  this.quill.format('size', Size.whitelist[j - 1]);
                  return;
                }
              }
              return;
            }
          }
        }
      },
      'increaseSize': function () {
        if (this.quill !== undefined) {
          var arr = Array.prototype.slice.call(document.getElementsByClassName("ql-picker-label"));
          for (var i = 0; i < arr.length; i++) {
            var attr = parseInt(arr[i].getAttribute('data-label'));
            if (!isNaN(attr) && attr > 0) {
              for (var j = 0; j < Size.whitelist.length; j++) {
                var item = Size.whitelist[j];
                if (item == (attr + 'px') && j < (Size.whitelist.length - 1)) {
                  size = item;
                  this.quill.format('size', Size.whitelist[j + 1]);
                  return;
                }
              }
              return;
            }
          }
        }
      },
    }
  }
}

export const formats = [
  "size",
  "font",
  "bold",
  "italic",
  "align",
  "underline",
  "color",
  "link"
];

export default class EditorToolbar extends Component {
  constructor() {
    super();
    this.state = {

    }
  }
  render() {
    return (
      <div id='toolbar' className='editor-toolbar'>
        <span className='ql-formats'>
          <select className="ql-font">
            <option value="arial">Arial</option>
            <option value="courier-new">Courier New</option>
            <option value="georgia">Georgia</option>
            <option value="helvetica">Helvetica</option>
            <option value="lucida-sans">Lucida Sans</option>
            <option value="tahoma">Tahoma</option>
            <option value="times-new-roman">Times New Roman</option>
            <option value="trebuchet-ms">Trebuchet MS</option>
          </select>
          <select className="ql-textStyle">
            <option value="normal">Normal</option>
            <option value="bold">Bold</option>
            <option value="italic">Italic</option>
            <option value="underline">Underline</option>
          </select>
        </span>
        <span className='ql-formats'>
          <button className='ql-decreaseSize'></button>
          <select className="ql-size" defaultValue='14px'>
            <option value="9px">9</option>
            <option value="10px">10</option>
            <option value="11px">11</option>
            <option value="12px">12</option>
            <option value="14px">14</option>
            <option value="16px">16</option>
            <option value="18px">18</option>
            <option value="20px">20</option>
            <option value="22px">22</option>
            <option value="24px">24</option>
            <option value="26px">26</option>
            <option value="28px">28</option>
          </select>
          <button className='ql-increaseSize'></button>
        </span>
        <span className='ql-formats'>
          <select className="ql-align" />
        </span>
        <span className="ql-formats">
          <button className='ql-link'></button>
        </span>
        <span className="ql-formats">
          <select className="ql-color">
            <option value='#FFFFFF'></option>
            <option value='#C0C0C0'></option>
            <option value='#808080'></option>
            <option value='#000000'></option>
            <option value='#FF0000'></option>
            <option value='#800000'></option>
            <option value='#FFFF00'></option>
            <option value='#808000'></option>
            <option value='#00FF00'></option>
            <option value='#008000'></option>
            <option value='#00FFFF'></option>
            <option value='#008080'></option>
            <option value='#0000FF'></option>
            <option value='#000080'></option>
            <option value='#FF00FF'></option>
            <option value='#800080'></option>
            <option value='custom-color'></option>
          </select>
        </span>
      </div>
    )
  }
}