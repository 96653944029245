export const setArr = (value) => {
    return { type: "PUT", payload: value }
}
export const putElm = (value) => {
    return { type: "PUT_ELM", payload: value }
}
export const setToken = (token) => {
    return { type: "SET_TOKEN", payload: token }
}
export const setCampaignName = (value) => {
    return { type: "SET_CAMPAIGN_NAME", payload: value }
}
export const setSelectedChannels = (value) => {
    return { type: "SET_SELECTED_CHANNELS", payload: value }
}
export const setEmailsData = (value) => {
    return { type: "SET_EMAILS_DATA", payload: value };
}
export const setEmailId = (value) => {
    return { type: "SET_EMAIL_ID", payload: value }
}
export const setCampaignId = (value) => {
    return { type: "SET_CAMPAIGN_ID", payload: value }
}
export const setAiImages = (value) => {
    return { type: "PUT_AI_IMAGES", payload: value }
}
export const setAIImgLoading = (value) => {
    return { type: "PUT_AI_IMG_LOADING", payload: value }
}