function uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}

export const addTextManager = (params) => {
    if (params === undefined) {
        let id = uuidv4();
        let text = 'Paragraph: Laborum laboris mollit commodo do consectetur consectetur sunt ea velit reprehenderit ad irure aliqua amet. Excepteur esse Lorem do labore laborum reprehenderit laborum mollit dolor mollit mollit quis in. Anim nulla Lorem nostrud duis voluptate ipsum sint pariatur. Esse ex fugiat pariatur officia consequat est aliqua labore sit cillum Lorem deserunt qui mollit. Quis eu Lorem cillum dolor do do. Ea sint aliquip irure exercitation ut ut et irure aute ad.';
        let _obj = {
            id: id,
            type: 'text',
            childrens: [],
            params: {
                backgroundColor: '#fff',
                paddingTop: '12px',
                paddingLeft: '32px',
                paddingRight: '32px',
                text: text,
                paddingBottom: '12px',
                marginTop: 0,
                marginRight: 0,
                align: 'center',
                color: '#000',
                for: -99,
                marginLeft: 0,
                marginBottom: 0,
                fontFamily: 'Arial',
                fontSize: '14px',
                fontWeight: 400,
                fontHeight: '133%'
            }
        };
        return _obj;
    } else {
        let id = uuidv4();
        let _obj = {
            id: id,
            type: 'text',
            childrens: [],
            params: params
        };
        return _obj;
    }
}