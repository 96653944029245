import { Row } from "@react-email/row";
import { getImgBlock } from "./imageBlock";
import { getTextBlock } from "./textBlock";
import { Column } from '@react-email/column';
import { Section } from '@react-email/section';
import { getTextImage } from "./textImage";

export const getThreeTextImage = (data_array, params, main, arr) => {
    var params_1 = data_array[0];
    var params_2 = data_array[1];
    var params_3 = data_array[2];
    return <Row style={{
        paddingTop: params.paddingTop, paddingLeft: params.paddingLeft, paddingRight: params.paddingRight, paddingBottom: params.paddingBottom,
        marginTop: params.marginTop, marginLeft: params.marginLeft, marginRight: params.marginRight, marginBottom: params.marginBottom, backgroundColor: params.backgroundColor
    }}>
        <Column style={{}}>{getTextImage(params_1.img, params_1.text, params_1.params, main, arr)}</Column>
        <Column style={{}}>{getTextImage(params_2.img, params_2.text, params_2.params, main, arr)}</Column>
        <Column style={{}}>{getTextImage(params_3.img, params_3.text, params_3.params, main, arr)}</Column>

    </Row>;
}