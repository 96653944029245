import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setArr } from '../../../actions/actions';
import { bindActionCreators } from 'redux';
import { icons } from '../../../assets/icons';
import AttributeTooltip from '../../../components/AttributeTooltip/AttributeTooltip';
import { determinatePercentage } from '../../../components/determinatePercentage';
class ImageBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            src: '',
            alt: '',
            width: '100%',
            height: 'auto',
            align: 'center',
            fileName: '',
            activeLink: false
        };
    }
    componentDidMount = () => {
        this.getAll();
    }

    toggleApplyLink = () => {
        this.setState({ activeLink: !this.state.activeLink })
    }

    getAll = () => {

        var id = this.props.for;
        var arr = Array.prototype.concat(this.props.arr);
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === id) {
                var item = arr[i];
                this.setState({ src: item.params.src, alt: item.params.alt, width: item.params.width, height: item.params.height, align: item.params.align });
                return;
            }
        }
    }
    setSrc = (value) => {
        this.setState({ src: value });
        var id = this.props.for;
        var arr = Array.prototype.concat(this.props.arr);
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === id) {
                var item = arr[i];
                item.params.src = value;
                arr[i] = item;
                break;
            }
        }
        this.props.setArr(arr);
    }
    setAlt = (value) => {
        this.setState({ alt: value });
        var id = this.props.for;
        var arr = Array.prototype.concat(this.props.arr);
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === id) {
                var item = arr[i];
                item.params.alt = value;
                arr[i] = item;
                break;
            }
        }
        this.props.setArr(arr);
    }
    setWidth = (value) => {
        let isHave = determinatePercentage(value);
        if (isHave) {
            return;
        } else {
            if (!this.state.activeLink) {
                this.setState({ width: value });
            } else {
                this.setState({
                    width: value,
                    height: value,
                });
            }
            var id = this.props.for;
            var arr = Array.prototype.concat(this.props.arr);
            for (var i = 0; i < arr.length; i++) {
                if (arr[i].id === id) {
                    var item = arr[i];
                    if (!this.state.activeLink) {
                        item.params.width = value;
                    } else {
                        item.params.width = value;
                        item.params.height = value;
                    }
                    arr[i] = item;
                    break;
                }
            }
            this.props.setArr(arr);
        }
    }
    setHeight = (value) => {
        let isHave = determinatePercentage(value);
        if (isHave) {
            return;
        } else {
            if (!this.state.activeLink) {
                this.setState({ height: value });
            } else {
                this.setState({
                    width: value,
                    height: value,
                });
            }
            var id = this.props.for;
            var arr = Array.prototype.concat(this.props.arr);
            for (var i = 0; i < arr.length; i++) {
                if (arr[i].id === id) {
                    var item = arr[i];
                    if (!this.state.activeLink) {
                        item.params.height = value;
                    } else {
                        item.params.width = value;
                        item.params.height = value;
                    }
                    arr[i] = item;
                    break;
                }
            }
            this.props.setArr(arr);
        }
    }
    setAlign = (value) => {
        this.setState({ align: value });
        var id = this.props.for;
        var arr = Array.prototype.concat(this.props.arr);
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === id) {
                var item = arr[i];
                item.params.align = value;
                arr[i] = item;
                break;
            }
        }
        this.props.setArr(arr);
    }
    setImage = (files) => {
        var f = files[0];
        var token = this.props.token;
        var reader = new FileReader();
        var _this = this;
        var fileFormat = f.name.substring(f.name.indexOf('.'));
        reader.onloadstart = function () {
        };
        reader.onload = function () {

            var arrayBuffer = reader.result;
            var array = new Uint8Array(arrayBuffer);

            var data = "";

            array.forEach(function (e) {
                data += e + ';'
            });

            var json = {
                fileFormat: fileFormat,
                data: data,
                c_id: token
            };
            // console.log(json);
            // return;
            fetch('UploadImage', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(json)
            }).then(x => x.json()).then(x => {
                _this.setSrc(x.path);
                _this.setState({fileName: f.name})
            });

        };
        if (f) {

            reader.readAsArrayBuffer(files[0]);

        }
        else {

        }
    }
    render() {
        return (
            <div>
                <div className='attr-block'>
                    <p className='attr-label'>
                        ImageSrc
                        <AttributeTooltip/>
                    </p>
                    <input className='attr-input' value={this.state.src} onInput={(e) => this.setSrc(e.target.value)} />
                </div>
                <div className='attr-block'>
                    <p className='attr-label'>
                        Image Alternative Describe
                        <AttributeTooltip/>
                    </p>
                    <input className='attr-input' value={this.state.alt} onInput={(e) => this.setAlt(e.target.value)} />
                </div>
                <div className='attr-block'>
                    <button onClick={() => this.props.setCurrentPage('ai_img')} className='attr-generate-img'>
                        <img src={icons.sparkles}/>
                        <span>Generate image</span>
                    </button>
                </div>
                <div className='attr-block'>
                    <p className='attr-label'>
                        Image
                        <AttributeTooltip/>
                    </p>
                    <label for='file' className='attr-file-label'>{this.state.fileName ? this.state.fileName : 'Browse file'}</label>
                    <input type='file' name='file' id='file' className='attr-file-input' accept="image/png, image/jpeg, image/jpg, image/gif" onInput={(e) => this.setImage(e.target.files)} />
                </div>
                <div className='attr-block'>
                    <p className='attr-label'>
                        Image SIze
                        <AttributeTooltip/>
                    </p>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{marginRight: '30px'}}>
                            <span className='attr-input-text'>W</span>
                            <input className='attr-input' style={{width: '64px'}} value={this.state.width} onInput={(e) => this.setWidth(e.target.value)} />
                        </div>
                        <div style={{marginRight: '30px'}}>
                            <span className='attr-input-text'>H</span>
                            <input className='attr-input' style={{width: '64px'}} value={this.state.height} onInput={(e) => this.setHeight(e.target.value)} />
                        </div>
                        <button className={this.state.activeLink ? 'attr-link attr-link__active' : 'attr-link'} onClick={this.toggleApplyLink}>
                            {this.state.activeLink ? (
                                <img className='attr-link__img' src={icons.link_active} />
                            ) : (
                                <img className='attr-link__img' src={icons.link} />
                            )}
                        </button>
                    </div>
                </div>
                <div className='attr-block'>
                    <p className='attr-label'>
                        Align
                        <AttributeTooltip/>
                    </p>
                    <button className={this.state.align === 'left' ? 'custom-align custom-align__checked' : 'custom-align'} onClick={() => { this.setAlign("left") }}>
                        <img src={icons.align_left} />
                    </button>
                    <button className={this.state.align === 'center' ? 'custom-align custom-align__checked' : 'custom-align'} onClick={() => { this.setAlign("center") }}>
                        <img src={icons.align_center} />
                    </button>
                    <button className={this.state.align === 'right' ? 'custom-align custom-align__checked' : 'custom-align'} onClick={() => { this.setAlign("right") }}>
                        <img src={icons.align_right} />
                    </button>
                </div>
            </div>
        );
    }
}
function matchDispatchToProps(dispatch) {
    return bindActionCreators({ setArr: setArr }, dispatch);
}
const mapStateToProps = state => {
    return {
        arr: state.arr,
        token: state.token
    }
}
export default connect(mapStateToProps, matchDispatchToProps)(ImageBox);