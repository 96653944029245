import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setArr, setEmailId, putElm, setAiImages } from '../../actions/actions';
import { Html } from '@react-email/html';
import { Body } from '@react-email/body';
import { render } from '@react-email/render';
import ImageBlock from './viewBlocks/imageBlock';
import ImageBox from './attrBlocks/image';
import PaddingBox from './attrBlocks/padding';
import generateBlock from './viewBlocks/generateBlock';
import BackgroundBox from './attrBlocks/background';
import FontBox from './attrBlocks/font';
import AlignBox from './attrBlocks/align';
import DeleteBox from './attrBlocks/delete';
import TextBlock from './viewBlocks/textBlock';
import DirBox from './attrBlocks/dir.js';
import MarginBox from './attrBlocks/margin';
import LinkBox from './attrBlocks/link';
import { getTextBlock } from './renderBlocks/textBlock';
import { getImgBlock } from './renderBlocks/imageBlock';
import ImageButton from './viewBlocks/imageButton';
import { getImgButton } from './renderBlocks/imageButton';
import ListBlock from './viewBlocks/listBlock';
import { getListBlock } from './renderBlocks/listBlock';
import LogoBlock from './viewBlocks/logoBlock';
import { getLogoBlock } from './renderBlocks/logoBlock';
import SocialBlock from './viewBlocks/socialBlock';
import { getSocialBlock } from './renderBlocks/socialBlock';
import { getTextLinkBlock } from './renderBlocks/textLinkBlock';
import TextLinkBlock from './viewBlocks/textLinkBlock';
import Border from './attrBlocks/border';
import PreviewModal from '../../components/PreviewModal/PreviewModal';
import Ai_text from './attrBlocks/ai_text';
import { icons } from '../../assets/icons';
import { Fade } from 'react-reveal';
import { getHtmlRaw } from '../../components/getHtmlRaw';
import './styles.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AboutModal from '../../components/AboutModal/AboutModal';
import Background from './attrBlocks/background';
import { newEmail } from '../../components/newEmail';
import GenerateLettetModal from '../../components/GenerateLettetModal/GenerateLettetModal';
import { addTextManager } from '../../components/addText';
import DeleteCampaignModal from '../../components/DeleteCampaignModal/DeleteCampaignModal';
import test_data from './test_data.json';
class Constructor extends Component {
    constructor(props) {
        super(props);
        this.types = [
            { obj: { type: "main-block", attr: [1, 4] } },
            { obj: { type: "text", attr: [1, 2, 4, 3, 7] } },
            { obj: { type: "image", attr: [1, 4, 3, 5, 7] } },
            { obj: { type: "link-img", attr: [1, 4, 3, 5, 6, 7] } },
            { obj: { type: "list-block", attr: [1, 4, 11, 3, 8, 7] } },
            { obj: { type: "text-image", attr: [1, 4, 11, 3, 8, 7] } },
            { obj: { type: "logo-block", attr: [1, 4] } },
            { obj: { type: "three-i-row", attr: [1, 4] } },
            { obj: { type: "text-two-block", attr: [1, 4] } },
            { obj: { type: "two-text-image", attr: [1, 4] } },
            { obj: { type: "three-text-image", attr: [1, 4] } },
            { obj: { type: "text-three-block", attr: [1, 4] } },
            { obj: { type: "social-block", attr: [1, 4, 7, 8] } },
            { obj: { type: "text-link", attr: [1, 9, 4, 10, 7, 2, 6,] } },

        ];
        this.state = {
            attrs: [],
            activeTab: 1,
            leftMenuControlsHeight: 0,
            openedPreviewModal: false,
            selectedBlock: -99,
            open_menu: false,
            openedAboutModal: false,
            openedGenerateLetterModal: false,
            openedDeleteModal: false
        };
        this.blockTypesBoxRef = React.createRef();
        this.sheetBoxRef = React.createRef();
        this.attrBoxRef = React.createRef();
        this.menuRef = React.createRef();
    }

    toggleActiveTab = (index) => {
        this.setState({ activeTab: index })
    }

    openPreviewModal = () => {
        this.setState({ openedPreviewModal: true })
    }

    closePreviewModal = () => {
        this.setState({ openedPreviewModal: false })
    }

    openAboutModal = () => {
        this.setState({ openedAboutModal: true })
    }

    closeAboutModal = () => {
        this.setState({ openedAboutModal: false })
    }

    openGenerateLetterModal = () => {
        this.setState({ openedGenerateLetterModal: true })
    }

    closeGenerateLetterModal = () => {
        this.setState({ openedGenerateLetterModal: false })
    }

    openDeleteModal = () => {
        this.setState({ openedDeleteModal: true })
    }

    closeDeleteModal = () => {
        this.setState({ openedDeleteModal: false })
        //TODO: refresh page
    }

    uuidv4() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }

    addInOrder = (obj, id) => {
        this.props.arr.push(obj)
        var arr = Array.prototype.concat(this.props.arr);
        this.props.setArr(arr);
        if (id) return id;
    }

    addBlockManager = (obj, id) => {
        if (this.props.selectedElm != -99 && this.props.selectedElm != undefined) {
            if (this.props.arr.length > 0 && this.props.arr.filter(x => x.params.for === -99).length > 0 &&
                this.props.arr.find(elem => elem.id === this.props.selectedElm) !== undefined
                && this.props.arr.find(elem => elem.id === this.props.selectedElm).params.for === -99) {
                let index = null;
                for (let i = 0; i < this.props.arr.length; i++) {
                    if (this.props.arr[i].id === this.props.selectedElm) {
                        index = i;
                    }
                }
                this.props.arr.splice(index + 1, 0, obj)
                var arr = Array.prototype.concat(this.props.arr);
                this.props.setArr(arr);
                if (id) return id;
            } else {
                if (id) {
                    let res = this.addInOrder(obj, id)
                    return res
                } else {
                    this.addInOrder(obj)
                }
            }
        } else {
            if (id) {
                let res = this.addInOrder(obj, id)
                return res
            } else {
                this.addInOrder(obj)
            }
        }
    }

    addListBlock = (rtl) => {
        var id = this.uuidv4();
        this.addText(id, undefined, "20px", rtl ? 'right' : 'center');
        this.addImage(id, "https://" + 'adbotest.digitum.nl' + "/static/media/default_dot.png", "35px", '32px');//window.location.hostname
        var _obj = {};
        if (rtl) {
            _obj = {
                id: id,
                childrens: [],
                type: "list-block",
                params: {
                    paddingTop: '12px',
                    paddingLeft: '0px',
                    paddingRight: '32px',
                    paddingBottom: '12px',
                    dir: 'rtl',
                    borderTopLeftRadius: '0px',
                    borderBottomLeftRadius: '0px',
                    borderTopRightRadius: '0px',
                    borderBottomRightRadius: '0px',
                    backgroundColor: '#fff',
                    marginTop: '0px',
                    for: -99,
                    marginRight: '0px',
                    marginLeft: '0px',
                    marginBottom: '0px',
                }
            }
        } else {
            _obj = {
                id: id,
                childrens: [],
                type: "list-block",
                params: {
                    paddingTop: '12px',
                    paddingLeft: '32px',
                    paddingRight: '32px',
                    paddingBottom: '12px',
                    dir: '',
                    borderTopLeftRadius: '0px',
                    borderBottomLeftRadius: '0px',
                    borderTopRightRadius: '0px',
                    borderBottomRightRadius: '0px',
                    backgroundColor: '#fff',
                    marginTop: '0px',
                    for: -99,
                    marginRight: '0px',
                    marginLeft: '0px',
                    marginBottom: '0px',
                }
            }
        }

        this.addBlockManager(_obj)
    }
    addTextImage = (rtl, _width, _for) => {
        var id = this.uuidv4();
        var width = '602px';
        if (_width !== undefined && _width != null){
            width = _width;
        }
        this.addText(id, undefined, "20px", rtl ? 'right' : 'center');
        this.addImage(id, "https://" + 'adbotest.digitum.nl' + "/static/media/default_dot.png", "35px", '0');//window.location.hostname
        var _obj = {};

        _obj = {
            id: id,
            childrens: [],
            type: "text-image",
            params: {
                paddingTop: '12px',
                paddingLeft: '32px',
                paddingRight: '32px',
                paddingBottom: '12px',
                dir: '',
                borderTopLeftRadius: '0px',
                borderBottomLeftRadius: '0px',
                borderTopRightRadius: '0px',
                borderBottomRightRadius: '0px',
                align: 'center',
                backgroundColor: '#fff',
                marginTop: '0px',
                for: _for === undefined ? -99 : _for,
                width: width,
                marginRight: '0px',
                marginLeft: '0px',
                marginBottom: '0px',
            }
        }


        let resId = this.addBlockManager(_obj, _obj.id);
        return resId;
    }
    addTwoTextImage = () => {
        var id = this.uuidv4();
        var block1 = this.addTextImage(false, '301px', id);
        var block2 = this.addTextImage(false, '301px', id);

        var _obj = {
            id: id,
            childrens: [block1, block2],
            type: "two-text-image",
            params: {
                paddingTop: '12px',
                paddingLeft: '32px',
                paddingRight: '32px',
                paddingBottom: '12px',
                borderTopLeftRadius: '0px',
                borderBottomLeftRadius: '0px',
                borderTopRightRadius: '0px',
                borderBottomRightRadius: '0px',
                backgroundColor: '#fff',
                marginTop: '0px',
                for: -99,
                marginRight: '0px',
                marginLeft: '0px',
                marginBottom: '0px',
            }
        }
        this.addBlockManager(_obj)
    }
    addThreeTextImage = () => {
        var id = this.uuidv4();
        var block1 = this.addTextImage(false, '200px', id);
        var block2 = this.addTextImage(false,'200px', id);
        var block3 = this.addTextImage(false,'200px', id);
        var _obj = {
            id: id,
            childrens: [block1, block2, block3],
            type: "three-text-image",
            params: {
                paddingTop: '12px',
                paddingLeft: '32px',
                paddingRight: '32px',
                paddingBottom: '12px',
                borderTopLeftRadius: '0px',
                borderBottomLeftRadius: '0px',
                borderTopRightRadius: '0px',
                borderBottomRightRadius: '0px',
                backgroundColor: '#fff',
                marginTop: '0px',
                for: -99,
                marginRight: '0px',
                marginLeft: '0px',
                marginBottom: '0px',
            }
        }
        this.addBlockManager(_obj)

    }
    addSocialBlock = () => {
        var id = this.uuidv4();
        var img1 = this.addImageLink(id, "https://" + window.location.hostname + "/static/media/fb_default.png", '30px', '20px');
        var img2 = this.addImageLink(id, "https://" + window.location.hostname + "/static/media/tw_default.png", '30px', '20px');
        var img3 = this.addImageLink(id, "https://" + window.location.hostname + "/static/media/insta_default.png", '30px', '20px');
        var img4 = this.addImageLink(id, "https://" + window.location.hostname + "/static/media/yt_default.png", '30px', '20px');

        var _obj = {
            id: id,
            type: 'social-block',
            childrens: [img1, img2, img3, img4],
            params: {
                paddingTop: '12px',
                paddingLeft: '0px',
                paddingRight: '0px',
                align: 'center',
                backgroundColor: '#fff',
                paddingBottom: '12px',
                marginTop: '0px',
                for: -99,
                marginRight: '0px',
                marginLeft: '0px',
                marginBottom: '0px',
            }
        }
        this.addBlockManager(_obj)
    }
    addLogoBlock = () => {
        var id = this.uuidv4();
        var img1 = this.addImage(id, "https://" + window.location.hostname + "/static/media/logo_test.png", '170px');
        var img2 = this.addImage(id, "https://" + window.location.hostname + "/static/media/logo_test.png", "90px");
        var _obj = {
            id: id,
            type: 'logo-block',
            childrens: [img1, img2],
            params: {
                paddingTop: '12px',
                paddingLeft: '0px',
                paddingRight: '0px',
                backgroundColor: '#fff',
                paddingBottom: '12px',
                marginTop: '0px',
                for: -99,
                marginRight: '0px',
                marginLeft: '0px',
                marginBottom: '0px',
            }
        }
        this.addBlockManager(_obj)
    }
    addThreeImage = () => {
        var id = this.uuidv4();
        var width = '160px';
        var url = "https://adbotest.digitum.nl/static/media/banner_test.png";//"https://" + window.location.hostname + "/static/media/banner_test.png";
        var img1 = this.addImage(id, url, width);
        var img2 = this.addImage(id, url, width);
        var img3 = this.addImage(id, url, width);
        var _obj = {
            id: id,
            type: 'three-i-row',
            childrens: [img1, img2, img3],
            params: {
                paddingTop: '12px',
                paddingLeft: '0px',
                paddingRight: '0px',
                backgroundColor: '#fff',
                paddingBottom: '12px',
                marginTop: '0px',
                for: -99,
                marginRight: '0px',
                marginLeft: '0px',
                marginBottom: '0px',
            }
        };
        this.addBlockManager(_obj)
    }
    addImage = (_for, _img, _width, pR) => {
        var id = this.uuidv4();
        var img = "https://" + window.location.hostname + "/static/media/banner_test.png";
        var width = '350px';
        if (_img !== undefined && _img !== null) {
            img = _img;
        }
        if (_width !== undefined && _width !== null) {
            width = _width;
        }
        var paddingRight = '0';
        if (pR !== undefined && pR !== null) {
            paddingRight = pR;
        }
        var _obj = {
            id: id,
            type: 'image',
            childrens: [],
            params: {
                height: 'auto',
                width: width,
                paddingTop: _for === undefined ? '12px' : '0px',
                align: 'center',
                paddingLeft: '0px',
                paddingRight: paddingRight,
                paddingBottom: _for === undefined ? '12px' : '0px',
                backgroundColor: '#fff',
                marginTop: '0px',
                borderTopLeftRadius: '0px',
                borderBottomLeftRadius: '0px',
                borderTopRightRadius: '0px',
                borderBottomRightRadius: '0px',
                for: _for === undefined ? -99 : _for,
                marginRight: '0px',
                marginLeft: '0px',
                marginBottom: '0px',
                alt: '',
                src: img
            }
        }
        let resId = this.addBlockManager(_obj, id)
        return resId
    }
    addImageLink = (_for, _img_link, _size, _pRight) => {
        var id = this.uuidv4();
        var img_link = _img_link === undefined ? "https://" + window.location.hostname + "/static/media/default_button.png" : _img_link;
        var size = _size === undefined ? '300px' : _size;
        var _obj = {
            id: id,
            type: 'link-img',
            childrens: [],
            params: {
                height: 'auto',
                width: size,
                paddingTop: _for === undefined ? '12px' : '0px',
                for: _for === undefined ? -99 : _for,
                align: 'center',
                paddingLeft: '0px',
                link: "https://digitum.nl",
                paddingRight: '0px',
                borderTopLeftRadius: '0px',
                borderBottomLeftRadius: '0px',
                borderTopRightRadius: '0px',
                borderBottomRightRadius: '0px',
                backgroundColor: '#fff',
                paddingBottom: _for === undefined ? '12px' : '0px',
                marginTop: '0px',
                marginRight: _pRight === undefined ? '0px' : _pRight,
                marginLeft: '0px',
                marginBottom: '0px',
                alt: '',
                src: img_link
            }
        }
        let resId = this.addBlockManager(_obj, id)
        return resId
    }
    addTwoText = () => {
        var id = this.uuidv4();
        var text1 = this.addText(id, undefined, "20px");
        var text2 = this.addText(id, undefined, "20px");

        var _obj = {
            id: id,
            childrens: [text1, text2],
            type: "text-two-block",
            params: {
                paddingTop: '12px',
                paddingLeft: '32px',
                paddingRight: '32px',
                paddingBottom: '12px',
                borderTopLeftRadius: '0px',
                borderBottomLeftRadius: '0px',
                borderTopRightRadius: '0px',
                borderBottomRightRadius: '0px',
                backgroundColor: '#fff',
                marginTop: '0px',
                for: -99,
                marginRight: '0px',
                marginLeft: '0px',
                marginBottom: '0px',
            }
        }
        this.addBlockManager(_obj)
    }
    addThreeText = () => {
        var id = this.uuidv4();
        var text1 = this.addText(id, undefined, "20px");
        var text2 = this.addText(id, undefined, "20px");
        var text3 = this.addText(id, undefined, "20px");
        var _obj = {
            id: id,
            childrens: [text1, text2, text3],
            type: "text-three-block",
            params: {
                paddingTop: '12px',
                paddingLeft: '32px',
                paddingRight: '32px',
                paddingBottom: '12px',
                borderTopLeftRadius: '0px',
                borderBottomLeftRadius: '0px',
                borderTopRightRadius: '0px',
                borderBottomRightRadius: '0px',
                backgroundColor: '#fff',
                marginTop: '0px',
                for: -99,
                marginRight: '0px',
                marginLeft: '0px',
                marginBottom: '0px',
            }
        }
        this.addBlockManager(_obj)

    }
    addText = (_for, _text, _pLeft, align) => {
        let text = 'Paragraph: Laborum laboris mollit commodo do consectetur consectetur sunt ea velit reprehenderit ad irure aliqua amet. Excepteur esse Lorem do labore laborum reprehenderit laborum mollit dolor mollit mollit quis in. Anim nulla Lorem nostrud duis voluptate ipsum sint pariatur. Esse ex fugiat pariatur officia consequat est aliqua labore sit cillum Lorem deserunt qui mollit. Quis eu Lorem cillum dolor do do. Ea sint aliquip irure exercitation ut ut et irure aute ad.';
        if (_text != undefined && _text != null) {
            text = _text;
        }
        if (align === undefined || align === null) {
            align = 'center';
        }
        let _obj = addTextManager({
            backgroundColor: '#fff',
            paddingTop: _for === undefined ? '12px' : '0px',
            paddingLeft: _for === undefined ? '32px' : _pLeft,
            paddingRight: _for === undefined ? '32px' : _pLeft,
            text: _for === undefined ? text : 'Lorem ipsum',
            paddingBottom: _for === undefined ? '12px' : '0px',
            marginTop: '0px',
            marginRight: '0px',
            align: align,
            color: '#000',
            dir: '',
            for: _for === undefined ? -99 : _for,
            marginLeft: '0px',
            marginBottom: '0px',
            fontFamily: 'Arial',
            fontSize: '14px',
            fontWeight: 400,
            borderTopLeftRadius: '0px',
            borderBottomLeftRadius: '0px',
            borderTopRightRadius: '0px',
            borderBottomRightRadius: '0px',
            fontHeight: '133%'
        })
        let resId = this.addBlockManager(_obj, _obj.id);
        return resId
    }
    addTextLink = (_for) => {
        var id = this.uuidv4();
        var _obj = {
            id: id,
            type: 'text-link',
            childrens: [],
            params: {
                backgroundColor: '#fff',
                background: '#fff',
                paddingTop: _for === undefined ? '12px' : '0px',
                paddingLeft: '0px',
                paddingRight: '0px',
                paddingBTop: '0px',
                paddingBBottom: '0px',
                paddingBLeft: '0px',
                paddingBRigth: '0px',
                text: 'Lorem Ipsum',
                color: '#000',
                link: 'https://digitum.nl',
                paddingBottom: _for === undefined ? '12px' : '0px',
                marginTop: '0px',
                marginRight: '0px',
                align: 'center',
                for: _for === undefined ? -99 : _for,
                marginLeft: '0px',
                marginBottom: '0px',
                fontFamily: 'Arial',
                fontSize: '14px',
                fontWeight: 400,
                fontHeight: '133%',
                borderStyle: '',
                borderWidth: '',
                borderColor: '',
                borderTopLeftRadius: '0px',
                borderBottomLeftRadius: '0px',
                borderTopRightRadius: '0px',
                borderBottomRightRadius: '0px',
            }
        };
        this.addBlockManager(_obj)
    }

    mainRender = () => {
        var mainElm = this.props.arr.filter(x => x.id === '-122');
        var obj = {
            backgroundColor: '#ffffff',
            paddingTop: '0px',
            paddingBottom: '0px',
            paddingLeft: '0px',
            paddingRight: '0px'
        };
        if (mainElm.length > 0) {
            obj = mainElm[0].params;
        }
        return <div ref={this.sheetBoxRef} style={{
            width: '602px', minHeight: '100%',
            paddingTop: obj.paddingTop,
            paddingBottom: obj.paddingBottom,
            paddingLeft: obj.paddingLeft,
            paddingRight: obj.paddingRight,
            backgroundColor: obj.backgroundColor
        }}>
            {this.props.arr.map(x => {
                if (x !== undefined && x.params !== undefined) {
                    if (x.params.for !== undefined && x.params.for === -99) {
                        return generateBlock(x);
                    } else {
                        <div style={{ width: '100%', height: '100%', backgroundColor: 'white' }}></div>
                    }
                }

            })}
        </div>;
    }

    renderAttr = (id, attr) => {
        let arr = [];
        for (var i = 0; i < attr.length; i++) {
            switch (attr[i]) {
                case 140:
                    arr.push(<Ai_text for={id} />);
                    break;
                case 0:
                    arr.push(<DeleteBox for={id} />);
                    break;
                case 1:
                    arr.push(<BackgroundBox for={id} bad={false} />);
                    break;
                case 2:
                    arr.push(<FontBox for={id} />);
                    break;
                case 3:
                    arr.push(<MarginBox for={id} />);
                    break;
                case 4:
                    arr.push(<PaddingBox for={id} bad={false} />);
                    break;
                case 10:
                    arr.push(<PaddingBox for={id} bad={true} />);
                    break;
                case 5:
                    arr.push(<ImageBox for={id} setCurrentPage={this.props.setCurrentPage} />);
                    break;
                case 6:
                    arr.push(<LinkBox for={id} />);
                    break;
                case 7:
                    arr.push(<Border for={id} />);
                    break;
                case 8:
                    arr.push(<AlignBox for={id} />);
                    break;
                case 9:
                    arr.push(<BackgroundBox for={id} bad={true} />);
                    break;
                case 11:
                    arr.push(<DirBox for={id} />);
                    break;
                default:
                    break;
            }
        }
        return arr;
    }
    _toast = (text, status) => {
        toast(text, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            className: status === 200 ? 'toast-success' : 'toast-error'
        });
    }
    getElm = () => {


        var id = this.props.selectedElm;
        for (var i = 0; i < this.props.arr.length; i++) {

            if (this.props.arr[i].id == id) {
                for (var j = 0; j < this.types.length; j++) {
                    if (this.types[j].obj.type === this.props.arr[i].type) {
                        const new_arr = this.renderAttr(id, this.types[j].obj.attr);
                        this.setState({ attrs: new_arr });
                    }
                }
            }
        }

    }
    componentDidUpdate = (props, state) => {
        if (props.selectedElm != this.props.selectedElm) {
            this.setState({ attrs: [] });
            setTimeout(() => this.getElm(), 50);
        }
    }
    setTestArr = () => {
        var data = JSON.parse(test_data[0].data);
        this.props.setArr(data);
    }
    componentDidMount() {
        //  const height = this.divElement.clientHeight;
        //  this.setState({ leftMenuControlsHeight: height + 10 });
        //  this.setTestArr();
        document.addEventListener('click', this.handleClickOutside);
        if (this.props.arr.length > 0 && this.props.arr.filter(x => x.id === '-122').length === 0) {
            this.addMainBlock();
            this.props.putElm({ id: -99 });
            this.getElm();
            // var _this = this;
            // setTimeout(() => _this.props.putElm( {id:'-122'}), 150);  

        }
        else if (this.props.arr.length === 0) {
            this.addMainBlock();
            this.props.putElm({ id: -99 });
            this.getElm();
        }
        else {
            this.props.putElm(-99);
            this.getElm();
        }
    }
    addMainBlock = () => {

        var _obj = {
            id: '-122',
            childrens: [],
            type: "main-block",
            params: {
                paddingTop: '0',
                paddingLeft: '0',
                paddingRight: '0',
                paddingBottom: '0',
                backgroundColor: '#ffffff',
                for: -122,

            }
        }
        this.addBlockManager(_obj);

    }
    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside)
    }

    handleClickOutside = (e) => {
        if (!this.blockTypesBoxRef.current.contains(e.target) &&
            !this.sheetBoxRef.current.contains(e.target) &&
            !this.attrBoxRef.current.contains(e.target) && this.props.selectedElm !== '-122') {
            this.props.putElm(-99);
        }
        if (!this.menuRef.current.contains(e.target)) {
            this.setState({ open_menu: false })
        }
    }

    getHtml = () => {
        const html = getHtmlRaw(this.props.arr);
        const element = document.createElement("a");
        const file = new Blob([html], { type: 'text/html' });
        element.href = URL.createObjectURL(file);
        element.download = "index.html";
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
        this.setState({ open_menu: false });
    }
    saveEmail = () => {
        var json = {
            email_id: this.props.email_id,
            token: this.props.token,
            data: JSON.stringify(this.props.arr)
        };
        fetch('UpdateEmail', {
            method: "POST",
            body: JSON.stringify(json),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then(x => { this.setState({ open_menu: false }); this._toast("Success", x.status) });
    }
    removeEmail = () => {
        var json = {
            token: this.props.token,
            id: this.props.email_id
        };
        fetch('RemoveEmail', {
            method: "POST",
            body: JSON.stringify(json),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then(x => this.props.setCurrentPage('campaign_details'));
    }
    // newEmail = () => {
    //     var json = {
    //         campaignId: this.props.campaignId,
    //         token: this.props.token,
    //         data: '[]'
    //     };
    //     fetch('UploadEmail', {
    //         method: "POST",
    //         body: JSON.stringify(json),
    //         headers: {
    //             'Accept': 'application/json',
    //             'Content-Type': 'application/json'
    //         }
    //     }).then(x => x.json()).then(x => {
    //         this.props.setArr([]);
    //         this.props.setEmailId(x.emailId);
    //     });
    // }
    getRotate = (i) => {
        if (this.state.selectedBlock === i) {
            return 'rotate(90deg)';
        } else {
            return 'rotate(0deg)';
        }
    }
    getAttrs = (attrs) => {
        if (attrs.length === 0) {
            console.log(this.props.selectedElm);
        }
        if (attrs.length === 0 && this.props.selectedElm === undefined) {
            this.props.putElm({ id: '-122' });
        } else {
            return this.state.attrs.map(x => x);
        }
    }
    render() {
        return (<div className="App" style={{ overflowY: 'hidden' }}>
            <ToastContainer
                position="top-right"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '16px', borderBottom: '1px solid #EFF1F133' }}>
                <div ref={this.menuRef} onClick={() => this.setState({ open_menu: !this.state.open_menu })} style={{ display: 'flex', cursor: 'pointer' }}>
                    <img width={96} src={icons.logo_adbot_small} />
                    <img className='icon-transform' src={icons.next} style={{ transform: this.state.open_menu ? 'rotate(270deg)' : 'rotate(90deg)', scale: '1.2', marginLeft: '15px' }} />
                </div>
                <button className='preview-btn' onClick={this.openPreviewModal} >Preview</button>
                <Fade duration={300} top when={this.state.open_menu}>
                    <ul className='constructor-menu' style={{ zIndex: 1, display: this.state.open_menu ? '' : 'none', marginTop: '45px', width: '174px', color: 'white', listStyle: 'none', backgroundColor: '#14201e', position: 'absolute', boxShadow: 'rgba(0, 0, 0, 0.45)', border: '1px solid #EFF1F133', borderRadius: '8px' }}>
                        <li onClick={() => this.props.setCurrentPage('dashboard')} style={{ padding: '18px', borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }}>Home</li>
                        <li onClick={() => newEmail(this.props.campaignId, this.props.token, this.props.setArr, this.props.setEmailId, '[]')} style={{ padding: '18px' }}>New</li>
                        <li onClick={() => this.saveEmail()} style={{ padding: '18px' }}>Save</li>
                        <li onClick={() => newEmail(this.props.campaignId, this.props.token, this.props.setArr, this.props.setEmailId, this.props.arr)} style={{ padding: '18px' }}>Save as new</li>
                        <li onClick={() => this.getHtml()} style={{ padding: '18px' }}>Download .zip</li>
                        <li onClick={this.openDeleteModal} style={{ padding: '18px', color: '#FD7D66' }}>Remove</li>
                        <li style={{ padding: '18px', borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px', borderTop: '1px solid #EFF1F133' }} onClick={this.openAboutModal}>About Adbot</li>
                        <li style={{ padding: '18px', color: '#FD7D66' }} onClick={this.props.logOut}>Log Out</li>
                    </ul>
                </Fade>
                {this.state.openedAboutModal && <AboutModal close={this.closeAboutModal} />}
                {this.state.openedPreviewModal && <PreviewModal close={this.closePreviewModal} />}
                {this.state.openedDeleteModal &&
                    <DeleteCampaignModal
                        close={this.closeDeleteModal}
                        id={this.props.email_id}
                        token={this.props.token}
                        setCurrentPage={this.props.setCurrentPage}
                    />
                }
            </div>
            <div style={{ height: 'calc(100vh - 50px)', display: 'flex', justifyContent: 'space-between' }}>
                <div ref={this.blockTypesBoxRef} className='constructor-side-block' style={{ borderRight: '1px solid #EFF1F133', width: '380px', backgroundColor: '#23312F', maxHeight: '100vh', overflowY: 'scroll' }}>
                    <div className='constructor-block'>
                        <p onClick={() => this.openGenerateLetterModal()}>
                            <img className='constructor-block__img' style={{ marginRight: '4px' }} src={icons.sparkles} />
                            Generate entire letter
                        </p>
                        {this.state.openedGenerateLetterModal && <GenerateLettetModal setCurrentPage={this.props.setCurrentPage} close={this.closeGenerateLetterModal} />}
                    </div>
                    <div className='constructor-block'>
                        <p onClick={() => this.setState({ selectedBlock: this.state.selectedBlock === 1 ? -99 : 1 })}><img className='icon-transform' src={icons.next} style={{ transform: this.getRotate(1), filter: 'brightness(1000%)' }} /> Text</p>
                        <div style={{ display: this.state.selectedBlock === 1 ? '' : 'none', paddingLeft: '19px' }}>
                            <p onClick={() => this.props.setCurrentPage('ai_text')} style={{ display: 'flex' }}><img className='constructor-block__img' style={{ marginRight: '4px' }} src={icons.sparkles} />Generate entire text</p>
                            <p onClick={() => this.addText()}>Add text block</p>
                            <p onClick={() => this.addTwoText()}>Add 2 text in row</p>
                            <p onClick={() => this.addThreeText()}>Add 3 text in row</p>
                        </div>
                    </div>
                    <div className='constructor-block'>
                        <p onClick={() => this.setState({ selectedBlock: this.state.selectedBlock === 2 ? -99 : 2 })}><img className='icon-transform' src={icons.next} style={{ transform: this.getRotate(2), filter: 'brightness(1000%)' }} /> Image</p>
                        <div style={{ display: this.state.selectedBlock === 2 ? '' : 'none', paddingLeft: '19px' }}>
                            <p onClick={() => { this.props.setCurrentPage('ai_img'); this.props.setAiImages([]); }} style={{ display: 'flex' }}><img className='constructor-block__img' style={{ marginRight: '4px' }} src={icons.sparkles} />Generate image</p>
                            <p onClick={() => this.addImage()}>Add image block</p>
                            <p onClick={() => this.addThreeImage()}>Add 3 image in row</p>
                        </div>
                    </div>
                    <div className='constructor-block'>
                        <p onClick={() => this.setState({ selectedBlock: this.state.selectedBlock === 3 ? -99 : 3 })}><img className='icon-transform' src={icons.next} style={{ transform: this.getRotate(3), filter: 'brightness(1000%)' }} /> Button</p>
                        <div style={{ display: this.state.selectedBlock === 3 ? '' : 'none', paddingLeft: '19px' }}>
                            <p onClick={() => this.addTextLink()}>Add text button</p>
                            <p onClick={() => this.addImageLink()}>Add image button</p>
                        </div>
                    </div>
                    <div className='constructor-block'>
                        <p onClick={() => this.setState({ selectedBlock: this.state.selectedBlock === 4 ? -99 : 4 })}><img className='icon-transform' src={icons.next} style={{ transform: this.getRotate(4), filter: 'brightness(1000%)' }} /> List</p>
                        <div style={{ display: this.state.selectedBlock === 4 ? '' : 'none', paddingLeft: '19px' }}>
                            <p onClick={() => this.addListBlock(false)}>Add list block</p>
                            <p onClick={() => this.addListBlock(true)}>Add RTL list block</p>
                            <p onClick={() => this.addTextImage(false)}>Add image with text</p>
                            <p onClick={() => this.addTwoTextImage(false)}>Add 2 image with text</p>
                            <p onClick={() => this.addThreeTextImage(false)}>Add 3 image with text</p>
                        </div>
                    </div>
                    <div className='constructor-block'>
                        <p onClick={() => this.setState({ selectedBlock: this.state.selectedBlock === 5 ? -99 : 5 })}><img className='icon-transform' src={icons.next} style={{ transform: this.getRotate(5), filter: 'brightness(1000%)' }} /> Logo</p>
                        <div style={{ display: this.state.selectedBlock === 5 ? '' : 'none', paddingLeft: '19px' }}>
                            <p onClick={() => this.addLogoBlock()}>Add logo block</p>
                        </div>
                    </div>
                    <div className='constructor-block'>
                        <p onClick={() => this.setState({ selectedBlock: this.state.selectedBlock === 6 ? -99 : 6 })}><img className='icon-transform' src={icons.next} style={{ transform: this.getRotate(6), filter: 'brightness(1000%)' }} /> Social media</p>
                        <div style={{ display: this.state.selectedBlock === 6 ? '' : 'none', paddingLeft: '19px' }}>
                            <p onClick={() => this.addSocialBlock()}>Add social block</p>
                        </div>
                    </div>
                </div>
                <div className='letter-block' style={{ width: '657px', maxHeight: 'calc(100vh - 50px)', marginLeft: '55px' }}>
                    <div style={{ minHeight: '600px', marginBottom: '60px', borderRadius: '8px' }}>
                        <Html style={{ height: 'calc(100vh - 50px)', overflowX: 'visible', overflowY: 'scroll', paddingTop: '52px', paddingBottom: '72px' }}>
                            {this.mainRender()}
                        </Html>
                    </div>
                </div>
                <div ref={this.attrBoxRef} className='constructor-side-block' style={{ borderLeft: '1px solid #EFF1F133', width: '380px', backgroundColor: '#23312F', maxHeight: '100vh', overflowY: 'scroll' }}>
                    {this.getAttrs(this.state.attrs)}
                </div>
            </div>
        </div>)
    }
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({ setArr: setArr, setEmailId: setEmailId, putElm: putElm, setAiImages: setAiImages }, dispatch);
}
const mapStateToProps = state => {

    return {
        arr: state.arr,
        selectedElm: state.selectedElm,
        email_id: state.email_id,
        token: state.token,

        campaignId: state.campaignId
    }
}
export default connect(mapStateToProps, matchDispatchToProps)(Constructor);