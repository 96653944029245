export const stepper = {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: '863px',
    margin: '0 auto 120px auto',
    fontFamily: `Montserrat, sans-serif`,
}

export const stepperItem = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
}

export const stepperItemCircle = {
    position: 'relative',
    width: '60px',
    height: '60px',
    marginBottom: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    border: '4px solid #14201E'
}

export const stepperItemCircleNumber = {
    position: 'relative',
    zIndex: '2'
}

export const stepperItemCircleIcon = {
    position: 'absolute',
    top: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: '1'
}

export const stepperItemTitle = {
    fontSize: '16px',
    lineHeight: '19.5px',
}