import React, { Component } from 'react'
import { Fade } from 'react-reveal'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setCampaignId, setEmailsData } from '../../actions/actions';

import { icons } from '../../assets/icons';

import './styles.css'

class EditCampaignNameModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: props.elem.name,
            error: false
        }
    };

    componentDidMount = () => {
        document.addEventListener('click', this.handleClickOutside);
    };

    handleClickOutside = (e) => {
        if (e.target.classList.contains('editCampaignName-modal-overlay')) {
            this.props.close();
        }
    };

    goToCampaign = () => {
        var json = {
            id: this.props.elem.id,
            token: this.props.token
        };
        fetch('GetEmails', {
            method: "POST",
            body: JSON.stringify(json),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then(x => x.json()).then(x => {
            //this.props.setArr(JSON.parse(x.data));
            //  this.props.setEmailId(this.props.elem.id);
            this.props.setEmailsData(x);
            this.props.setCampaignId(this.props.elem.id);
            this.props.setCurrentPage('campaign_details');
            window.scrollTo(0, 0);
        });
    }

    changeName = () => {
        if (this.state.name === '' || this.props.elem.name === this.state.name) {
            this.setState({ error: true });
            return;
        } else {
            this.setState({ error: false });
            var json = {
                token: this.props.token,
                id: this.props.elem.id,
                name: this.state.name
            };
            fetch('ChangeeCampaignName', {
                method: "POST",
                body: JSON.stringify(json),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            }).then(() => {
                this.props.close();
                this.goToCampaign();
            })
        }
    }

    render() {
        return (
            <Fade>
                <div className='editCampaignName-modal-overlay'>
                    <div className='editCampaignName-modal'>
                        <div className='editCampaignName-modal-top'>
                            <h3 >Campaign name edit</h3>
                            <img src={icons.close} onClick={this.props.close} />
                        </div>
                        <div className='editCampaignName-modal-input'>
                            <label>Campaign name</label>
                            <input type='text' value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} />
                            {this.state.error && <p>Enter a valid name</p>}
                        </div>
                        <div className='editCampaignName-modal-buttons'>
                            <button onClick={this.props.close}>Cancel</button>
                            <button onClick={this.changeName}>Save</button>
                        </div>
                    </div>
                </div>
            </Fade>
        )
    }
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({ setEmailsData: setEmailsData, setCampaignId: setCampaignId }, dispatch);
}
const mapStateToProps = state => {
    return {
        token: state.token,
    }
}
export default connect(mapStateToProps, matchDispatchToProps)(EditCampaignNameModal);