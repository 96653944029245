import React, {Component} from 'react';
import {Link} from "react-router-dom";

import {container, content, header, list, listItem, listItemLink} from "./styles";

class Header extends Component {
  render() {
    return (
      <header style={{...header}}>
        <div style={{...container}}>
          <div style={{...content}}>
            <ul style={{...list}}>
              {/* <li style={{...listItem}}>
                <Link style={{...listItemLink}} to='/about'>What is Adbot?</Link>
              </li> */}
              <li style={{...listItem}}>
                <Link style={{...listItemLink}} to='https://digitum.nl/#popup' target='_blank'>Contact us</Link>
              </li>
              {/* <li style={{...listItem}}>
                <Link style={{...listItemLink}} to='/login'>Log In</Link>
              </li> */}
            </ul>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;