export const icons = {
  logo_adbot_small: require('./logo-adbot-small.svg').default,
  logo_adbot_big: require('./logo-adbot-big.svg').default,
  logo_digitum: require('./logo-digitum.svg').default,
  logo_digitum_light: require('./logo-digitum-light.svg').default,
  arrow_right: require('./arrow-right.svg').default,
  arrow_bottom: require('./arrow-bottom.svg').default,
  arrow_down: require('./arrow-down.svg').default,
  arrow_up: require('./arrow-up.svg').default,
  settings: require('./settings.svg').default,
  show_more: require('./show-more.svg').default,
  edit: require('./edit.svg').default,
  copy: require('./copy.svg').default,
  delete: require('./delete.svg').default,
  back: require('./back.svg').default,
  next: require('./next.svg').default,
  info: require('./info.svg').default,
  rozhki: require('./rozhki.svg').default,
  close: require('./close.svg').default,
  close_small: require('./close-small.svg').default,
  loading: require('./loading.svg').default,
  remove: require('./remove.svg').default,
  arrow_left: require('./arrow-left.svg').default,
  sparkles: require('./sparkles.svg').default,
  adbot_mini: require('./adbot_mini.svg').default,
  padding_margin: require('./padding-margin.svg').default,
  link: require('./link.svg').default,
  link_active: require('./link-active.svg').default,
  minimase: require('./minimase.svg').default,
  align_center: require('./align-center.svg').default,
  align_left: require('./align-left.svg').default,
  align_right: require('./align-right.svg').default,
  m_loading: require('./m_loading.svg').default,
  border_radius: require('./border-radius.svg').default,
}