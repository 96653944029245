import React, { Component } from 'react';
import { Fade } from 'react-reveal';

import AboutModal from '../../components/AboutModal/AboutModal';

import { icons } from "../../assets/icons";

import './styles.css'

class Header extends Component {
  constructor() {
    super();
    this.state = {
      open_menu: false,
      openedAboutModal: false
    }
    this.menuRef = React.createRef()
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside)
  }
  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside)
  }

  handleClickOutside = (e) => {
    if (!this.menuRef.current.contains(e.target)) {
      this.setState({ open_menu: false })
    }
  }

  openAboutModal = () => {
    this.setState({ openedAboutModal: true })
  }

  closeAboutModal = () => {
    this.setState({ openedAboutModal: false })
  }

  render() {
    return (
      <header className='header'>
        <div ref={this.menuRef} className='header-logo' onClick={() => this.setState({ open_menu: !this.state.open_menu })}>
          <img width={96} src={icons.logo_adbot_small} alt='Logo Adbot small' />
          <img
            className='icon-transform'
            src={icons.next}
            style={{
              transform: this.state.open_menu ? 'rotate(270deg)' : 'rotate(90deg)',
              scale: '1.2',
              marginLeft: '15px'
            }}
          />
        </div>
        <Fade duration={300} top when={this.state.open_menu}>
          <ul className='header-menu' style={{ zIndex: 1, display: this.state.open_menu ? '' : 'none' }}>
            <li className='header-menu-item' onClick={() => this.props.setCurrentPage('dashboard')}>Home</li>
            <li>
              <a className='header-menu-item' href='https://digitum.nl/#popup' target='_blank'>Contact Us</a>
            </li>
            <li className='header-menu-item' onClick={() => this.props.setCurrentPage('faq')}>FAQ</li>
            <li className='header-menu-item'>Settings</li>
            <li className='header-menu-item' style={{borderTop: '1px solid #EFF1F133'}} onClick={this.openAboutModal}>About Adbot</li>
            <li className='header-menu-item' style={{color: '#FD7D66'}} onClick={this.props.logOut}>Log Out</li>
          </ul>
        </Fade>
        {this.state.openedAboutModal && <AboutModal close={this.closeAboutModal} />}
      </header>
    );
  }
}

export default Header;