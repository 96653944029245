export const myCampaigns = {
  padding: '50px 0',
  fontFamily: `Montserrat, sans-serif`,
}
export const container = {
  maxWidth: '1024px',
  width: '100%',
  margin: '0 auto',
}
export const content = {

}
export const top = {
  textAlign: 'right'
}
export const topButton = {
  marginBottom: '42px',
  padding: '20px 70px',
  fontSize: '14px',
  fontWeight: '500',
  lineHeight: '17px',
  background: '#34B9A0',
  border: 'none',
  borderRadius: '4px',
  cursor: 'pointer',
  fontFamily: 'inherit'
}

export const table = {
  width: '100%'
}
export const tableHead = {
  padding: '12px 21px',
  listStyleType: 'none',
  width: '100%',
  display: 'flex',
}
export const tableHeadCol = {

}
export const tableHeadColButton = {
  fontSize: '9px',
  lineHeight: '11px',
  fontWeight: '500',
  color: '#748E8C',
  border: 'none',
  background: 'transparent',
  cursor: 'pointer'
}
export const tableHeadColDate = {
  width: '10%'
}
export const tableHeadColName = {
  width: '60%'
}
export const tableHeadColStatus = {
  width: '30%'
}
export const tableHeadColIcon = {
  marginLeft: '6px'
}
export const tableBody = {}
export const tableBodyRow = {
  padding: '27px 21px',
  marginBottom: '3px',
  listStyleType: 'none',
  display: 'flex',
  background: '#202D2B'
}
export const tableBodyRowCol = {}
export const tableBodyRowColDate = {
  width: '10%'
}
export const tableBodyRowColDateText = {
  color: '#BDBDBD',
  fontSize: '9px',
  lineHeight: '11px',
  fontWeight: '500'
}
export const tableBodyRowColName = {
  width: '60%'
}
export const tableBodyRowColNameText = {
  color: '#E0E0E0',
  fontSize: '16px',
  lineHeight: '19px',
}
export const tableBodyRowColStatus = {
  width: '30%',
  position: 'relative'
}
export const tableBodyRowColStatusText = {
  fontSize: '12px',
  lineHeight: '15px',
  fontWeight: '500'
}
export const tableBodyRowColStatusButton = {
  position: 'absolute',
  left: '85.5%',
  top: '50%',
  transform: 'translateY(-50%)',
  width: '64px',
  height: '73px',
  border: 'none',
  background: '#23312F',
  cursor: 'pointer'
}
