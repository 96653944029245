import { getImgBlock } from "./imageBlock";
import { getTextBlock } from "./textBlock";
import { Column } from '@react-email/column';
import { Section } from '@react-email/section';

export const getTextImage = (img, text, params, main, arr) => {
    var params_i = img.params;
    var params_t = text.params;
    console.log(params);
    var rtl = false;
    if (params.dir !== undefined && params.dir !== null) {
        rtl = params.dir;
    }
    var width = params.width;
    var master = '-ab999';
    if (arr !== null) {
        

        for (var i = 0; i < arr.length; i++) {

            if (arr[i].id === params.for){
                var mR = parseInt(arr[i].params.paddingRight);
                var mL = parseInt(arr[i].params.paddingLeft);
                width = parseInt(width) - mR - mL;
                width = width.toString() + 'px';
                console.log(width);
                break;
            }
        }
    }
    if (main !== undefined && main !== null) {
        var _params = main;
        var pR = parseInt(_params.paddingRight);
        var pL = parseInt(_params.paddingLeft);

        var _width = parseInt(width) - pR - pL;
        width = _width.toString() + 'px';

    }
    return <table width={parseInt(width)} cellspacing="0" align={params.align} cellpadding="0" style={{
        maxWidth:  width , width: width,
        borderTopLeftRadius: params.borderTopLeftRadius,
        borderBottomLeftRadius: params.borderBottomLeftRadius,
        borderTopRightRadius: params.borderTopRightRadius,
        borderBottomRightRadius: params.borderBottomRightRadius,
        paddingLeft: params.paddingLeft,
        paddingRight: params.paddingRight,

        marginTop: params.marginTop, marginLeft: params.marginLeft, marginRight: params.marginRight, marginBottom: params.marginBottom, backgroundColor: params.backgroundColor
    }}>
        <tr >
            <td align={params_i.align} style={{
                borderTopLeftRadius: params.borderTopLeftRadius,
                borderBottomLeftRadius: params.borderBottomLeftRadius,
                verticalAlign: 'top',
                width: params_i.width,
                backgroundColor: params_i.backgroundColor
            }}>{getImgBlock(params_i)}</td>


        </tr>
        <tr>
            <td align={params_t.align} style={{
                width: '100%',
                borderTopRightRadius: params.borderTopRightRadius,
                borderBottomRightRadius: params.borderBottomRightRadius,
                backgroundColor: params_t.backgroundColor, paddingTop: params.paddingTop,
                paddingBottom: params.paddingBottom,
            }}>{getTextBlock(params_t)}</td>
        </tr>

    </table>;
}