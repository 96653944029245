import React, { Component } from 'react';
import { connect } from 'react-redux';
import { putElm, setArr } from '../../../actions/actions';
import { Text } from '@react-email/text';
import Modal from 'react-modal';
import { bindActionCreators } from 'redux';
import { SketchPicker } from 'react-color';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Container } from '@react-email/container';
import { Column } from '@react-email/column';
import { Row } from '@react-email/row';
import Menu from '../../../components/Menu';
import EditTextModal from '../../../components/EditTextModal/EditTextModal';

function hexToRgbA(hex){
    var c;
    if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
        c= hex.substring(1).split('');
        if(c.length== 3){
            c= [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c= '0x'+c.join('');
        return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',1)';
    }
}

const modalStyle = {
    content: {
        top: '50%',
        left: '50%',
        width: '700px',
        height: '550px',
        right: 'auto',
        zIndex: 2,
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white'
    },
};
var Size = Quill.import("attributors/style/size");
Size.whitelist = [
    "9px",
    "10px",
    "11px",
    "12px",
    "14px",
    "16px",
    "18px",
    "20px",
    "22px",
    "24px",
    "26px",
    "28px",
];
Quill.register(Size, true);
const quill_models = {
    toolbar: {
        container: [
            ['bold', 'italic', 'underline', 'strike'],
            [{
                size: [
                    "9px",
                    "10px",
                    "11px",
                    "12px",
                    "14px",
                    "16px",
                    "18px",
                    "20px",
                    "22px",
                    "24px",
                    "26px",
                    "28px",
                ],
            },
            ],
            [{ 'color': ["#FFFFFF", "#C0C0C0", "#808080", "#000000", "#FF0000", "#800000", "#FFFF00", "#808000", "#00FF00", "#008000", "#00FFFF", "#008080", "#0000FF", "#000080", "#FF00FF", "#800080", 'custom-color'] }]
        ],
        handlers: {
            'color': function (value) {
                if (value == 'custom-color') value = window.prompt('Enter Hex Color Code');
                if (value[0] === '#') {
                    let rgba = hexToRgbA(value);
                    this.quill.format('color', rgba);
                    return;
                } else {
                    this.quill.format('color', value);
                    return;
                }
            },
            'size': function (value) {
                this.quill.format('size', value);
                return;
            },
            'link': function (value) {
                if (value) {
                    var href = prompt('Enter the URL');
                    if (href.indexOf("http://") === -1 && href.indexOf("https://") === -1) {
                        href = "https://" + href;
                    }
                    this.quill.format('link', href);
                } else {
                    this.quill.format('link', false);
                }
            }
        }
    }

};
class TextLinkBlock extends Component {

    constructor(props) {
        super();

        // const editor = Quill.getEditor();
        this.state = {
            modalIsOpen: false,
            dgdText: '',
            colorPicker: '#333',
            displayColor: false
        };
    }
    getBackground = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.backgroundColor;
            }
        }
    }
    getBackgroundColor = () =>{
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.background;
            }
        }
        return '#fff';
    }
    getFontFamily = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.fontFamily;
            }
        }
    }
    getFontSize = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.fontSize;
            }
        }
    }
    getFontHeight = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.fontHeight;
            }
        }
    }
    getFontWeight = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.fontWeight;
            }
        }
    }
    getMarginTop = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.marginTop;
            }
        }
    }
    getMarginLeft = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.marginLeft;
            }
        }
    }
    getMarginRight = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.marginRight;
            }
        }
    }
    getMarginBottom = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.marginBottom;
            }
        }
    }
    getPaddingTop = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.paddingTop;
            }
        }
    }
    getPaddingLeft = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.paddingLeft;
            }
        }
    }
    getPaddingRight = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.paddingRight;
            }
        }
    }
    getPaddingBottom = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.paddingBottom;
            }
        }
    }
    getText = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {

                return arr[i].params.text;
            }
        }
    }
    getColor = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.color;
            }
        }
    }
    getAlign = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.align;
            }
        }
    }
    getDgDtext = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {

                this.setState({ dgdText: arr[i].params.text });
            }
        }
    }
    getborderTopLeftRadius = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.borderTopLeftRadius;
            }
        }
    }
    getborderBottomLeftRadius = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.borderBottomLeftRadius;
            }
        }
    }
    getborderTopRightRadius = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.borderTopRightRadius;
            }
        }
    }
    getborderBottomRightRadius = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.borderBottomRightRadius;
            }
        }
    }
    componentDidMount = () => {
        this.getDgDtext();
        document.querySelectorAll('#moved-block')[0].classList.add('menu-moveBottom')
    }

    componentWillUnmount = () => {
        document.querySelectorAll('#moved-block')[0].classList.remove('menu-moveBottom')
    }

    setModal = () => {
        var open = !this.state.modalIsOpen;
        this.setState({ modalIsOpen: open });
    }

    setText = () => {

        var id = this.props.id;
        var arr = Array.prototype.concat(this.props.arr);
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === id) {
                var item = arr[i];
                item.params.text = this.state.dgdText.replace('<p>', '').replace('</p>', '');
                arr[i] = item;
                break;
            }
        }
        this.props.setArr(arr);
        this.setModal();
    }

    getAlign = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.align;
            }
        }
    }

    checkIfCanInteract = () => {
        return this.props.arr.filter(item => item.id === this.props.id)[0].params.for === -99;
    }

    editBlock = () => {
        this.props.putElm({ id: this.props.id, type: 'text' })
    }

    deleteBlock = () => {
        const deletedBlock = this.props.arr.filter(item => item.id === this.props.id);
        if (deletedBlock[0].params.for !== -99) return;
        this.props.setArr(this.props.arr.filter(item => item.id !== this.props.id));
    }

    uuidv4() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }

    copyBlock = () => {
        let id = this.uuidv4();
        const block = this.props.arr.filter(item => item.id === this.props.id);
        const modifiedBlock = structuredClone(block.map(item => {
            return { ...item, id: id }
        }))
        this.props.arr.push(modifiedBlock[0]);
        var arr = Array.prototype.concat(this.props.arr);
        this.props.setArr(arr);
    }

    elemMove = (direction) => {
        let arr = this.props.arr.filter(elem => elem.params.for === -99);
        let arrWithout99 = this.props.arr.filter(elem => elem.params.for !== -99);
        let selectedElemIndex = 0;
        let selectedElem = arr.find((elem, index) => {
            selectedElemIndex = index;
            return elem.id === this.props.id
        });
        let res = [];
        if (direction === 'up') {
            if (selectedElemIndex === 0) return;
            const arrCopy = [...arr];
            let element = arrCopy[selectedElemIndex];
            arrCopy.splice(selectedElemIndex, 1);
            arrCopy.splice(selectedElemIndex - 1, 0, element);
            res = arrCopy;
        }
        if (direction === 'down') {
            if (selectedElemIndex === this.props.arr.length - 1) return;
            const arrCopy = [...arr];
            let element = arrCopy[selectedElemIndex];
            arrCopy.splice(selectedElemIndex, 1);
            arrCopy.splice(selectedElemIndex + 1, 0, element);
            res = arrCopy;
        }
        this.props.setArr(res.concat(arrWithout99));
    }

    setDgdText = (value) => {
        this.setState({dgdText: value})
    }
    getPaddingBTop = () =>{
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.paddingBTop;
            }
        }
    }
    getPaddingBBottom = () =>{
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.paddingBBottom;
            }
        }
    }
    getPaddingBLeft = () =>{
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.paddingBLeft;
            }
        }
    }
    getPaddingBRigth = () =>{
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.paddingBRigth;
            }
        }
    }
    getDir = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                var dir = arr[i].params.dir;
                if (dir !== null && dir !== undefined) {
                    return dir ? 'rtl' : '';
                } else {
                    return '';
                }
            }
        }
    }
    render() {
        return <>

            {this.state.modalIsOpen && 
                <EditTextModal
                    value={this.state.dgdText}
                    setValue={this.setDgdText}
                    close={this.setModal}
                    setText={this.setText}
                />
            }

            <Container>
                <Row>
                    <Column style={{backgroundColor: this.getBackgroundColor(), paddingTop: this.getPaddingBTop(), paddingBottom: this.getPaddingBBottom(),
                    paddingLeft: this.getPaddingBLeft(), paddingRight: this.getPaddingBRigth()}} id='moved-block' className='highlighted__childElem' align={this.getAlign()}>
                        <Text dir={this.getDir()} onDoubleClick={(e) => this.setModal()} onClick={() => this.props.putElm({ id: this.props.id, type: "text" })} style={{
                            display: this.child ? 'inline' : '',
                            width: 'fit-content',
                            border: this.props.selectedElm == this.props.id ? '2px solid #34b9a0' : '',
                            backgroundColor: this.getBackground(), paddingTop: this.getPaddingTop(), paddingBottom: this.getPaddingBottom(),
                            paddingLeft: this.getPaddingLeft(), paddingRight: this.getPaddingRight(),
                            borderTopLeftRadius: this.getborderTopLeftRadius(),
                            borderBottomLeftRadius: this.getborderBottomLeftRadius(),
                            borderTopRightRadius: this.getborderTopRightRadius(),
                            borderBottomRightRadius: this.getborderBottomRightRadius(),
                            marginTop: this.getMarginTop(), marginBottom: this.getMarginBottom(), marginLeft: this.getMarginLeft(),
                            marginRight: this.getMarginRight(), textAlign: this.getAlign(), color: this.getColor(),
                            fontSize: this.getFontSize(), fontFamily: this.getFontFamily(), fontWeight: this.getFontWeight(),
                            lineHeight: this.getFontHeight()
                        }} dangerouslySetInnerHTML={{ __html: this.getText() }}>

                            {/*this.children.map(x => )*/}
                        </Text>
                        <Menu
                            edit={this.editBlock}
                            canInteract={this.checkIfCanInteract()}
                            delete={this.deleteBlock}
                            copy={this.copyBlock}
                            moveUp={() => this.elemMove('up')}
                            moveDown={() => this.elemMove('down')}
                        />
                    </Column>
                </Row>
            </Container>
        </>
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({ putElm: putElm, setArr: setArr }, dispatch);
}
const mapStateToProps = state => {
    return {
        arr: state.arr,
        selectedElm: state.selectedElm
    }
}

export default connect(mapStateToProps, matchDispatchToProps)(TextLinkBlock);