import React, {Component} from 'react';
import {Navigate} from "react-router-dom";

import Header from "../layout/loginHeader/Header";
import Footer from "../layout/loginFooter/Footer";
import Login from "../pages/Login/Login";
class LoginTemplate extends Component {
  render() {
    return (
      <>
        <Header/>
        <main>
          {this.props.authenticated ? <Navigate to='/'/> : <Login loginError={this.props.loginError} logIn={this.props.logIn}/>}
        </main>
        <Footer/>
      </>
    );
  }
}

export default LoginTemplate;