import React, { Component } from 'react'

export default class CustomCheckbox extends Component {
    render() {
        const {labelId, name, labelText, setValue, value} = this.props;
        return (
            <div className='custom-checkbox'>
                <input className='custom-checkbox__input' type="checkbox" id={labelId} name={name} value={value} onChange={(e) => setValue(e)} />
                <label className='custom-checkbox__label' htmlFor={labelId}>{labelText}</label>
            </div>
        )
    }
}
