import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setArr } from '../../../actions/actions';
import { bindActionCreators } from 'redux';
import { icons } from '../../../assets/icons';
import AttributeTooltip from '../../../components/AttributeTooltip/AttributeTooltip';
class AlignBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            align: 'center'
        };
    }
    componentDidMount = () => {
        this.getAll();
    }

   

    getAll = () => {

        var id = this.props.for;
        var arr = Array.prototype.concat(this.props.arr);
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === id) {
                var item = arr[i];
                this.setState({ align: item.params.align });
                return;
            }
        }
    }
 
    setAlign = (value) => {
        this.setState({ align: value });
        var id = this.props.for;
        var arr = Array.prototype.concat(this.props.arr);
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === id) {
                var item = arr[i];
                item.params.align = value;
                arr[i] = item;
                break;
            }
        }
        this.props.setArr(arr);
    }
    
    render() {
        return (
            <div>
                
                <div className='attr-block'>
                    <p className='attr-label'>
                        Align
                        <AttributeTooltip/>
                    </p>
                    <button className={this.state.align === 'left' ? 'custom-align custom-align__checked' : 'custom-align'} onClick={() => { this.setAlign("left") }}>
                        <img src={icons.align_left} />
                    </button>
                    <button className={this.state.align === 'center' ? 'custom-align custom-align__checked' : 'custom-align'} onClick={() => { this.setAlign("center") }}>
                        <img src={icons.align_center} />
                    </button>
                    <button className={this.state.align === 'right' ? 'custom-align custom-align__checked' : 'custom-align'} onClick={() => { this.setAlign("right") }}>
                        <img src={icons.align_right} />
                    </button>
                </div>
            </div>
        );
    }
}
function matchDispatchToProps(dispatch) {
    return bindActionCreators({ setArr: setArr }, dispatch);
}
const mapStateToProps = state => {
    return {
        arr: state.arr,
        token: state.token
    }
}
export default connect(mapStateToProps, matchDispatchToProps)(AlignBox);